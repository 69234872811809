
import { Component, Vue, Prop } from "vue-property-decorator";
import {Accounts, VatMatrix} from "@/util/accounts";


@Component
export default class VatMatrixSelector extends Vue {
    @Prop({default: false})
    dense!: boolean
    @Prop({default: false})
    outlined!: boolean
    @Prop({default: false})
    hidedetails!: boolean
    @Prop({default: {}})
    reference!: any
    @Prop({default: []})
    vatMatrixEntries!: VatMatrix[]


    items = [] as VatMatrix[]

    async mounted() {
        if (this.vatMatrixEntries.length === 0) {
            this.items = await Accounts.getVatMatrixEntries()
        } else {
            this.items = this.vatMatrixEntries
        }
    }

    async change(selected: string) {
        this.reference.vatmatrix = selected
        this.$emit("change", selected, this.reference)
    }
}
