
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Page extends Vue {

    @Prop({ default: "MyTitle" })
    title!: string

    @Prop({ default: false })
    titleCentered?: boolean
}
