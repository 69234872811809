
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {Properties, Property} from "@/util/properties";
import { Store } from "vuex";
import { StateType } from "@/store";


@Component
export default class PropertySelector extends Vue {
    @Prop({default: false})
    dense!: boolean
    @Prop({default: false})
    outlined!: boolean
    @Prop({default: false})
    hidedetails!: boolean
    @Prop([])
    properties!: Property[]

    items = [] as Property[]


    async mounted() {
        this.setItems()
    }

    @Watch("$store.getters.allProperties")
    async setItems() {
        if (this.properties === undefined) {
            await Properties.refreshPropertiesFromApaleo()
            this.items = Properties.getActiveProperties()
        } else {
            this.items = this.properties
        }
        
        if (this.items[0]?.id) {
            this.$store.commit("selectProperty", this.items[0].id)
        }
    }

    @Watch("$store.getters.selectedProperty")
    selected() {
        return this.$store.getters.selectedProperty
    }

    get organisationProperties() {
        return this.items
    }

    async change(selected: string) {
        if (selected !== ""){
            const store = this.$store as Store<StateType>
            await store.commit("selectProperty", selected)
            this.$emit("change", selected)
        }
    }
}
