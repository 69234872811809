/*
 * Purpose: providing utilities to handle reports
 *
 * Written by Scopevisio AG 2021
 */

import {Apaleoserver} from "@/util/apaleoserver"
import { ApaleoDebitor } from "./debitorutil"

export interface Reservation {
    id: string
    status: string
    arrival: Date
    departure: Date
}

export interface TransactionAccount {
    name: string
    number: string
    parentNumber: string
    type: string

    mappedDescription?: string  // account in scopevisio
    debitor?: ApaleoDebitor
    scopevisioDebitor?: boolean
    /* the effective number is either the number of the parentNumber
    depending on the type of the account
    this is the number we use for scopevisio
    */
    effectiveNumber?: string
    reservationId?: string
}



export interface SubTotal {
	amount: number;
	currency: string;
}

export interface Company {
	id: string;
	code: string;
	name: string;
}

export interface Invoice {
	id: string;
	number: string;
	type: string;
	languageCode: string;
	folioId: string;
	reservationId: string;
	propertyId: string;
	subTotal: SubTotal;
	paymentSettled: boolean;
	status: string;
	created: string;
	guestName: string;
	guestCompany: string;
	company: Company;
}

export interface InvoicesResponse {
	invoices: Invoice[]
    count: number
}

export interface InvoiceFile {
    id: string
    data: any
}

export interface Folio {
    id: string;
    created: string;
    updated: string;
    type: string;
    debitor: Debitor;
    closingDate: string;
    isMainFolio: boolean;
    isEmpty: boolean;
    reservation: Reservation;
    balance: Balance;
    checkedOutOnAccountsReceivable: boolean;
    relatedInvoices?: RelatedInvoice[];
    status: string;
}
  
interface RelatedInvoice {
   id: string;
}

interface Balance {
    amount: number;
    currency: string;
}

export interface Reservation {
    id: string;
    bookingId: string;
}

interface Debitor {
    type: string;
    title: string;
    firstName: string;
    name: string;
    address: Address;
    email: string;
}

interface Address {
    addressLine1?: string;
    postalCode?: string;
    city?: string;
    countryCode: string;
}

export interface Tax {
    type: string
    percent: number
    amount: number
}

export interface Receipt {
    type: string
    number: string
}

export interface Transaction {
    reservation: Reservation
    timestamp: Date
    date: string
    debitedAccount: TransactionAccount
    creditedAccount: TransactionAccount
    command: string
    currency: string
    grossAmount: number
    netAmount: number
    taxes: Tax[]
    receipt: Receipt
    sourceEntryNumber: string
    reference: string
    referenceType: string
    vatmatrix?: string
    taxRate?: string
    debittaxkey?: string
    credittaxkey?: string
}


export interface GrossTransactionsResponse {
    transactions: Transaction[]
    count: number
}

export class Reports {
    static async fetchGrossTransactions(propertyId: string, from: string, to: string): Promise<Transaction[]> {
        if (!propertyId) {
            return []
        }
        const transactions = await Apaleoserver.instance.getGrossTransactions({
            propertyId: propertyId,
            dateFilter: `${from},${to}`
        })

        return transactions
    }

    static async fetchAggregatedPairsDaily(propertyId: string, from: string, to: string) {
        const transactions = await Apaleoserver.instance.getAggregatePairsDailyTransactions({
            propertyId: propertyId,
            from: from,
            to: to
        })

        return transactions
    }
}