
import Page from '@/components/Page.vue'
import {Component, Vue, Watch} from "vue-property-decorator"
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"
import {Account, Accounts as AccountHelper, Accounts, AccountSchema, VatMatrix} from "@/util/accounts"
import {HotelAccountMapping} from "@/store"
import {showConfirmAsync, showNotification} from "@/util/eventbus"
import {Dimension, ImpersonalAccountRecord, NotificationType} from "@/util/scopevisiotypes"
import {Apaleoserver} from '@/util/apaleoserver'
import {Scopevisioserver} from '@/util/scopevisioserver'
import VatMatrixSelector from "@/components/VatMatrixSelector.vue"
import {Properties} from "@/util/properties"
import {ManagedData} from "@/util/manageddata"
import {Util} from '@/util/util'
import {Logger} from '@/util/logger'

@Component({
    components: {VatMatrixSelector, Page, NoMappingMessage, NoConnectionMessage},
})
export default class SettingsAccounting extends Vue {

    mappings: HotelAccountMapping = {}
    loadedMappings: Account[] = []

    scopevisioImpersonalAccounts = [] as ImpersonalAccountRecord[]
    apaleoAccounts = {} as AccountSchema

    dimension1Accounts: Dimension[] = []
    dimension2Accounts: Dimension[] = []
    vatMatrixEntries = [] as VatMatrix[]

    show = true
    loading = false
    search = ""


    async mounted() {
        
        if (this.isConnectedToScopevisio && this.isConnectedToApaleo) {
            this.setData()
        }

        // set search from url
        if (this.$route.params.account) {
            // @todo verify account against accounts
            this.search = this.$route.params.account
        }
    }


    @Watch("$store.getters.selectedProperty")
    async setData() {
        if (Properties.isSelected()) {
            this.startLoading()

            // set scopevisio accounts
            this.scopevisioImpersonalAccounts = await this.getScopevisioImpersonalAccounts()
            // Dimensions
            this.dimension1Accounts = await this.getDimensionAccounts(1)
            this.dimension2Accounts = await this.getDimensionAccounts(2)
            // get stored mappings
            if (this.$store.getters?.accountMappings) {
                this.mappings = Util.deepCopy(this.$store.getters?.accountMappings)
            }             
            this.vatMatrixEntries = await AccountHelper.getVatMatrixEntries()
            this.vatMatrixEntries?.unshift({
                    vatKey: "",
                    vatKeyDescription: "",
            } as VatMatrix)

            // set apaleo accounts      
            this.setApaleoAccounts()            
        } else {
            this.loadedMappings = [] as Account[]
        }
    }

    get loadedMappingItems() {
        return this.loadedMappings
    }

    get searchTrigger () {
        if (this.search.length < 3) {
            return ""
        }

        return this.search
    }

    get title() {
        if (this.hasHotelMappings && this.$store.getters.selectedProperty !== "") {
            return "Kontierung für " + Properties.getSelectedPropertyName()
        } else {
            return "Kontierung pro Hotel"
        }
    }

    async setApaleoAccounts() {
        this.loadedMappings = []
        
        if (this.$store.getters.selectedProperty === "") {
            return
        }  

        this.apaleoAccounts = await AccountHelper.getPropertyAccounts(
            this.$store.getters.selectedProperty
        )
        this.loadMappings()
    }

    get hasLoadedMappings() {
        return (this.loadedMappings.length > 0)
    }

    startLoading() {
        this.loading = true
        this.loadedMappings = []
    }

    scopevisioAccountName(item: ImpersonalAccountRecord) {
        return `${item.number} ${item.name}`;
    }

    async getDimensionAccounts(dimension: number) {
        const accounts = await Scopevisioserver.instance.getDimensionAccounts(dimension)

        if (accounts) {
            accounts.sort((a, b) => (a.name < b.name ? -1 : 1));
        }

        accounts.unshift({
                number: 0,
                name: " --- ",
                locked: false,
        })

        return accounts
    }

    async getScopevisioImpersonalAccounts() {
        let accountsArray = [] as any[]
        const accounts = await Scopevisioserver.instance.getImpersonalAccounts()

        if (accounts) {
            accountsArray = accounts.records
        }
                
        accountsArray.unshift({
                number: "",
                apaleoAccountNumber: 0,
                name: "",
                accountTypeName: "",
                active: true,
                directPosting: true,
                vatKeyName: "",
        } as ImpersonalAccountRecord)
        
        // add debitors
        const debitors = await Scopevisioserver.instance.debitors()
        accountsArray = accountsArray.concat(debitors)
        // eslint-disable-next-line 
        accountsArray = accountsArray.map(({apaleoAccountNumber, accountTypeName, active, categoryName, directPosting, vatKeyName, ...item}) => item)
        
        return accountsArray
    }

    loadMappings() {
        let mappingArray = [] as Account[]

        if (this.$store.getters.selectedProperty === ""){
            return mappingArray
        }
        const storeMappings = this.$store.getters.loadedAccountMappings

        // add dynamic accounts
        if (this.apaleoAccounts) {
            if (!this.apaleoAccounts["dynamic"]) {
                this.apaleoAccounts["dynamic"] = [] as Account[]
            }
            // split deposit account into single accounts per taxrate
            for (const i of Accounts.depositAccountTaxRates) {
                this.apaleoAccounts["dynamic"].unshift(
                    {
                        "name": `Verbindlichkeiten ${i}%`,
                        "type": "Liabilities",
                        "hasChildren": true,
                        "apaleoAccountNumber": `3000:${i}.00`,
                        "scopevisioAccountNumber": "",
                        "buKey": false,
                        "vatmatrix": "",
                        "dimension1": 0,
                        "dimension2": 0,
                        "path": "guestAccounts"
                    }
                )
            }
        }


        Object.values(this.apaleoAccounts).forEach(accounts => {
            for (const apaleoAccount of accounts) {

                if (apaleoAccount.apaleoAccountNumber == "3000") {
                    // Util.cl("skip account 3000", apaleoAccount);
                    continue
                }

                if (mappingArray.findIndex(x => x.apaleoAccountNumber == apaleoAccount.apaleoAccountNumber) === -1) {
                    if (storeMappings &&
                        typeof storeMappings[apaleoAccount.apaleoAccountNumber] !== "undefined"
                    ) {
                        mappingArray.push(storeMappings[apaleoAccount.apaleoAccountNumber])
                    } else {
                        mappingArray.push(apaleoAccount)
                    }
                } else {
                    // Util.cl("skip account: " + apaleoAccount.apaleoAccountNumber)
                }
            }
        }, this)
        this.loadedMappings = Util.deepCopy(mappingArray)
        this.loading = false
    }

    syncMappings() {
        for (const mapping of this.loadedMappings) {
            this.mappings = this.saveAccountInStructure(
                this.mappings,
                mapping,
            )
        }
    }

    saveAccountInStructure(mappings:HotelAccountMapping, account: Account) {
        if (mappings === undefined) {
            mappings = {}
        }
        mappings[account.apaleoAccountNumber] = account

        return mappings
    }
    
    onDynamicAccounting() {
        this.$router.push("/settings/accounting/dynamic")
    }

    async onSave() {
        const r = await showConfirmAsync({
            message: "Wollen Sie die Kontenzuordnung speichern?"
        })

        if (!r) {
            showNotification({
                type: NotificationType.INFO,
                message: "Die Kontenzuordnung wurde nicht gespeichert",
                timeoutMs: 5000
            })

            return
        }

        this.syncMappings()

        Logger.logAccountMappings(
            this.$store.getters.loadedAccountMappings,
            Util.deepCopy(this.mappings),
        )

        this.$store.commit("loadedAccountMappings", Util.deepCopy(this.mappings))

        if (await ManagedData.setValue(this.$store.state)) {
            showNotification({
                type: NotificationType.INFO,
                message: "Die Kontenzuordnung wurde gespeichert.",
                timeoutMs: 5000
            })
        }
    }

    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    
    get hasSelectedProperty() {
        return this.$store.getters.selectedProperty !== ""
    }

    get hasHotelMappings() {
        return Properties.hasHotelMappings()
    }
    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }

    dimensionName(item: Dimension) {
        if (item.number == 0) {
            return ""
        }

        return `${item.number} ${item.name}`;
    }

    headers = [
        { text: 'Apaleo Kontoname', value: 'name'},
        { text: 'Apaleo Kontonummer', value: 'apaleoAccountNumber'},
        { text: 'Kontotyp', value: 'type', filterable: false},
        { text: 'Scopevisio Konto', value: 'scopevisioAccountNumber', sortable: true, filterable: false},
        { text: 'Steuerschlüssel', value: 'vatmatrix', sortable: false, filterable: false},
        { text: 'Dimension 1', value: 'dimension1', sortable: false, filterable: false},
        { text: 'Dimension 2', value: 'dimension2', sortable: false, filterable: false},
    ]
}
