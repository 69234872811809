/*
 * Purpose: providing utilities to handle scopevisio authentication
 *
 * Written by Scopevisio AG 2021
 */

import { Util} from '@/util/util'

export class ScopevisioAuth {

    static startAuth() {
        const state = Util.makeCode(20)
        let redirectUri = ''
        const env =  process?.env?.VUE_APP_ENV ?? 'local'
        switch (env) {
            case 'local': redirectUri = 'http://localhost:8080/scopevisioauth'; break;
            case 'staging': redirectUri = 'https://apaleo.staging.scopevisio.com/scopevisioauth'; break;
            case 'production': redirectUri = 'https://apaleo.scopevisio.com/scopevisioauth'; break;
        }

        if (process?.env?.VUE_APP_DEBUGSTEPAN) {
            redirectUri = `http://localhost:8080/scopevisioauth`
        }

        if (process?.env?.VUE_APP_DEBUGMARTIN) {
            redirectUri = `http://localhost:8080/scopevisioauth`
        }

        const url = 
            `https://appload.scopevisio.com/static/authorize.html?client_id=apaleo`
                + `&state=${encodeURIComponent(state)}`
                + `&redirect_uri=${encodeURIComponent(redirectUri)}`
                + `&response_type=code`
        const href = url
        window.location.href = href
    }
}