
import { Component, Vue, Prop } from "vue-property-decorator";
import {Organisation} from "@/util/scopevisiotypes";
import {Scopevisioserver} from "@/util/scopevisioserver";
import { Util } from "@/util/util";


@Component
export default class ScopevisioOrganisationSelector extends Vue {
    @Prop({default: false})
    dense!: boolean
    @Prop({default: false})
    outlined!: boolean
    @Prop({default: false})
    hidedetails!: boolean
    @Prop({default: ""})
    label!: string
    @Prop({})
    selected!: Organisation
    @Prop({default: ""})
    reference!: string
    @Prop({})
    value!: number
    @Prop({})
    organisations!: Organisation[]

    items = [] as  Organisation[]

    async mounted() {
        Util.cl("MM", this.organisations)
        if (this.organisations?.length) {
            this.items = this.organisations
        } else {
            const organisations = await Scopevisioserver.instance.getOrganisations()
            if (organisations) {
                this.items = organisations.records
            }
        }
    }

    organisationName(item: Organisation) {
        if (item.id == 0) {
            return ""
        }

        return item.name
    }

    async change(selected: Organisation) {
        if (selected !== null){
            this.$emit("selected", selected, this.reference)
        }
    }
}
