/*
 * Purpose: providing utilities to authenticate against apaleo
 *
 * Written by Scopevisio AG 2021
 */

import store from "@/store"
import {Util} from "./util"

export class ApaleoAuth {

    static isConnected() {
        return !!store.getters.apaleoRefreshToken
    }

    static getClientId() {
        return 'SBWQ-AC-SCOPEVISIOCONNECTOR'
    }

    static getRedirectUri() {
        let redirectUri = ''
        const env =  process?.env?.VUE_APP_ENV ?? 'local'
        switch (env) {
            case 'local': redirectUri = 'http://localhost:8080/auth'; break;
            case 'staging': redirectUri = 'https://apaleo.staging.scopevisio.com/auth'; break;
            case 'production': redirectUri = 'https://apaleo.scopevisio.com/auth'; break;
        }

        if (process?.env?.VUE_APP_DEBUGSTEPAN) {
            redirectUri = `http://localhost:8080/auth`
        }

        if (process?.env?.VUE_APP_DEBUGMARTIN) {
            redirectUri = `http://localhost:8080/auth`
        }
        return redirectUri
    }

    static startAuth() {
        const state = Util.makeCode(20)

        //const scope = `offline_access identity:account-users.manage identity:account-users.read payment:configuration.read payment:invoices.read payment:reports.read account.manage accounting.read availability.manage availability.read companies.manage companies.read folios.manage folios.payment-with-charges folios.read invoices.manage invoices.read logs.read maintenances.manage maintenances.read offer-index.read offers.read operations.change-room-state operations.trigger-night-audit rateplans.read-corporate rateplans.read-negotiated rates.manage rates.read reports.read reservations.force-manage reservations.manage reservations.read setup.manage setup.read`
        // last working -> const scope = `offline_access companies.read folios.manage folios.payment-with-charges folios.read identity:account-users.manage identity:account-users.read integration:ui-integrations.manage invoices.manage invoices.read logs.read payment:configuration.read payment:invoices.read payment:reports.read reports.read reservations.read setup.read accounting.read`
        const scope = `offline_access companies.read identity:account-users.read invoices.read logs.read reports.read reservations.read setup.read accounting.read`
        
        const href = `https://identity.apaleo.com/connect/authorize?response_type=code&scope=${encodeURIComponent(scope)}&client_id=${ApaleoAuth.getClientId()}&redirect_uri=${encodeURIComponent(ApaleoAuth.getRedirectUri())}&state=${encodeURIComponent(state)}`
        window.location.href = href
    }
}


/*

-- post via reservations -> reservation MQYLXUFD-1 (eg) -> folios -> + Add Charge

-- get transactions via gross report
--  dateformat: YYYY-MM-DD

curl -X POST "https://api.apaleo.com/reports/v0-nsfw/reports/gross-transactions?propertyId=BER&dateFilter=gte_2021-06-07,lte_2021-06-07" -H  "accept: application/json" -H  "authorization: Bearer ..." -d ""


-- get statistics via

curl -X GET "https://api.apaleo.com/reports/v0-nsfw/reports/property-performance?propertyId=BER&from=2021-06-01&to=2021-06-30&expand=businessDays" -H  "accept: application/json"

(zimmer_= soldItems ...)

-- see it in action:

https://public.teamwork.scopevisio.com/d3e24df6-6290-4f6a-bbc2-094c1e7efcee


*/

