/*
 * Purpose: providing utilities to handle scopevisio types
 *
 * these interfaces and classes are types that are either input for
 * the scopevisio apis or returned by the api
 * 
 * Written by Scopevisio AG 2021
 */

import { VatMatrix } from "./accounts"

export enum NotificationType {
    ERROR,
    WARNING,
    INFO,
    SUCCESS ,
}

export enum ConnectionState {
    PENDING,
    DISCONNECTED,
    CONNECTED ,
}

export interface NotificationInfo {
    type: NotificationType
    message: string
    timeoutMs?: number
}

export interface ConfirmInfo {
    message: string
    onSuccess: () => void
    onCancel?: () => void
}

export interface Credentials {

    customer: string
    username: string
    password: string
    organisation?: string
    saveLogin?: boolean
    baseUrl?: string
}

export interface CustomerInfo {
    customer: string
    username: string
}

export interface LoginResponse {
    access_token: string
    refresh_token: string
    expires_in: number
    organisationId: number
    organisationName: string
    teamworkTenantId: string
    token_type: string
    uid: string
}

export interface User {
    firstName: string
    lastName: string
    login: string
    status: string
    profiles?: Profile[]
    uid: string
}

export interface Profile {
    name: string
    organisation: string
}

export interface ScopevisioAccountResponse {
    customer: {
        name: string
        publicId: string
        status: string
    }
    organisation: Organisation
    organisations?: Organisation[]
    teamwork: {
        teamworkAppRootUrl: string
    }
    user: User
}

export interface OrganisationsResponse {
    records: Organisation[]
}
export interface Organisation{
    id: number
    name: string
    teamworkTenantId?: string
    teamworkTenantName?: string
}

export interface ManagedData {
    id: number
    organisation: number
    tableId: number
    createdTs: number
    creatorUid: string
    creatorName: string
    modifiedTs: number
    modifierUid: string
    modifierName: string
    permissionDisplay: string
    ownerUid: string
    permissionType: number
    key: string
    value: string
}
export interface ManagedDataStatus {
    id: number
    tableId: number
    modifiedTs: number
    modifierName: string
    message?: string
    code?: number
}

export interface LoginResponse {
    access_token: string
    refresh_token: string
    expires_in: number
    organisationId: number
    organisationName: string
    teamworkTenantId: string
    token_type: string
    uid: string
}

export interface Errors {
    message: string
}
/* 
export interface CreateContactResponse {
    status: number
    contactId: number
    location: string
    errors: Errors
} */
export interface CreateDebitorResponse {
    status: number
    number: string
    name?: string
    created: boolean
    errors: Errors
    apaleoDebitorNumber?: string
}
export interface UpdateDebitorResponse {
    status: number
    errors: Errors
    apaleoDebitorNumber?:string
    loadedDebitorNumber?: string
    updatedDebitorNumber?: string
}

export interface OrganisationRecord {
    id: number
    name: string
}

export interface OrganisationsResponse {
    records: Organisation[]
}

export interface StatisticsUnitsResponse {
    records: string[]
}

export interface ImpersonalAccountRecord {
    number: string
    name: string
    accountTypeName: string
    active: boolean
    directPosting: boolean
    vatKeyName: string | null | undefined
}

export interface ImpersonalAccountsResponse {
    records: ImpersonalAccountRecord[]
}

export interface Dimension {
    number: number
    name: string
    locked: boolean
}

export interface DimensionsResponse {
    records: Dimension[]
}

export interface PostingsRequest {
    generateVat: boolean
    postingBatch: boolean
    importMasterDataEnabled: boolean
    importMasterDataMode: string
    importFilesEnabled: boolean
    generateVatCreatorInfos: boolean
    refreshPaymentTerm: boolean
    postingXml: string
}

export interface PostingsResponse {
    r: any
    message?: string
}

export interface VatMatrixEntriesResponse {
    records: VatMatrix[]
}



export interface CustomField {
    customFieldName: string
    customFieldValue: string
}

export interface Employer {
    employerId: number
    legacyNumber: string
    companyType: string
    companyDocumentVisibility: boolean
    companyCommentVisibility: boolean
    companyTaskVisibility: boolean
    companyInfoVisibility: boolean
    validFrom: number
    validTo: number
}

export interface CreateContactRequest {
    person: boolean
    salutation: string
    title: string
    lastname: string
    firstname: string
    paymentTypeName: string
    federalState: string
    federalState2: string
    federalState3: string
    federalState4: string
    federalState5: string
    federalState6: string
    street1: string
    street2: string
    street3: string
    street4: string
    street5: string
    street6: string
    addressExtra1: string
    addressExtra2: string
    addressExtra3: string
    addressExtra4: string
    addressExtra5: string
    addressExtra6: string
    city1: string
    city2: string
    city3: string
    city4: string
    city5: string
    city6: string
    postcode1: string
    postcode2: string
    postcode3: string
    postcode4: string
    postcode5: string
    postcode6: string
    country1: string
    country2: string
    country3: string
    country4: string
    country5: string
    country6: string
    email: string
    email2: string
    email3: string
    email4: string
    email5: string
    email6: string
    phone: string
    phone2: string
    phone3: string
    phone4: string
    phone5: string
    phone6: string
    mobile: string
    mobile2: string
    mobile3: string
    mobile4: string
    mobile5: string
    mobile6: string
    fax: string
    fax2: string
    fax3: string
    fax4: string
    fax5: string
    fax6: string
    preferredKontaktType: string
    preferredInvoiceSendMethod: string
    language: string
    position: string
    positionReplacement: string
    department: string
    industry: string
    organisationType: string
    tradeRegisterName: string
    tradeRegisterNumber: string
    customerNumber: string
    legacyNumber: string
    tags: string
    website: string
    description: string
    vatId: string
    currency: string
    customFields: CustomField[]
    employers: Employer[]
    deliveryTermIncoterm: string
    deliveryTermIncotermLocation: string
    personalDataInsuranceType: string
    personalDataInsurance: string
    personalDataInsuranceNumber: string
    personalDataCareInsurance: string
    personalDataCareInsuranceNumber: string
    personalDataAnnuityInsurance: string
    ownerUid: string
    permissionType: number
    readPermissionProfiles: string
    readPermissionLogins: string
    writePermissionProfiles: string
    writePermissionLogins: string
}