
import ButtonBar from '@/components/ButtonBar.vue'
import Page from '@/components/Page.vue'
import { Component, Vue, Watch } from "vue-property-decorator";
import {Properties} from "@/util/properties";
import { Apaleoserver } from '@/util/apaleoserver';
import { Scopevisioserver } from '@/util/scopevisioserver';
import {Logger} from '@/util/logger';
import moment from 'moment';
import {Util} from '@/util/util';
import { Organisations } from '@/util/organisations';
import { showNotification } from '@/util/eventbus';
import { NotificationType } from '@/util/scopevisiotypes';

@Component( {
    components: { ButtonBar, Page }
})
export default class Home extends Vue {

    countLastTransactions = 0
    countLastStatistics = 0
    countLastDebitors = 0
    yesterday = Util.formatDateGerman(moment().clone().subtract(1, 'days').format('YYYY-MM-DD'))
    activeRightsCheck = false

    mounted() {
        this.setStatistics()

        // check neccessary rights
        if (this.activeRightsCheck && this.isConnectedToScopevisio) {
            const rightsScopevisio = Scopevisioserver.usageRights(
                this.$store.getters.scopevisioOrganisation.name, 
                this.$store.getters.scopevisioAccount.user.profiles
            )
            if (rightsScopevisio.failed.length !== 10) {
                showNotification({
                    type: NotificationType.ERROR,
                    message: "Rechteproblem: Es fehlen folgende Rechte: " + rightsScopevisio.ok.join(),
                    timeoutMs: 1000000
                })
            }
        }
    }


    @Watch("$store.getters.selectedProperty")
    async setStatistics() {
        this.countLastTransactions = (await Logger.getLogs({
            dateFrom: moment().clone().subtract(1, 'days').format('YYYY-MM-DD'),
            dateTo: moment().clone().subtract(1, 'days').format('YYYY-MM-DD'),
            actionType: Logger.actionTypeTransactionTransferPosting,
        })).length
        this.countLastStatistics = (await Logger.getLogs({
            dateFrom: moment().clone().subtract(1, 'days').format('YYYY-MM-DD'),
            dateTo: moment().clone().subtract(0, 'days').format('YYYY-MM-DD'),
            actionType: Logger.actionTypeStatisticTransactionTransferPosting,
        })).length
        this.countLastDebitors = (await Logger.getLogs({
            dateFrom: moment().clone().subtract(1, 'days').format('YYYY-MM-DD'),
            dateTo: moment().clone().subtract(1, 'days').format('YYYY-MM-DD'),
            actionType: "create debitor",
        })).length
    }

    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    get organisationChangeInProgress() {
        Util.cl("organisationChangeInProgress",this.$store.getters.scopevisioOrganisation.id,!this.$store.getters.scopevisioOrganisation.id,Organisations.changeInProgress)
        return Organisations.changeInProgress
    }
    get hasHotelMappings() {
        return Properties.hasHotelMappings()
    }
    get hasSelectedProperty() {
        return this.$store.getters.selectedProperty !== ""
    }
    get countLastTransactionsNumber() {
        return this.countLastTransactions
    }
    get countLastStatisticsNumber() {
        return this.countLastStatistics
    }
    get countLastDebitorsNumber() {
        return this.countLastDebitors
    }

    onMapProperties()  {
        this.$router.push("/settings/mapping")
    }

    onTransactionDetails() {
        this.$router.push("/journal/transaktionsbuchungen/yesterday")
    }
    onStatisticDetails() {
        this.$router.push("/journal/statistikbuchungen/yesterday")
    }
    onDebitorDetails() {
        this.$router.push("/journal/debitoren/yesterday")
    }

    noMappingPresent() {
        if (!this.$store.getters.scopevisioAccount) {
            return false
        }

        return Properties.noMappedHotels()
    }

    noMappingMessage() {
        if (!this.$store.getters.scopevisioAccount) {
            return false
        }

        return "Der Gesellschaft '" + this.$store.getters.scopevisioAccount.organisation?.name + "' sind keine Hotels zugeordnet."
    }
}
