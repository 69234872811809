

import Page from "@/components/Page.vue"
import { Component, Vue } from "vue-property-decorator"
import { Scopevisioserver } from "@/util/scopevisioserver"
import { QueryParser} from '@/util/queryparser'
import { Properties } from "@/util/properties"
import { Apaleoserver } from "@/util/apaleoserver"
import { ConnectionState } from "@/util/scopevisiotypes"

@Component({
    components: { Page },
})
export default class ScopevisioAuth extends Vue {
    propertyCount = 0

    async mounted() {
        // example use of store
        const query = QueryParser.parse(window.location.href)
        const code = query.code || ""
        //const state = query.state || ""

        await Scopevisioserver.instance.fetchRefreshToken(code)
        
        if (Apaleoserver.instance.checkConnection() == ConnectionState.CONNECTED) {
            await Properties.refreshPropertiesFromApaleo()
        }

        this.$router.push("/settings/scopevisio")
    }

    get myAccount() {
        return (this.$store.getters.scopevisioAccount)
    }
}
