

import {Component, Vue, Watch} from "vue-property-decorator"
import Page from '@/components/Page.vue'
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"
import {NotificationType} from "@/util/scopevisiotypes"
import {Apaleoserver} from '@/util/apaleoserver'
import {DebitorUtil, ScopevisioDebitor, ApaleoDebitor, ScopevisioContacts} from '@/util/debitorutil'
import {Scopevisioserver} from '@/util/scopevisioserver'
import {Logger} from '@/util/logger'
import {Util} from '@/util/util'
import { showConfirmAsync, showNotification } from '@/util/eventbus'
import { Properties } from '@/util/properties'
import { Settings } from "@/util/settings"
import { Organisations } from '@/util/organisations'


interface Action {
    action: string
    name: string
}


@Component({
    components: {Page, NoMappingMessage, NoConnectionMessage},
})
export default class Debitors extends Vue {

    show = false
    showSendButton = true
    loading = false
    search = ""

    apaleoDebitors: ApaleoDebitor[] = []
    scopevisioDebitors: ScopevisioDebitor[] = []
    scopevisioContacts: ScopevisioContacts = {}
    usedScopevisioDebitors: ScopevisioDebitor[] = []

    actions = [
        {
            action: "create",
            name: "anlegen",
        },
        {
            action: "",
            name: "nicht anlegen",
        }
    ] as Action[]


    async mounted() {
        if (this.isConnectedToScopevisio && this.hasHotelMappings) {
            await this.setScopevisioDebitorItems()       
            await this.setDebitorMappings()
        }        
        // select account from url
        if (this.$route.params.account) {
            // @todo verify account against accounts
            this.search = this.$route.params.account
        }
        // activate modals
        this.show = true
    }


    get allowDebitorSynchronisation() {
        return Settings.manualDebitorSynchronisation()
    }
    get debitorItems() {
        return this.apaleoDebitors
    }
    get allDebitorsMapped() {
        const unmappedDebitor = this.apaleoDebitors.find(debitor => 
            !debitor.scopevisioDebitorNumber || debitor.scopevisioDebitorNumber == ""
        )

        return !(unmappedDebitor)
    }

    get organisationChangeInProgress() {
        return Organisations.changeInProgress
    }

    async updateDebitorData(apaleoDebitor: ApaleoDebitor) {        
        const ok = await showConfirmAsync({
            message: `Soll der Debitor ${apaleoDebitor.companyname} aktualisiert werden?`
        })

        if (!ok) {
            return false
        }
        
        const data = {
            lastname: apaleoDebitor.companyname,
            street1: apaleoDebitor.street,
            postcode1: apaleoDebitor.zipcode,
            country1: apaleoDebitor.countrycode,
        }

        if (apaleoDebitor.scopevisioDebitorContactId &&
            await Scopevisioserver.instance.updateContact(apaleoDebitor.scopevisioDebitorContactId, data)
        ) {
            if (!data.lastname) {
                data.lastname = apaleoDebitor.name
            }
            Logger.logDebitorUpdate(data)

            showNotification({
                type: NotificationType.INFO,
                message: `Kontakt ${data.lastname} aktualisiert`
            })
            this.setDebitorMappings()
        } else {
            showNotification({
                type: NotificationType.ERROR,
                message: `Kontakt ${data.lastname} nicht aktualisiert`
            })
        }
    }

    startLoading() {
        this.loading = true
    }

    getCountryNameByIsoCode(code: string) {
        return Util.getCountryNameByIsoCode(code)
    }
 
    getScopevisioDebitorByNumber(number: string) {
        return this.scopevisioDebitors.find((svDebitor: ScopevisioDebitor) => svDebitor.number === number)
    }

    isSumAccount(apaleoDebitor: ApaleoDebitor) {
        return (apaleoDebitor.scopevisioDebitorNumber == this.$store.getters.loadedBasicSettings?.debitorAccount)
    }

    @Watch("$store.getters.scopevisioAccount.organisation")
    async setScopevisioDebitorItems() {
        this.scopevisioDebitors = await Scopevisioserver.instance.debitors()
    }

    @Watch("$store.getters.selectedProperty")
    async setDebitorMappings() {
        this.startLoading()

        try {
            let apaleoDebitors = await DebitorUtil.loadApaleoDebitors(this.propertyId)
            apaleoDebitors = [...apaleoDebitors]
            this.usedScopevisioDebitors = []

            for (let apaleoDebitor of apaleoDebitors) {
                let connectedScopevisioDebitor = this.scopevisioDebitors.find(
                    (scopevisioDebitor: ScopevisioDebitor) => 
                        apaleoDebitor.number == scopevisioDebitor.externalNumber,
                )
                    
                if ( connectedScopevisioDebitor) {
                    apaleoDebitor.scopevisioDebitorNumber = connectedScopevisioDebitor.number
                    apaleoDebitor.scopevisioDebitorContactId = connectedScopevisioDebitor.contactId
                    this.usedScopevisioDebitors.push(connectedScopevisioDebitor)                    
                }
            }
            // remove CollectiveDebitor_AccountsReceivable
            apaleoDebitors = apaleoDebitors.filter(
                (apaleoDebitor: ApaleoDebitor) => apaleoDebitor.number != DebitorUtil.sumAccountNumber
            )
         
            // set debitors contact data if present
            const contactIds = DebitorUtil.getContactIds(this.usedScopevisioDebitors)
            
            if (contactIds.length > 0) {
                this.scopevisioContacts = await DebitorUtil.getContacts(
                    DebitorUtil.getContactSearch(contactIds)
                )
            } else {
                this.scopevisioContacts = {}
            }

            for (let apaleoDebitor of apaleoDebitors) {
                let connectedScopevisioDebitor = this.scopevisioDebitors.find(
                    (scopevisioDebitor: ScopevisioDebitor) => 
                        apaleoDebitor.number == scopevisioDebitor.externalNumber,
                )
                if ( connectedScopevisioDebitor) {
                    apaleoDebitor.scopevisioDebitorDataChanged = await this.hasDebitorDataChanged(apaleoDebitor, connectedScopevisioDebitor)
                }
            }

            this.apaleoDebitors = apaleoDebitors
        } finally {
            this.loading = false
        }
    }

    async hasDebitorDataChanged(apaleoDebitor: ApaleoDebitor, scopevisioDebitor: ScopevisioDebitor) {
        const scopevisioContact = this.scopevisioContacts[scopevisioDebitor.contactId]
        
        if (apaleoDebitor.companyname != scopevisioContact?.lastname ||
            apaleoDebitor.street != scopevisioContact?.street1 ||
            apaleoDebitor.zipcode != scopevisioContact?.postcode1 
        ) {
            return true
        } else {
            return false
        }        
    }

    get hasHotelMappings() {
        return Properties.hasHotelMappings()
    }
    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }    
    get propertyId()  {
        return this.$store.getters.selectedProperty
    }
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    async onSave() {
        this.showSendButton = false
        this.loading = true

        const apaleoDebitors = Util.deepCopy(this.apaleoDebitors)
        this.apaleoDebitors = []
        
        const newDebitors = await DebitorUtil.createDebitors(apaleoDebitors)

        if (newDebitors && newDebitors.length > 0) {
            for (const newDebitor of newDebitors) {
                if (newDebitor.status != 201) {
                    showNotification({
                        type: NotificationType.ERROR,
                        message: `Apaleo Debitor ${newDebitor.apaleoDebitorNumber} konnte nicht angelegt werden.`,
                        timeoutMs: 10000
                    })
                }
            }
        }
        // extend account mappings with new debitors
        await DebitorUtil.extendAccountMappings(newDebitors)
        
        await this.setScopevisioDebitorItems()

        Logger.logDebitorCreation(newDebitors)
        
        await this.setDebitorMappings()

        this.loading = false
        this.showSendButton = true
    }

    headers = [
        { text: 'Apaleo Debitor ID', sortable: true, value: 'number'},
        { text: 'Apaleo Debitor Name', sortable: true, value: 'name'},
        { text: 'Firma', sortable: true, value: 'companyname'},
        { text: 'Straße', sortable: true, value: 'street'},
        { text: 'PLZ', sortable: true, value: 'zipcode'},
        { text: 'Stadt', sortable: true, value: 'city'},
        { text: 'Land', sortable: true, value: 'countrycode'},
        { text: 'Debitor aktuell', sortable: true, value: 'scopevisioDebitorDataChanged'},
        { text: 'Scopevisio Debitor (ID)', sortable: true, value: 'scopevisioDebitorNumber'}
    ]
}
