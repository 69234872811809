
import Vue from 'vue';
import Menu from '@/components/Menu.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import Component from 'vue-class-component';
import {
    EVENT_BUS,
    ACTION_NOTIFICATION,
    ACTION_CONFIRM,
} from "@/util/eventbus";
import { ConfirmInfo, NotificationInfo, NotificationType } from './util/scopevisiotypes';

/*
 * prepare a css variable to fix the 100vh bug of ios 
 * vuetify suffers from the ios inaccuracies
 */
const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()



/* Application main Vue Component, the root 
 * of all vue components in the app.
 * 
 * Generic items like the snackbar for events or the 
 * global confirm dialog are hosted inside the app-vue as well.
 */
@Component({
    components: {
        Menu,
        ConfirmDialog,
    },
})
export default class MyApp extends Vue {
    snackBarTimeout = 5000;
    snackbarVisible = false;
    snackbarText = `Info`;
    snackbarColor = "red";
    transitionName = 'slide-left';
    menuShown = true;

    async created() {

        /* confirmations and notifications can be triggered with
         * global methods
         * these global Methods are defined in the eventbus file
         *
         * developers can just invoke  confirmDialog() programmatically
         * and should not add their own vue components to do so.
         */
        EVENT_BUS.$on(ACTION_NOTIFICATION, (notification: NotificationInfo) => {
            this.snackbarVisible = true
            this.snackbarText = notification.message
            this.snackBarTimeout = notification.timeoutMs ?? 500000
            switch (notification.type) {
                case NotificationType.ERROR:
                    this.snackbarColor = "red"
                    break
                case NotificationType.WARNING:
                    this.snackbarColor = "yellow"
                    break
                case NotificationType.INFO:
                    this.snackbarColor = "green"
                    break
            }
        })
        EVENT_BUS.$on(ACTION_CONFIRM, (confirm: ConfirmInfo) => {
            this.getConfirmDialog()
                .open("Bestätigung", confirm.message)
                .then((value: any) => {
                    const confirmed = value as boolean
                    if (confirmed) {
                        confirm.onSuccess()
                    } else {
                        if (confirm.onCancel) {
                            confirm.onCancel()
                        }
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        })
    }

    getMenu() {
        return this.$refs.menu as Menu
    }

    getConfirmDialog() {
        return this.$refs.confirmdialog as ConfirmDialog
    }

    onToggleMenu() {
        this.getMenu().toggle()
    }






}
