/*
 * Purpose: providing utilities to handle the journal
 *
 * Written by Scopevisio AG 2021
 */

import {Scopevisioserver} from "@/util/scopevisioserver";
import {Apaleoserver} from "@/util/apaleoserver";
import moment from "moment";
import store from "@/store";


export interface GrossUnitRevenue {
    amount: number;
    currency: string;
}

export interface NetUnitRevenue {
    amount: number;
    currency: string;
}

export interface GrossAdr {
    amount: number;
    currency: string;
}

export interface NetAdr {
    amount: number;
    currency: string;
}

export interface RevPar {
    amount: number;
    currency: string;
}

export interface GrossUnitRevenue2 {
    amount: number;
    currency: string;
}

export interface NetUnitRevenue2 {
    amount: number;
    currency: string;
}

export interface GrossAdr2 {
    amount: number;
    currency: string;
}

export interface NetAdr2 {
    amount: number;
    currency: string;
}

export interface RevPar2 {
    amount: number;
    currency: string;
}

export interface UnitGroup2 {
    id: string;
    code: string;
    name: string;
    description: string;
    type: string;
}

export interface GrossUnitRevenue3 {
    amount: number;
    currency: string;
}

export interface NetUnitRevenue3 {
    amount: number;
    currency: string;
}

export interface GrossAdr3 {
    amount: number;
    currency: string;
}

export interface NetAdr3 {
    amount: number;
    currency: string;
}

export interface RevPar3 {
    amount: number;
    currency: string;
}

export interface UnitGroup {
    unitGroup: UnitGroup2;
    houseCount: number;
    houseItemsCount: number;
    soldCount: number;
    soldItemsCount: number;
    unsoldCount: number;
    unsoldItemsCount: number;
    outOfOrderCount: number;
    outOfOrderItemsCount: number;
    tentativelyBlockedCount: number;
    tentativelyBlockedItemsCount: number;
    arrivalsCount: number;
    departuresCount: number;
    noShowsCount: number;
    cancellationsCount: number;
    occupancyPercentage: number;
    grossUnitRevenue: GrossUnitRevenue3;
    netUnitRevenue: NetUnitRevenue3;
    grossAdr: GrossAdr3;
    netAdr: NetAdr3;
    revPar: RevPar3;
}

export interface BusinessDay {
    businessDay: string;
    houseCount: number;
    houseItemsCount: number;
    soldCount: number;
    soldItemsCount: number;
    unsoldCount: number;
    unsoldItemsCount: number;
    outOfOrderCount: number;
    outOfOrderItemsCount: number;
    tentativelyBlockedCount: number;
    tentativelyBlockedItemsCount: number;
    arrivalsCount: number;
    departuresCount: number;
    noShowsCount: number;
    cancellationsCount: number;
    occupancyPercentage: number;
    grossUnitRevenue: GrossUnitRevenue2;
    netUnitRevenue: NetUnitRevenue2;
    grossAdr: GrossAdr2;
    netAdr: NetAdr2;
    revPar: RevPar2;
    unitGroups: UnitGroup[];
}

export interface PropertyPerformance {
    houseCount: number
    houseItemsCount: number
    soldCount: number
    soldItemsCount: number
    unsoldCount: number
    unsoldItemsCount: number
    outOfOrderCount: number
    outOfOrderItemsCount: number
    tentativelyBlockedCount: number
    tentativelyBlockedItemsCount: number
    arrivalsCount: number
    departuresCount: number
    noShowsCount: number
    cancellationsCount: number
    occupancyPercentage: number
    grossUnitRevenue: GrossUnitRevenue
    netUnitRevenue: NetUnitRevenue
    grossAdr: GrossAdr
    netAdr: NetAdr
    revPar: RevPar
    businessDays?: BusinessDay[]
    date?: string
}

export interface StatisticsPostingRequest {
    autoCreateAccount: boolean
    autoAccountDecimal: number
    statisticsPostings: StatisticsPosting[]
}

export interface StatisticsPosting {
    date: number;
    accountNumber: string;
    amount: number;
    text: string;
    zoneNumber: string;
    dimension_1?: number;
    dimension_2: number;
    dimension_3: number;
    dimension_4: number;
    dimension_5: number;
    dimension_6: number;
    dimension_7: number;
    dimension_8: number;
    dimension_9: number;
    dimension_10: number;
}

export interface StatisticsPosting_Full {
    rowNumber: number
    accountingArea: number
    createdTs: number
    creatorUid: string
    creatorName: string
    modifiedTs: number
    modifierUid: string
    modifierName: string
    zoneId?: any
    zoneNumber?: any
    zoneName?: any
    postingDate: number
    accountId: number
    accountNumber: string
    accountName: string
    amount: number
    debitAmount: number
    creditAmount?: any
    postingText: string
    documentDimension_1: number
    documentDimension_2: number
    documentDimension_3: number
    documentDimension_4: number
    documentDimension_5: number
    documentDimension_6: number
    documentDimension_7: number
    documentDimension_8: number
    documentDimension_9: number
    documentDimension_10: number
}

export interface StatisticsAccount {
    id: number
    accountingArea: number
    createdTs: number
    creatorUid: string
    creatorName: string
    modifiedTs: number
    modifierUid: string
    modifierName: string
    number: string
    name: string
    precision: number
    unit: string
}

export interface StatisticsAccountMapping {
    apaleoStatisticAccountId: string
    apaleoStatisticAccountName: string
    scopevisioStatisticAccountNumber: string
    scopevisioStatisticAccountName: string
    costUnit: number
    unit: string
}



export class Statistics {
    static unassigned = "<unzugeordnet>"

    static async getPropertyPerformance(propertyId: string, from: string, to: string) {
        const fromDate = moment(from)
        const toDate = moment(to)
        const statisticsAccountMappings = this.getStatisticsAccountMappings()
        let journals: any[] = []

        for (let loop = fromDate; loop.diff(toDate, 'days') <= 0; loop.add(1, 'days')) {
            const loopString = loop.format("YYYY-MM-DD")
            const dailyPerformance = await Apaleoserver.instance.getPropertyPerformance(
                {
                    propertyId: propertyId,
                    from: loopString,
                    to: loopString,
                    expand: "businessDays",
                    timeSliceDefinitionIds: propertyId + "-NIGHT"
                }
            )
            
            if (dailyPerformance && dailyPerformance.businessDays && dailyPerformance.businessDays[0]) {
                const businessDay = dailyPerformance.businessDays[0]
                
                if (dailyPerformance !== null) {
                    const day = moment(loopString).valueOf()
                    journals = [...journals, ...this.getStatisticPostings(statisticsAccountMappings, businessDay as PropertyPerformance, day)]
                }
            }
        }


        return journals
    }

    static getStatisticsAccountMappings() {
        const dictionary = []
        const statisticsAccountMappings =
            store.getters.loadedStatisticsAccountMappings
        
        for (const mapping of statisticsAccountMappings) {
            if (mapping.scopevisioStatisticAccountNumber) {
                dictionary[mapping.apaleoStatisticAccountId] = mapping
            }
        }

        return dictionary
    }

    static getStatisticPostings(mappings:StatisticsAccountMapping[], performance: PropertyPerformance, date: number) {
        const statisticsPostings = []

        if (performance) {
            for (const [, mapping] of Object.entries(mappings)) {
                let amount = 0
    
                if (mapping.apaleoStatisticAccountId in performance) {
                    amount = Number(performance[mapping.apaleoStatisticAccountId as keyof PropertyPerformance])
                }
    
                if (amount <= 0) {
                    continue
                }
    
                const posting = {
                    date: date,
                    accountNumber: mapping.scopevisioStatisticAccountNumber,
                    amount: amount,
                    text: mapping.apaleoStatisticAccountName,
                    zoneNumber: "unknown",
                    dimension_1: mapping.costUnit,
                }
                statisticsPostings.push(posting)
            }
        }

        return statisticsPostings
    }

    static getStatisticsUnits() {
        return Scopevisioserver.instance.getStatisticsUnits()
    }

    static getStatisticsPostings() {
        return Scopevisioserver.instance.getStatisticsPostings()
    }

    static getStatisticsAccounts() {
        return Scopevisioserver.instance.getStatisticsAccounts()
    }

    static getStatisticMappings() {
        if (!store.getters.loadedStatisticsAccountMappings) {
            return [] as StatisticsAccountMapping[]
        }
        
        return store.getters.loadedStatisticsAccountMappings
            .filter((mapping: StatisticsAccountMapping) => 
                mapping.scopevisioStatisticAccountNumber && 
                mapping.scopevisioStatisticAccountNumber != Statistics.unassigned
            )
    }

    static hasStatisticMappings() {
        return Scopevisioserver.instance.organisationChangeInProgress || 
            (Statistics.getStatisticMappings().length > 0)
    }

    static getApaleoOccupationUnits() {
        return [
            {
                id: "houseCount",
                name: "House Count",
            },
            {
                id: "outOfOrderCount",
                name: "Out of Order",
            },
            {
                id: "soldCount",
                name: "Verkaufte Einheiten",
            },
            {
                id: "unsoldCount",
                name: "Verfügbare Einheiten",
            },
            {
                id: "soldItemsCount",
                name: "Belegung",
            },
            {
                id: "arrivalsCount",
                name: "Anreisen",
            },
            {
                id: "departuresCount",
                name: "Abreisen",
            },
            {
                id: "noShows",
                name: "No-Shows",
            },
            {
                id: "cancellationsCount",
                name: "Stornierungen",
            },
            {
                id: "tentativelyBlockedCount",
                name: "Vorläufig geblockt",
            },
        ]
    }
}