/*
 * Purpose: the complete routing is modelled in this file
 *
 * (c) by Scopevisio AG 2021
 */

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Imprint from '@/views/Imprint.vue'
import Auth from '@/views/Auth.vue'
import ScopevisioAuthDestination from '@/views/ScopevisioAuthDestination.vue'
import SettingsScopevisio from "@/views/SettingsScopevisio.vue";
import SettingsApaleo from "@/views/SettingsApaleo.vue"
import Export from "@/views/Export.vue"
import Logs  from "@/views/Journal.vue"
import SettingsMerge from "@/views/SettingsMerge.vue"
import SettingsBasic from "@/views/SettingsBasic.vue"
import SettingsAccounting from "@/views/SettingsAccounting.vue"
import SettingsMapping from "@/views/SettingsMapping.vue"
import SettingsStatistic from "@/views/SettingsStatistic.vue"
import ExportStatistics from "@/views/ExportStatistics.vue"
import Debitors from "@/views/Debitors.vue"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/export/transactions',
        name: 'TransactionAccounting',
        component: Export
    },
    {
        path: '/export/statistics',
        name: 'StatisticAccounting',
        component: ExportStatistics
    },
    {
        path: '/settings/merge',
        name: 'SettingsMerge',
        component: SettingsMerge
    },
    {
        path: '/settings/mapping',
        name: 'SettingsMapping',
        component: SettingsMapping
    },
    {
        path: '/settings/basic',
        name: 'SettingsBasic',
        component: SettingsBasic
    },
    {
        path: '/settings/accounting/:account?',
        name: 'SettingsAccounting',
        component: SettingsAccounting
    },
    {
        path: '/settings/statistic',
        name: 'SettingsStatistic',
        component: SettingsStatistic
    },
    {
        path: '/journal/:logtype?/:logyesterday?',
        name: 'Journal',
        component: Logs
    },
    {
        path: '/settings/scopevisio/:directredirect?',
        name: 'SettingsScopevisio',
        component: SettingsScopevisio
    },
    {
        path: '/settings/apaleo/:directredirect?',
        name: 'SettingsApaleo',
        component: SettingsApaleo
    },
    {
        path: '/imprint',
        name: 'Imprint',
        component: Imprint
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth
    },
    {
        path: '/debitors/:account?',
        name: 'Debitors',
        component: Debitors
    },
    {
        path: '/scopevisioauth',
        name: 'ScopevisioAuthDestination',
        component: ScopevisioAuthDestination
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


export default router
