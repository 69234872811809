
import {Component, Vue, Watch} from "vue-property-decorator"
import ButtonBar from '@/components/ButtonBar.vue'
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"
import {LogEntry, Logger, LogSearch} from "@/util/logger"
import Page from '@/components/Page.vue'
import {Apaleoserver} from "@/util/apaleoserver"
import {Scopevisioserver} from "@/util/scopevisioserver"
import moment from "moment"
import { Properties } from '@/util/properties'
import {Util} from '@/util/util'
import {ManagedData} from '@/util/manageddata'
import { showConfirmAsync } from '@/util/eventbus'
import { Organisations } from '@/util/organisations'

@Component({
    components: {Page, ButtonBar, NoMappingMessage, NoConnectionMessage},
})
export default class Journal extends Vue {

    show = false
    loading = false
    modalFrom = false
    modalTo = false
    search = ""
    showAllProperties = true
    
    settingsLog = [] as LogEntry[]
    
    from = moment().clone().subtract(1, 'month').format('YYYY-MM-DD')
    to = moment().clone().format('YYYY-MM-DD')

    settingsHeader = [
        {text: 'Datum', value: 'date', filterable: false},
        {text: 'Aktion', value: "action"},
        {text: 'Aktionstyp', value: 'actionType'},
        {text: 'Hotel', value: 'propertyId', filterable: false},
        {text: 'Benutzer', value: 'user'},
    ]    

    logType = "Stammdaten"
    logActionType = ""
    logUser = ""

    logActionTypes = [] as string[]
    logUsers = [] as string[]


    async mounted() {
        // activate modals
        this.show = true

        if (this.$route.params.logtype && this.logTypes.indexOf(this.$route.params.logtype) >= 0) {
            this.logType = Util.upperCaseFirst(this.$route.params.logtype)
        }

        if (this.$route.params.logyesterday) {
            this.from = moment().clone().subtract(1, 'days').format('YYYY-MM-DD')
            this.to = moment().clone().subtract(1, 'days').format('YYYY-MM-DD')
        }

        this.setSettingsLog(undefined, true)
    }

    get logTypes() {
        const logTypes = [
            "Debitoren",
            "Stammdaten",
            "Transaktionsbuchungen",
        ]     

        if (this.statisticManagement === "Ja") {
            logTypes.push("Statistikbuchungen")
        }

        return logTypes
    }

    @Watch("$store.getters.scopevisioAccount.organisation.id")
    @Watch("$store.getters.selectedProperty")
    async setSettingsLog (propertyId?: string, intervalNotChanged?: boolean){   
        if (intervalNotChanged) {
            const lastLogs = await Logger.getLastEntriesDatesByLogType(this.logType, 50, propertyId)

            if (lastLogs) {
                this.from = moment(lastLogs.from).clone().format('YYYY-MM-DD')
                this.to = moment(lastLogs.to).clone().format('YYYY-MM-DD')               
            }
        }

        const search = {
            logType: this.logType,
            dateFrom: this.from,
            dateTo: this.to,
        } as LogSearch
        
        this.showAllProperties = true

        if (propertyId) {
            search.propertyId = propertyId 
            this.showAllProperties = false      
        }

        this.settingsLog = await Logger.getLogs(search)
    }

    async clearLogs() {        
        const clearAll = await showConfirmAsync({
            message: "Wollen Sie alle Logeinträge unwiderruflich löschen?"
        })
        
        if (clearAll) {
            this.$store.commit("logs", [])
            ManagedData.setValue(this.$store.state)
            this.setSettingsLog(this.$store.getters.selectedProperty)
        }
    }

    getPropertyName(id: string) {
        if (id === "") {
            return "---"
        }
        if (id === "selected") {
            id = this.$store.getters.selectedProperty
        }

        return Properties.getPropertyNameById(id)
    }

    get buttonTextShowOnlySelectedHotel() {
        if (!this.selectedProperty) {
            return false
        }
        
        return `Nur ${this.getPropertyName(this.selectedProperty)} anzeigen`
    }

    get statisticManagement() {
        return this.$store.getters.loadedBasicSettings?.statisticManagement
    }
    get selectedProperty() {
        return this.$store.getters.selectedProperty
    }
    
    formatDateTimeGerman(date: string) {
        return Util.formatDateTimeGerman(date)
    }
    get computedFromDateFormatted() {
        return Util.formatDateGerman(this.from);
    }
    get computedToDateFormatted() {
        return Util.formatDateGerman(this.to);
    }

    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    get hasHotelMappings() {
        return Organisations.changeInProgress || Properties.hasHotelMappings()
    }
    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }

    get title() {
        if (this.showAllProperties || !Properties.isSelected()) {
            return "Journal aller Hotels"
        } else {
            return "Journal " + Properties.getSelectedPropertyName()
        }        
    }
}
