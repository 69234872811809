/*
 * Purpose: providing utilities to handle queries
 *
 * Written by Scopevisio AG 2021
 */
export class QueryParser {
    
    static parse(uri: string) {
        const q: any = {};
        if (uri) {
            const tmp = uri.split("?");
            if (tmp.length > 1) {
                uri = "?" + tmp[1];
            }
        }
        let s = uri ? uri : window.location.search;
        if (s) {
            s = s.substring(1);
            const kvs = s.split("&");
            for (let i = 0, n = kvs.length; i < n; ++i) {
                const kv = kvs[i].split("=");
                q[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]);
            }
        }
        q.get = function (key: string, defaultValue: string) {
            const value = q[key];
            return value == undefined ? defaultValue : value;
        };
        q.toString = function () {
            let buffer = "";
            for (const key in q) {
                if (key === "toString" || key === "get")
                    continue;
                if (q[key] !== undefined && q[key].toString().length > 0) {
                    buffer += buffer.length === 0 ? "?" : "&";
                    buffer += key;
                    buffer += "=" + q[key];
                }
            }
            return window.location.pathname + buffer;
        };
        return q;
    }
}