/*
 * Purpose: the complete reading and writing of the local storage is modelled in this file
 *
 * (c) by Scopevisio AG 2021
 */

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import {Property} from "@/util/properties";
import {Account} from "@/util/accounts";
import {ScopevisioAccountResponse, Organisation} from "@/util/scopevisiotypes";
import {StatisticsAccountMapping} from "@/util/statistics";
import { LogEntry } from '@/util/logger';

Vue.use(Vuex)

export const state = {
    settings: {
        basic: {} as BasicSettings,
        exportFrom: "",
        exportTo: "",
    },
    accounts: {
        apaleoRefreshToken: "",
        scopevisioRefreshToken: "",
        scopevisioAccount: {} as ScopevisioAccountResponse,
        apaleoAccount: {} as ApaleoAccount,
    },
    properties: {} as PropertiesStore,
    mappings: {} as MappingsStore,
    logs: [] as LogEntry[],
    meta: {} as Metadata
}

interface Metadata {
    id: number
    ts: number
    user: string
}


export interface PropertiesStore {
    selected: string
    all: Property[]
}

export interface BasicSettings {
    [key: string]: BasicSettingsProperty
}

export interface BasicSettingsProperty {
    details: "Tagessummen" | "Einzelpositionen",
    debitorMangementScopevisio: "Ja" | "Nein",
    debitorAccount: string,
    debitorSynchronisation: "keine" | "manuell",
    debitorAccountNumberStart: number,
    statisticManagement: "Ja" | "Nein",
    creditCardDetails: "Pro Tag" | "Pro Position/Beleg",
    apaleoCreditCardAccounts: [],
    call: "Pro Hotel" | "Pro Gesellschaft",
}

export interface ApaleoAccount {
    code: string;
    name: string;
    defaultLanguage: string;
    type: string;
}


export interface HotelAccountMapping {
    [key: string]: Account
}
export interface HotelAccountMappings {
    [key: string]: HotelAccountMapping
}


export interface StatisticsAccountMappings {
    [key: string]: StatisticsAccountMapping[]
}

export interface MappingsStore {
    accountMappings: HotelAccountMappings
    statisticsAccountMappings: StatisticsAccountMappings
}

export type StateType = typeof state


const store = new Vuex.Store({
    state,
    mutations: {
        basicSettings(state, basicSettings: BasicSettings) {
            state.settings.basic = basicSettings
        },
        loadedBasicSettings(state, basicSettingsProperty: BasicSettingsProperty) {
            if (store.getters.selectedProperty) {
                if (!state.settings.basic) {
                    state.settings.basic = {}
                }
                state.settings.basic[store.getters.selectedProperty] = basicSettingsProperty
            }           
        },
        accountMappings(state, hotelAccountMappings: HotelAccountMappings) {
            state.mappings.accountMappings = hotelAccountMappings
        },
        loadedAccountMappings(state, mappings: HotelAccountMapping) {
            if (store.getters.selectedProperty) {
                if (!state.mappings.accountMappings) {
                    state.mappings.accountMappings = {}
                }
                if (!state.mappings.accountMappings[store.getters.selectedProperty]) {
                    state.mappings.accountMappings[store.getters.selectedProperty] = {}
                }

                state.mappings.accountMappings[store.getters.selectedProperty] = mappings
            }
        },
        statisticsAccountMappings(state, mappings: StatisticsAccountMappings) {
            state.mappings.statisticsAccountMappings = mappings
        },
        loadedStatisticsAccountMappings(state, statisticsAccountMapping: StatisticsAccountMapping[]) {
            if (store.getters.selectedProperty) {
                if (!state.mappings.statisticsAccountMappings) {
                    state.mappings.statisticsAccountMappings = {}
                }
                if (!state.mappings.statisticsAccountMappings[store.getters.selectedProperty]) {
                    state.mappings.statisticsAccountMappings[store.getters.selectedProperty] = []
                }
                state.mappings.statisticsAccountMappings[store.getters.selectedProperty] = statisticsAccountMapping
            }
        },
        apaleoAccount(state, apaleoAccount: ApaleoAccount) {
            state.accounts.apaleoAccount = apaleoAccount
        },
        scopevisioAccount(state, scopevisioAccount: ScopevisioAccountResponse) {
            state.accounts.scopevisioAccount = scopevisioAccount
        },
        scopevisioOrganisation(state, organisation: Organisation) {
            state.accounts.scopevisioAccount.organisation = organisation    
        },
        apaleoRefreshToken(state, refreshToken: string) {
            state.accounts.apaleoRefreshToken = refreshToken
        },
        scopevisioRefreshToken(state, refreshToken: string) {
            state.accounts.scopevisioRefreshToken = refreshToken
        },
        allProperties(state, properties: Property[]) {
            state.properties.all = properties
        },
        selectProperty(state, propertyId: string) {
            state.properties.selected = propertyId
        },
        log(state, logEntry: LogEntry) {
            state.logs.push(logEntry)
        },
        logs(state, logEntries: LogEntry[]) {
            state.logs = logEntries
        },
        exportFrom(state, from: string) {
            state.settings.exportFrom = from
        },
        exportTo(state, to: string) {
            state.settings.exportTo = to
        },
        metaData(state, data: Metadata) {
            state.meta = data
        }
    },
    getters: {
        selectedProperty(state) {
            return state.properties?.selected
        },
        allProperties(state) {
            return state.properties?.all
        },
        apaleoAccount(state) {
            return state.accounts?.apaleoAccount
        },
        scopevisioAccount(state) {
            return state.accounts?.scopevisioAccount
        },
        scopevisioOrganisation(state) {
            return state.accounts?.scopevisioAccount?.organisation
        },
        scopevisioOrganisations(state) {
            return state.accounts?.scopevisioAccount?.organisations
        },
        loadedBasicSettings(state) {
            if (state.properties.selected && 
                state.settings?.basic && 
                state.settings?.basic[state.properties.selected]
            ) {
                return state.settings.basic[state.properties.selected]
            }
            
            return {} as BasicSettingsProperty
        },
        loadedAccountMappings(state) {
            if (state.properties.selected && 
                state.mappings.accountMappings && 
                state.mappings.accountMappings[state.properties.selected]
            ) {
                return state.mappings.accountMappings[state.properties.selected]
            }
            
            return {} as HotelAccountMappings
        },
        loadedStatisticsAccountMappings(state) {
            if (state.properties.selected && 
                state.mappings.statisticsAccountMappings && 
                state.mappings.statisticsAccountMappings[state.properties.selected]
            ) {
                return state.mappings.statisticsAccountMappings[state.properties.selected]
            }
                
            return [] as StatisticsAccountMapping[]
        },
        logs(state){ 
            return state.logs
        },
        apaleoRefreshToken(state) {
            return state.accounts?.apaleoRefreshToken
        },
        scopevisioRefreshToken(state) {
            return state.accounts?.scopevisioRefreshToken
        },
        metaData(state) {
            return state.meta
        }
    },
    actions: {
        rebuildStore ({ commit }, data: StateType) {
            commit("basicSettings", data.settings.basic)
            commit("exportFrom", data.settings?.exportFrom)
            commit("exportTo", data.settings?.exportTo)
            commit("allProperties", data.properties.all?data.properties.all:[])
            commit("accountMappings", data.mappings.accountMappings)
            commit("statisticsAccountMappings", data.mappings.statisticsAccountMappings)
            commit("logs", data.logs)
        },
        clearStoreScopevisio({ commit }) {
            commit("basicSettings", {})
            commit("accountMappings", {})
            commit("allProperties", [])
            commit("statisticsAccountMappings", {})
            commit("exportFrom", {})
            commit("exportTo", {})
            commit("metaData", {})
            commit("scopevisioRefreshToken", "")
            commit("scopevisioAccount", {})
        },
        clearStoreApaleo({ commit }) {
            commit("selectProperty", "")
            commit("apaleoRefreshToken", "")
            commit("apaleoAccount", {})            
            //commit("basicSettings", {})
            commit("loadedAccountMappings", {})
            //commit("allProperties", [])
            commit("statisticsAccountMappings", {})
        },
        clearLogs({ commit }) {
            commit("logs", {})
        }
    },
    modules: {},
    plugins: [createPersistedState()]
})
export default store

