
import Page from "@/components/Page.vue";
import { Component, Vue } from "vue-property-decorator";
import {Properties} from "@/util/properties";
import { Scopevisioserver } from "@/util/scopevisioserver";
import { Apaleoserver } from "@/util/apaleoserver";
import {ManagedData} from "@/util/manageddata";

@Component({
    components: { Page },
})
export default class Imprint extends Vue {

    mounted() {
        ManagedData.equalManagedDataVersion()
    }


    get propertyName() {
        return Properties.getSelectedPropertyName()
    }
    
    get scopevisioInfo() {
        const myAccount = this.$store.getters.scopevisioAccount

        return myAccount?.customer
            ? `${myAccount.customer.publicId} ${myAccount.customer.name} / ${myAccount?.organisation?.name}`
            : ""
    }

    get development() {
        return (process.env.NODE_ENV === 'development')
    } 
    
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }
}
