/*
 * Purpose: providing utilities to handle the settings
 *
 * Written by Scopevisio AG 2021
 */

import store from "@/store";
import Vue from "vue";
import {ManagedData} from "./manageddata";
import { Properties } from "./properties";
import { Scopevisioserver } from "./scopevisioserver";
import { Organisation } from "./scopevisiotypes";
import {Util} from "./util";

export class Settings extends Vue {

    static default = {
        details: "Tagessummen",
        debitorMangementScopevisio: "Nein",
        debitorAccount: "120000",
        debitorSynchronisation: "keine",
        debitorAccountNumberStart: 2,
        statisticManagement: "Nein",
        creditCardDetails: "Pro Tag",
        call: "Pro Hotel",
        apaleoCreditCardAccounts: []
    }


    static async setStoreForOrganisation(organisation: Organisation) {
        // set organisation
        store.commit("allProperties", [])
        store.commit("scopevisioOrganisation", organisation)

        // reset property selector
        store.commit("selectProperty", "")
        
        Util.cl("setStoreForOrganisation", organisation.name);
        
        const refreshToken = await Scopevisioserver.instance.setOrganisation(organisation)
        store.commit("scopevisioRefreshToken", refreshToken)

        Util.cl(
            "scopevisioRefreshToken", 
            refreshToken,
            store.getters.scopevisioRefreshToken,
            store.getters.accounts?.scopevisioAccount?.name,
            ManagedData.getId(),
            ManagedData.getModifiedTs()
        )

        await ManagedData.setManagedDataToStore() 
        await Properties.refreshPropertiesFromApaleo()               

        const myProperties = Properties.getActiveProperties()
        // set property selector
        if (myProperties?.length > 0) {
            store.commit("selectProperty", myProperties[0].id)
        }
    }
    
    static debitorManagementOnSumAccount() {
        return (store.getters.loadedBasicSettings?.debitorMangementScopevisio === "Ja")
    }

    static manualDebitorSynchronisation() {
        return (
            this.debitorManagementOnSumAccount() &&
            store.getters.loadedBasicSettings?.debitorSynchronisation === 'manuell'
        )
    }

    static aggregateCreditCard() {
        return (store.getters.loadedBasicSettings?.creditCardDetails === 'Pro Tag')
    }

    static isDetailDaily() {
        return (store.getters.loadedBasicSettings?.details === "Tagessummen")
    }

    static async saveToManagedData(data: any) {
        const value = Util.deepCopy(data)
        delete value.accounts.scopevisioAccount
        delete value.properties

        ManagedData.setValue(value)
    }
}