/*
 * Purpose: providing utilities to handle managed data in scopevisio
 *
 * Written by Scopevisio AG 2021
 */

import {Scopevisioserver} from "@/util/scopevisioserver"
import {ManagedData as ManagedDataEntry, NotificationType, Profile} from "@/util/scopevisiotypes"
import store, {state, StateType} from "@/store"
import { showNotification } from "./eventbus"
import moment from "moment"
import { LastChange } from "./merger"
import { Apaleoserver } from "./apaleoserver"
import { Util } from "./util"

export class ManagedData {
    static readonly key = "system.ApaleoConnectorSettings"
    
    
    static getId() {
        return store.getters.metaData.id
    }
    static getModifiedTs() {
        return store.getters.metaData.ts
    }
    
    static async createEntry() {
        const newEntry = {
            validFrom: 0,
            permissionType: 0,
            json: {
                key: this.key,
                value: state
            }
        }
        
        const response = await Scopevisioserver.instance.createManagedDataEntry(
            newEntry
        )
            
        return response?.data
    }

    static async equalManagedDataVersion(lastChange: LastChange = {} as LastChange) {
        const managedDataStatus = await Scopevisioserver.instance.managedDataStatus(
            this.key,
            this.getId()
        );

        if (managedDataStatus.code == 404) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Es fehlt die notwendige Tabelle zur Speicherung von Managed Data. Bitte wenden Sie sich an den Scopevisio Support.",
            })

            return false
        }
        if (managedDataStatus.code == 401) {
            if (Apaleoserver.instance.isConnected()) {
                showNotification({
                    type: NotificationType.ERROR,
                    message: "Loginsession nicht mehr aktuell. Timeout oder erneuter Login mit diesem User.",
                })
            }

            return false
        }
        
        if (managedDataStatus.code == 403) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Es fehlt die notwendige Berechtigung zur Speicherung von Managed Data. Bitte wenden Sie sich an den Scopevisio Support.",
            })

            return false
        }


        
        
        if (managedDataStatus.modifiedTs != this.getModifiedTs()) {
//            Util.cl("Speicherung nicht möglich! (SV != local)", managedDataStatus.modifiedTs, "!=", this.getModifiedTs(), 
//            Util.formatDatetime(managedDataStatus.modifiedTs), Util.formatDatetime(this.getModifiedTs()));
//             // get new data
//             const newData = JSON.parse((await this.readManagedData() as ManagedDataEntry).value)
//             // compute difference
//             const propertiesDifference = Merger.checkPropertyMapping(
//                 newData.properties.all,
//                 lastChange
//             )
                
//             const accountMappingsDifference = Merger.checkAccountMapping(
//                 newData.mappings.accountMappings
//             )

// return false

//             // choose actual data

//             Util.cl("lastChange", lastChange)
//             Util.cl("propertiesDifference", propertiesDifference, Object.keys(propertiesDifference).length)
//             Util.cl("newData", newData, "diffP", propertiesDifference)

//             if (Object.keys(propertiesDifference).length === 0  && Object.keys(accountMappingsDifference).length === 0) {
//                 return true
//             }
//             showConfirm({
//                 message: "Einstellungen wurden in der Gesellschaft verändert und gespeichert. Wollen Sie die gespeicherten Änderungen in ihre Änderungen einfliessen lassen? (Alternativ können sie sich auch erneut an-/abmelden)",
//                 onSuccess: () => {
//                     Util.cl("SUCCESS")
//                     router.push({ name: 'SettingsMerge'})
//                 }
//             })


            showNotification({
                type: NotificationType.ERROR,
                message: "Speicherung nicht möglich! Der Benutzer " + managedDataStatus.modifierName + " hat am " + moment(managedDataStatus.modifiedTs).format('D.M.YYYY - H:mm:ss') + " Uhr Änderungen für diese Gesellschaft gespeichert. "
                      + "Bitte melden Sie sich erneut an, damit Sie diese überschreiben können.",
            })

            return false
        }
        
        return true       
    }

    static async setManagedDataToStore() {
        let parsedManagedData = {} as typeof state

        let managedData = await this.readManagedData() as ManagedDataEntry
        
/*         if (managedData.id == 1041) {
            Util.cl("DELETE", managedData);
            
            await Scopevisioserver.instance.deleteManagedDataEntry(`${managedData.id}`)
        } */

        if (!managedData) {
            const response = await this.createEntry()

            if (response?.status == 201) {
                managedData = await this.readManagedData() as ManagedDataEntry
            }
        }

        if (!managedData.value || managedData.value == "{}") {
            store.commit("metaData", {
                id: 0,
                ts: 0
            })

            parsedManagedData = state
        } else {
            store.commit("metaData", {
                id: managedData.id,
                ts: managedData.modifiedTs
            })
            
            parsedManagedData = await JSON.parse(managedData.value)
        }
        
        store.dispatch("rebuildStore", parsedManagedData) 
    }


    static async updateManagedDataMetaData() {
        const managedData = await this.readManagedData() as ManagedDataEntry

        if (!managedData.value || managedData.value == "{}") {
            store.commit("metaData", {
                id: -1,
                ts: -1,
                user: ""
            })
        } else {
            store.commit("metaData", {
                id: managedData.id,
                ts: managedData.modifiedTs,
                user: managedData.modifierName
            })
        }
    }

    private static async readAllManagedData() {
        return await Scopevisioserver.instance.getAllManagedData(
            this.key
        )
    }

    static async readManagedData() {
        const allManagedData = await ManagedData.readAllManagedData()
        const managedDataEntry = allManagedData.find((managedData: any) => managedData.key === ManagedData.key) as ManagedDataEntry

        if (managedDataEntry !== undefined) {
            return managedDataEntry
        }

        return false
    }

    static async setValue(value: StateType) {
        const saveIsClear = await this.equalManagedDataVersion()
        
        if (!saveIsClear) {
            return false
        }

        // store it
        const storeResponse = await Scopevisioserver.instance.setManagedData(
            this.key,
            {
                permissionType: 0,
                json: {
                    key: ManagedData.key, 
                    value: value
                }
            }
        )

        if (storeResponse?.status == 201) {
            // update modified ts
            await this.updateManagedDataMetaData()
        }

        return storeResponse
    }
}