
import ButtonBar from '@/components/ButtonBar.vue'
import Page from '@/components/Page.vue'
import {Component, Vue} from "vue-property-decorator";
import {showConfirmAsync} from "@/util/eventbus";
import { ScopevisioAuth } from '@/util/scopevisioauth';
import { Scopevisioserver } from '@/util/scopevisioserver';
import {Util} from '@/util/util';
import {ManagedData} from '@/util/manageddata';
import {Logger} from '@/util/logger';
import { Organisations } from '@/util/organisations';




@Component( {
    components: { ButtonBar, Page }
})
export default class SettingsScopevisio extends Vue {

    connected = true

    created() {
        if (this.$route.params.directredirect === "go") {
            ScopevisioAuth.startAuth()
        }
                
        this.$store.commit("selectProperty", "")
    }

    async mounted() {
        await this.setAccount()
        this.$router.push("/") 
    }

    async setAccount() {
        let connected = false
        try {
            if (this.$store.getters.scopevisioRefreshToken) {
                let scopevisioAccount = await Scopevisioserver.instance.getMyAccount()
                
                if (scopevisioAccount) {
                    const organisations = await Scopevisioserver.instance.getOrganisations()

                    if (organisations) {
                        scopevisioAccount.organisations = organisations.records
                    }
                }
                
                connected = !!(scopevisioAccount && scopevisioAccount?.customer.name)
                
                // check rights for usage of managed data
                if (scopevisioAccount && scopevisioAccount.user.profiles) {
                    Scopevisioserver.usageRights(scopevisioAccount.organisation.name, scopevisioAccount.user.profiles)
                }

                await ManagedData.setManagedDataToStore()

                Logger.checkStorage()
            
                this.$store.commit(
                    "scopevisioAccount",
                    scopevisioAccount
                )

                Logger.log(
                    "Stammdaten",
                    "User " + scopevisioAccount?.user?.firstName + " " + scopevisioAccount?.user?.lastName + " bei Scopevisio angemeldet",
                    "status",
                    scopevisioAccount?.user,
                )
            }
        } catch (e) {
            Util.logAxiosError(e)
        } finally {
            this.connected = connected
        }
    }

    get scopevisioInfos() {
        const myAccount = this.$store.getters.scopevisioAccount
        return myAccount?.customer
            ? `${myAccount.customer.publicId} ${myAccount.customer.name} / ${myAccount?.organisation?.name}`
            : ""
    }

    get organisationChangeInProgress() {
        return Organisations.changeInProgress
    }

    async onSignin() {
        ScopevisioAuth.startAuth()
    }

    async onSignout() {
        const r = await showConfirmAsync({
            message: "Wollen Sie die Verbindung mit Scopevisio trennen?"
        })
        if (r) {
            Scopevisioserver.instance.accessToken = ""
            this.$store.commit("scopevisioRefreshToken", "")
            this.$store.commit("scopevisioAccount", null)
            this.$store.commit("selectProperty", "")
            this.connected = false
            this.$router.push("/") 
        }
    }
}
