
import Page from '@/components/Page.vue'
import {Component, Vue, Watch} from "vue-property-decorator"
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"
import ButtonBar from '@/components/ButtonBar.vue'
import {Store} from "vuex"
import {StateType} from "@/store"
import {PostingsRequest, NotificationType} from "@/util/scopevisiotypes"
import {showNotification} from "@/util/eventbus"
import {Folio, Invoice, InvoiceFile, Reports, Transaction, TransactionAccount} from "@/util/reports"
import { DataTableHeader } from 'vuetify'
import { Apaleoserver} from '@/util/apaleoserver'
import {Scopevisioserver} from "@/util/scopevisioserver"
import {Properties} from "@/util/properties"
import { Accounts } from '@/util/accounts'
import { DebitorUtil, ApaleoDebitor } from '@/util/debitorutil'
import moment from "moment"
import {Util} from '@/util/util'
import { LogEntry, Logger } from '@/util/logger'
import {ManagedData} from '@/util/manageddata'
import { Settings } from "@/util/settings"


export interface DatePack {
    text: string
    value: string
}
export interface SortedTransactions {
    [key: string]: {
        [key: string]: {
            [key: string]: Transaction[]
        }
    }
}
export interface SortedCreditCardTransactions {
    [key: string]: {
        [key: string]: Transaction[]
    }
}

interface ReservationInvoices {
    [key: string]: Invoice[]
}
interface ReservationFolios {
    [key: string]: Folio[]
}
interface ReservationIds {
    [key: string]: string
}


@Component({
    components: { Page, NoMappingMessage, NoConnectionMessage, ButtonBar}
})
export default class Export extends Vue {

    importDialog = false
    modalFrom = false
    modalTo = false
    show = false
    loading = false
    showErrors = false
    isDev = false

    lastTransfer = {} as LogEntry

    reservationInvoices = {} as ReservationInvoices
    reservationFolios = {} as ReservationFolios
    reservationIds = {} as ReservationIds
    
    transactions = [] as Transaction[]
    from = moment().clone().format('YYYY-MM-DD')
    to = moment().clone().format('YYYY-MM-DD')
    
    transactionsHeader: DataTableHeader[] = [
        {text: "Datum", value:"date"},
        {text: "Konto", value: "debitedAccount.effectiveNumber"},
        {text: "Konto SV", value: "debitedAccount.mappedDescription"},
        {text: "Gegenkonto", value: "creditedAccount.effectiveNumber"},
        {text: "Gegenkonto SV", value: "creditedAccount.mappedDescription"},
        {text: "Steuersatz", value: "taxRate", align: "center"},
        {text: "Steuerschlüssel", value: "vatmatrix", align: "center"},
        {text: "Brutto", value: "grossAmount", align: "end"},
        {text: "Netto", value: "netAmount", align: "end"},
    ]


    async mounted() {
        this.show = true

        if (this.$store.getters.exportFrom) {
            this.from = this.$store.getters.exportFrom
        }
        if (this.$store.getters.exportTo) {
            this.to = this.$store.getters.exportTo
        }

        await this.setLastTransactionTransferDate()

        this.isDev = Util.isDev()

        if (this.isDev) {
            // KHMM
            // this.from = this.to = moment("2023-08-29").clone().format('YYYY-MM-DD')
            // this.from = this.to = moment("2023-05-25").clone().format('YYYY-MM-DD')
            // this.from = this.to = moment("2023-08-31").clone().format('YYYY-MM-DD')
            // this.from = this.to = moment("2022-11-06").clone().format('YYYY-MM-DD')
            
            // TESTHOTEL
            // this.from = this.to = moment("2024-04-13").clone().format('YYYY-MM-DD')
            this.from = this.to = moment("2023-09-19").clone().format('YYYY-MM-DD')

        } else {
            const lastBookingDay = Logger.getLastBookingDay(this.lastTransfer)

            if (lastBookingDay) {
                this.from = moment(lastBookingDay).clone().add(1, 'days').format('YYYY-MM-DD') 
                this.to = this.from
            }
        }        
    }

    @Watch('from')
    onFromChanged(exportFrom: string) {
        this.$store.commit("exportFrom", exportFrom)
    }
    @Watch('to')
    onToChanged(exportTo: string) {
        this.$store.commit("exportTo", exportTo)
    }

    async getInvoiceFiles(reservationId: string, onlyLast = true) {
        let invoiceFiles = [] as any
        const invoices = await Apaleoserver.instance.getInvoicesByReservationId(reservationId)
        
        Util.cl("--> Invoices", reservationId, invoices)

        if (invoices.count > 0) {
            for (const invoice of invoices.invoices) {
                let invoicePdf = await Apaleoserver.instance.getInvoicePdf(invoice.id)

                const invoiceFile = {
                    id: invoice.id,
                    data: invoicePdf
                } as InvoiceFile 
                invoiceFiles.push(invoiceFile)

                if (onlyLast) {
                    Util.cl("--> Last invoice file", invoiceFiles[0])
                    break
                }
            }
        } 

        return invoiceFiles
    }

    async onFetchDataGrossTransactions() {
        this.startLoading()

        const fromISO = Util.formatDateEnglish(this.from)
        const toISO = Util.formatDateEnglish(this.to)
        const from = `gte_${fromISO}`
        const to = `lte_${toISO}`

        const store = this.$store as Store<StateType>
        const transactions = await Reports.fetchGrossTransactions(
            this.selectedProperty,
            from,
            to
        )
        let filteredTransactions = [] as Transaction[]
        const creditCardTransactions = [] as Transaction[]

        if (transactions) {
            /* augment debitor info */
            const apaleoDebitors = await DebitorUtil.loadApaleoDebitors(this.selectedProperty)
            const code2ApaleoDebitor = new Map<string, ApaleoDebitor>()
            
            apaleoDebitors.forEach(d => code2ApaleoDebitor.set(d.apaleocode, d))

            // check for new in scopevisio created debitors 
            const scopevisioDebitors = await DebitorUtil.loadMappedScopevisioDebitors()
            DebitorUtil.checkForNewMappedScopevisioDebitors(scopevisioDebitors)
            
            for (const transaction of transactions) {
                for (const account of [ transaction.debitedAccount, transaction.creditedAccount]) {
                    // if debitor account
                    if (DebitorUtil.isDebitorAccount(account)) {
                        const arCode = account.number.replace(/_.*$/, "")
                        const apaleoDebitor = code2ApaleoDebitor.get(arCode)
                        
                        if (apaleoDebitor) {
                            account.debitor = apaleoDebitor
                        }
                    }
                }
            }
            /* compute effective account number, its either number or parentNumber */
            for (const transaction of transactions) {
                for (const account of [ transaction.debitedAccount, transaction.creditedAccount]) {
                    if (["Receivables", "Liabilities"].indexOf(account.type) != -1) {
                        account.effectiveNumber = account.parentNumber
                    } else {
                        account.effectiveNumber = account.number
                        // debitor
                        if (DebitorUtil.isDebitorAccount(account)) {
                            const regexp = new RegExp('^(.*)_.*_(.*)/Offset$')
                            const parts = regexp.exec(account.number)
                            
                            if (parts && parts[1] && parts[2]) {
                                account.effectiveNumber = parts[1] + "_" + parts[2]
                            } else {
                                account.effectiveNumber = account.parentNumber                
                            }
                        }
                    }

                }
                
                if (transaction.taxes && transaction.taxes.length > 0) {
                    transaction.taxRate = transaction.taxes[0].percent + ""
                }

                // if taxrate is neither defined nor known, set it to 0
                if (!transaction.taxRate || Accounts.depositAccountTaxRates.indexOf(transaction.taxRate) === -1 ) {
                    transaction.taxRate = "0"
                }
                                
                // handle deposits with account 3000
                if (transaction.debitedAccount.effectiveNumber === Accounts.apaleoDepositAccount) {
                        transaction.debitedAccount.effectiveNumber = transaction.debitedAccount.effectiveNumber + 
                        `:${transaction.taxRate}.00`

                }if (transaction.creditedAccount.effectiveNumber === Accounts.apaleoDepositAccount) {
                        transaction.creditedAccount.effectiveNumber = transaction.creditedAccount.effectiveNumber + 
                        `:${transaction.taxRate}.00`
                }
            }
            
            if (store.getters.loadedAccountMappings && transactions?.length > 0) {
                const accounts = Accounts.getAllAccountsForProperty(store.getters.loadedAccountMappings)
                const accountMap = new Map(accounts.map(a => [a.apaleoAccountNumber, a]))
                
                /* fix scopevisioAccountNumber inside the loadedAccountMappings 
                 * using the latest live data from the scopevisio side
                 */
                scopevisioDebitors.forEach(d => {
                    if (d.externalNumber) {
                        const account = accountMap.get(d.externalNumber)
                        if (account) {
                            if (account.scopevisioAccountNumber != d.externalNumber) {
                                Util.cl("repaired apaleo account=" + d.externalNumber + " to " + d.number + " previous oldvalue=" + account.apaleoAccountNumber)
                                account.scopevisioAccountNumber = d.number
                            }
                        }
                    }
                })
                /* end of scopevisioAccountNumber fixing */
                
                transactions.forEach( (transaction, index) => {
                    // ignore transactions with amount 0€
                    if (transaction.grossAmount === 0) {
                        Util.cl("Ignored transaction has amount 0€", transaction)
                        
                        return 
                    }

                    const targetdebit  = accountMap.get(transaction?.debitedAccount?.effectiveNumber||"")?.scopevisioAccountNumber
                    const targetcredit = accountMap.get(transaction?.creditedAccount?.effectiveNumber||"")?.scopevisioAccountNumber

                    if (targetdebit) {
                        transaction.debitedAccount.scopevisioDebitor = DebitorUtil.isScopevisioDebitor(scopevisioDebitors, targetdebit.toString())
                        transaction.debitedAccount.mappedDescription = targetdebit.toString()
                        transaction.vatmatrix = accountMap.get(transaction?.debitedAccount?.effectiveNumber||"")?.vatmatrix
                    }

                    if (targetcredit) {
                        transaction.creditedAccount.scopevisioDebitor = DebitorUtil.isScopevisioDebitor(scopevisioDebitors, targetcredit.toString())
                        transaction.creditedAccount.mappedDescription = targetcredit.toString()
                        transaction.vatmatrix = accountMap.get(transaction?.creditedAccount?.effectiveNumber||"")?.vatmatrix
                    }

                    // check if set sum account has to be set
                    if (Settings.debitorManagementOnSumAccount()) {
                        if (DebitorUtil.isDebitorAccount(transaction?.debitedAccount)) {
                            transaction.debitedAccount.mappedDescription = store.getters.loadedBasicSettings.debitorAccount
                        }
                        if (DebitorUtil.isDebitorAccount(transaction?.creditedAccount)) {
                            transaction.creditedAccount.mappedDescription = store.getters.loadedBasicSettings.debitorAccount
                        }
                    }

                    // remove transactions with identical acounts
                    if ( transaction.debitedAccount.effectiveNumber != transaction.creditedAccount.effectiveNumber){
                        // remove transactions with 9999 -> {DEBITOR}
                        if ( transaction.debitedAccount.effectiveNumber == "9999" && transaction.creditedAccount.type == Accounts.debitorType) {
                            Util.cl(
                                "ignored 9999 -> {DEBITOR}", 
                                index,
                                transaction.debitedAccount.effectiveNumber,
                                transaction.creditedAccount.effectiveNumber,
                                transaction.creditedAccount.type 
                            )
                        } else {
                            filteredTransactions.push(transaction)
                            Util.cl(
                                "added 9999 -> {DEBITOR}", 
                                index,
                                transaction.debitedAccount.effectiveNumber,
                                transaction.creditedAccount.effectiveNumber,
                                transaction.creditedAccount.type 
                            )
                        }
                    } else {
                        Util.cl(
                            "ignored deb=cre", 
                            index,
                            transaction.debitedAccount.effectiveNumber,
                            transaction.creditedAccount.effectiveNumber
                        )
                    }

                    if (Settings.aggregateCreditCard() &&
                        (
                            transaction.debitedAccount.effectiveNumber && Accounts.isCreditCardAccount(transaction.debitedAccount.effectiveNumber) ||
                            transaction.debitedAccount.effectiveNumber && Accounts.isCreditCardAccount(transaction.debitedAccount.effectiveNumber)
                        )
                    ) {
                        creditCardTransactions.push(transaction)
                    }
                })
            } else {
                filteredTransactions = transactions
            }
        }
        
        if (Settings.isDetailDaily()) {
            this.transactions = await this.aggregateTransactions(filteredTransactions)
        } else {
            if (filteredTransactions) {
                if (Settings.aggregateCreditCard()) {
                    this.transactions = await this.aggregateOnlyCreditCardTransactions(filteredTransactions)
                } else {
                    this.transactions = filteredTransactions
                }
            }            
        }
        
        this.loading = false

        showNotification({
            type: NotificationType.INFO,
            message: "Transaktionen des " +
                Properties.getPropertyById(this.selectedProperty, store.getters.allProperties).name + " vom " +
                Util.formatDateGerman(this.from) + "-" +
                Util.formatDateGerman(this.to),
            timeoutMs: 5000
        })
    }

    changeTransactionDetail() {
        if (!this.isDev) {
            return false
        }

        let settings = this.$store.getters.loadedBasicSettings
        
        if (!settings) {
            settings = Settings.default
        }

        if (Settings.isDetailDaily()) {
            settings.details = "Einzelpositionen"
        } else {
            settings.details = "Tagessummen"
        }
        
        this.$store.commit("loadedBasicSettings", settings)
        this.onFetchDataGrossTransactions()
    }

    startLoading() {
        this.loading = true
        this.transactions = []
        this.showErrors = false
    }


    async onAgree() {
        this.importDialog = false
        let bookingInterval = Util.formatDateGerman(this.from)

        if (this.from !== this.to) {
            bookingInterval += "-" + Util.formatDateGerman(this.to)
        }

        let corexml = ``
        for (const t of this.transactions) {

            const amount = t.grossAmount.toFixed(2).toString()
            const dateComps = t.date.split("-")
            const date = `${dateComps[2]}.${dateComps[1]}.${dateComps[0]}`
            const documentNumber = t.receipt?`${t.receipt.type||""}-${t.receipt.number||""}`:
                (this.from != this.to)?"PostCharge: " + Util.formatDateGerman(this.from) + "-" + Util.formatDateGerman(this.to):
                    "PostCharge: " + Util.formatDateGerman(this.from)
            const debitAccount = t.debitedAccount.mappedDescription || ""
            const creditAccount = t.creditedAccount.mappedDescription || ""
            let debittaxkey = ""
            let credittaxkey = ""

            if (t.vatmatrix) {
                if (debitAccount && Number.parseInt(debitAccount) >= 4000) {
                    t.debittaxkey =  t.vatmatrix
                    debittaxkey = t.vatmatrix
                }
                if (creditAccount && Number.parseInt(creditAccount) >= 4000) {
                    t.credittaxkey = t.vatmatrix
                    credittaxkey = t.vatmatrix
                }
            }

            const debitAccountData = Accounts.getAccountById(t.debitedAccount.number)
            const creditAccountData = Accounts.getAccountById(t.creditedAccount.number)

            // ==>  if debitedAccount.type = revenue dann creditedAccount.name
            let postingText = t.debitedAccount.name
            let documentText = `${t.command||""} ${t.referenceType||""} ${t.reference||""}`
            
            if (t.creditedAccount.type == DebitorUtil.revenuesAccountType) {
                postingText = t.creditedAccount.name
            }  else {
                documentText = t.debitedAccount.name
            }

            let creditAccountPropertyName = "account"
            let debitAccountPropertyName = "account"

            // set if account is debitor and debitor is no sum account
            if (((DebitorUtil.isDebitorAccount(t.creditedAccount) && t.creditedAccount.scopevisioDebitor) || t.creditedAccount.debitor) && !Settings.debitorManagementOnSumAccount()) {
                creditAccountPropertyName = "personalAccount"
            }

            if (t.debitedAccount.scopevisioDebitor) {
                debitAccountPropertyName = "personalAccount"
            }

            if (!t.creditedAccount.debitor && t.creditedAccount.number !== "9999" && !Accounts.isCreditCardAccount(t.creditedAccount.number)) {
                postingText = t.creditedAccount.name
                documentText = postingText
            }

            var documentFile = ""

            // add a base64 encoded pdf from invoice file to posting
            if (t.reservation?.id && (creditAccountPropertyName === "personalAccount" || debitAccountPropertyName === "personalAccount")) {
                var invoiceFiles = await this.getInvoiceFiles(t.reservation.id) as InvoiceFile[]
                const invoiceFile = invoiceFiles[0]

                if (invoiceFile) {
                    Util.cl("--> InvoiceFile", invoiceFile, t.reservation?.id)
                    const base64Pdf = Util.convertBase64(invoiceFile.data)                
                    documentFile = `<documentFile filename='${invoiceFile.id}.pdf'>${base64Pdf}</documentFile>`
                    Util.cl("added documentFile", documentFile, "invoiceFile", invoiceFile)
                }
            }

            const postingXml = 
                `<posting dateFormat="dd.MM.yyyy" postingDate="${date}" documentDate="${date}" externalDocumentNumber="${Util.htmlEntities(documentNumber)}" legacyDocumentNumber="${Util.htmlEntities(documentNumber)}"><documentText>${Util.htmlEntities(documentText)}</documentText><row debit="${amount}" postingText="${Util.htmlEntities(postingText)}" ${debitAccountPropertyName}="${Util.htmlEntities(debitAccount)}" dimension1="${debitAccountData?.dimension1||''}" dimension2="${debitAccountData?.dimension2||''}" dimension3=""></row><row credit="${amount}" postingText="${Util.htmlEntities(postingText)}" ${creditAccountPropertyName}="${Util.htmlEntities(creditAccount)}" vatKey="${credittaxkey}" dimension1="${creditAccountData?.dimension1||''}" dimension2="${creditAccountData?.dimension2||''}" dimension3=""></row>${documentFile}</posting>`
                
            corexml += postingXml
        }

        const request: PostingsRequest = {
            generateVat: true,
            postingBatch: true,
            importMasterDataEnabled: false,
            importMasterDataMode: "CREATEORUPDATE",
            importFilesEnabled: false,
            generateVatCreatorInfos: false,
            refreshPaymentTerm: false,
            postingXml: `<?xml version="1.0" encoding="utf-8"?><postings xmlns="scopevisio.com/accounting/posting">${corexml}</postings>`,
        }

        Util.cl("PostingsRequest raw", request, corexml)

        try {
            const response = await Scopevisioserver.instance.postings(request) as any
                        
            if (response?.r?.isAxiosError) {

                showNotification({
                    type: NotificationType.ERROR,
                    message: "Transfer von Transaktionen gescheitert. Fehler: " + response.r.response.data.backendResponse
                })
                Logger.log(
                    "Transaktionsbuchungen",
                    "Transfer fehlerhaft. Fehler: " + response.r.response.data.backendResponse,
                    Logger.actionTypeTransactionTransferError,
                    this.transactions,
                    this.$store.getters.selectedProperty
                )
            } else {                
                Logger.logTransactions(this.transactions, bookingInterval)
                showNotification({
                    type: NotificationType.INFO,
                    message: "Transfer von " + this.transactions.length + " Transaktionen durchgeführt",
                    timeoutMs: 5000
                })

                this.setLastTransactionTransferDate()
            }

            ManagedData.setValue(this.$store.state)
        } catch (err) {
            
            showNotification({
                type: NotificationType.ERROR,
                message: "Transfer von Transaktionen gescheitert"
            })
            Logger.log(
                "Transaktionsbuchungen",
                "Transfer fehlerhaft",
                Logger.actionTypeTransactionTransferError,
                this.transactions
            )
        }
     }

    onCancel() {
        this.importDialog = false
    }

    get hasTransactions() {
        return (this.transactions?.length > 0)
    }
    get selectedProperty() {
        return this.$store.getters.selectedProperty
    }
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }
    get computedFromDateFormatted() {
        return Util.formatDateGerman(this.from);
    }
    get computedToDateFormatted() {
        return Util.formatDateGerman(this.to);
    }

    formatDateGerman(date: string) {
        return Util.formatDateGerman(date)
    }

    getLastMonths(amount: number){
        let lastSixMonths = []
        moment.locale('de');

        for (var i = amount - 1; i >= 0; i -= 1) {
            lastSixMonths.push({
                text: moment().subtract(i, 'months').format('MMM \'YY'),
                value: moment().subtract(i, 'months').format('YYYY-MM-DD'),
            })
        }

        return lastSixMonths.reverse() // Result
    }

    setMonthToDatePicker(month: DatePack) {
        this.from = moment(month.value).clone().startOf('month').format('YYYY-MM-DD')
        this.to = moment(month.value).clone().endOf('month').format('YYYY-MM-DD')
        this.onFetchDataGrossTransactions()
    }

    checkMissingAccountMappings() {
        let missingAccountsCount = 0

        for (const transaction of this.transactions) {
            if (!transaction.debitedAccount.mappedDescription) {
                missingAccountsCount++
            }
            if (!transaction.creditedAccount.mappedDescription) {
                missingAccountsCount++
            }
        }

        return missingAccountsCount
    }

    @Watch("$store.getters.selectedProperty")
    onPropertyChange() {
        this.transactions = []

        if (this.$store.getters.selectedProperty) {
            this.onFetchDataGrossTransactions()
        }        
    }


    async sumTransactions(transactions: Transaction[]) {
        let sumTransaction = {} as Transaction

        if (transactions?.length == 0) {
            return sumTransaction
        }

        if (transactions?.length == 1) {
            return transactions[0]
        }
        let first = transactions.shift()

        if (first) {
            sumTransaction = first

            for (const transaction of transactions) {
                sumTransaction.grossAmount += transaction.grossAmount
                sumTransaction.netAmount += transaction.netAmount
                sumTransaction.reference = transaction.debitedAccount.name
                // sumTransaction.creditedAccount.name = transaction.debitedAccount.name    => changed after mail Silvan 4.7.22
                sumTransaction.creditedAccount.name = transaction.creditedAccount.name
                // sumTransaction.debitedAccount.name = "Forderungen Tag"  => changed after call Silvan 12.09.23
                sumTransaction.debitedAccount.name = transaction.debitedAccount.name
            }
            sumTransaction.referenceType = ""
            sumTransaction.command = ""
        }

        return sumTransaction
    }
    async aggregateOnlyCreditCardTransactions(transactions: Transaction[]) {
        const sortedCreditCardTransactions = {} as SortedCreditCardTransactions
        let aggregatedTransactions = [] as Transaction[]

        if (transactions) {
            for (const transaction of transactions) {
                if (transaction.debitedAccount.effectiveNumber && Accounts.isCreditCardAccount(transaction.debitedAccount.effectiveNumber)) {
                    if (!sortedCreditCardTransactions[transaction.date]) {
                        sortedCreditCardTransactions[transaction.date] = {}
                    }
                    if (!sortedCreditCardTransactions[transaction.date][transaction.debitedAccount.effectiveNumber]) {
                        sortedCreditCardTransactions[transaction.date][transaction.debitedAccount.effectiveNumber] = []
                    }
                    sortedCreditCardTransactions[transaction.date][transaction.debitedAccount.effectiveNumber].push(transaction)
                } else {
                    aggregatedTransactions.push(transaction)
                }
            }            
        }
        for (const date of Object.keys(sortedCreditCardTransactions)) {
            for (const debitor of Object.keys(sortedCreditCardTransactions[date])) {
                const aggregatedTransaction = await this.sumTransactions(sortedCreditCardTransactions[date][debitor])

                if (aggregatedTransaction.netAmount != 0) {
                    aggregatedTransactions.push(aggregatedTransaction)
                }
            }
        }

        return aggregatedTransactions
    }

    async aggregateTransactions(transactions: Transaction[]) {
        const sortedTransactions = {} as SortedTransactions
        let aggregatedTransactions = [] as Transaction[]

        // sort transactions by date and credited and debited account numbers into hierarchical multidimensional array
        if (transactions) {
            for (const transaction of transactions) {

                if (transaction.debitedAccount.effectiveNumber && 
                    transaction.creditedAccount.effectiveNumber
                ) {
                    if (!sortedTransactions[transaction.date]) {
                        sortedTransactions[transaction.date] = {}
                    }
                    if (!sortedTransactions[transaction.date][transaction.creditedAccount.effectiveNumber]) {
                        sortedTransactions[transaction.date][transaction.creditedAccount.effectiveNumber] = {}
                    }
                    if (!sortedTransactions[transaction.date][transaction.creditedAccount.effectiveNumber][transaction.debitedAccount.effectiveNumber]) {
                        sortedTransactions[transaction.date][transaction.creditedAccount.effectiveNumber][transaction.debitedAccount.effectiveNumber] = []
                    }                    

                    sortedTransactions[transaction.date][transaction.creditedAccount.effectiveNumber][transaction.debitedAccount.effectiveNumber]
                        .push(transaction)
                }
            }
        }
        
        for (const date of Object.keys(sortedTransactions)) {
            for (const debitor of Object.keys(sortedTransactions[date])) {
                for (const creditor of Object.keys(sortedTransactions[date][debitor])) {
                    // check if account is creditcard or debitor [AC-4]
                    if ((!Settings.aggregateCreditCard() && Accounts.isCreditCardAccount(creditor)) || 
                        DebitorUtil.isDebitorAccount(sortedTransactions[date][debitor][creditor][0].creditedAccount)
                    ){
                        aggregatedTransactions = aggregatedTransactions.concat(sortedTransactions[date][debitor][creditor])
                    } else {
                        const summedTransactions = await this.sumTransactions(sortedTransactions[date][debitor][creditor])

                        if (summedTransactions.netAmount != 0) {
                            aggregatedTransactions.push(summedTransactions)
                        }
                    }
                }
            }
        }
            
        return aggregatedTransactions                
    }


    async onImport() {
        this.importDialog = true
        this.showErrors = true
    }

    get lastTransferData() {
        let lastTransferData = Util.formatDateTimeGermanLong(this.lastTransfer.date) + ` Uhr`
        
        if (this.lastTransfer.user) {
            const lastTransferInterval = Logger.getLastTransferInterval(this.lastTransfer)

            if (lastTransferInterval) {
                lastTransferData += ` für Buchungen vom ${lastTransferInterval}` 
            }
            if (this.lastTransfer.user) {
                lastTransferData += `, durchgeführt von ${this.lastTransfer.user}`
            }            
        } 
        
        return lastTransferData
    }

    getReservationNumber(accountName: string) {

        if (typeof(this.reservationIds[accountName]) === "undefined" ) {
            const reservationNumber = accountName.match(/([A-Z-]{8}-\d+)/)

            if (!reservationNumber) {
                return false
            }
            this.reservationIds[accountName] = reservationNumber[0]
        }

        return this.reservationIds[accountName]
    }

    async getReservationFolios(reservationId: string) {
        if (typeof(this.reservationFolios[reservationId]) === "undefined" ) {
            this.reservationFolios[reservationId] = (await Apaleoserver.instance.getFolioIdsByReservationId(reservationId)).folios
        }

        return this.reservationFolios[reservationId]
    }

    getReservationUrl(name: string) {
        
        const reservationNumber = this.getReservationNumber(name)
        
        if (!reservationNumber) {
            return name
        }

        if  (reservationNumber.match(new RegExp("^" + this.$store.getters.selectedProperty, "g"))) {
            return "https://app.apaleo.com/" + this.selectedProperty + "/finance/folios/" + reservationNumber + "/general"
        } else {
            return "https://app.apaleo.com/" + this.selectedProperty + "/reservations/" + reservationNumber + "/actions"
        }
    }

    @Watch("$store.getters.selectedProperty")
    async setLastTransactionTransferDate() {
        const lastTransactionTransfer = await Logger.getLastTransfer(Logger.actionTypeTransactionTransfer)
        
        if (lastTransactionTransfer) {
            this.lastTransfer = lastTransactionTransfer
        }
    }

    typeInfos(transaction: Transaction) {
        if (!this.isDev) {
            return false
        }

        return transaction.debitedAccount.type + "=D   C=" + transaction.creditedAccount.type
    }

    onDirectMapping(account: TransactionAccount) {
        if (DebitorUtil.isDebitorAccount(account)) {
            this.$router.push(`/debitors/${account.effectiveNumber}`)

            return 
        }
        this.$router.push(`/settings/accounting/${account.effectiveNumber}`)
    }

    onTransactionDetails() {
        this.$router.push("/journal/transaktionsbuchungen")
    }

    get transactionDetail() {
        if (Settings.isDetailDaily()) {
            return "Tagessummen"
        } else {
            return "Einzelpositionen"
        }
    }

    isShowMonthButtons() {
        return false
    }

    onShowDebitors()  {
        this.$router.push("/debitors")
    }
    get hasHotelMappings() {
        return Properties.hasHotelMappings()
    }
    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }
    get debitorManagement() {
        return !Settings.debitorManagementOnSumAccount()
    }
    get title() {
        if (Properties.getSelectedPropertyName() === Properties.noHotelText) {
            return "Transaktionsbuchungen"
        } else {
            return "Transaktionsbuchungen für " + Properties.getSelectedPropertyName()
        }        
    }
}
