
import {Component, Vue, Watch} from "vue-property-decorator"
import Page from '@/components/Page.vue'
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"

import {NotificationType} from "@/util/scopevisiotypes"
import {Apaleoserver} from "@/util/apaleoserver"
import {Scopevisioserver} from "@/util/scopevisioserver"
import {BasicSettingsProperty} from "@/store"
import {showConfirmAsync, showNotification} from "@/util/eventbus"
import {ManagedData} from "@/util/manageddata"
import {Properties} from "@/util/properties"
import {Util} from "@/util/util"
import {Logger} from "@/util/logger"

import { Settings } from "@/util/settings"
import { Organisations } from "@/util/organisations"


export interface selectItem {
    name: string
    number: string
}

@Component( {
    components: { Page, NoMappingMessage, NoConnectionMessage}
})
export default class SettingsBasic extends Vue {
    basicSettings = {} as BasicSettingsProperty
    apaleoPaymentAccounts = [] as selectItem[]

    async created() {
        await this.setBasicSettings()
    }
    async mounted() {
        const apaleoPaymentAccounts = (await Apaleoserver.instance.getPropertyChildAccounts({
            propertyId: this.$store.getters.selectedProperty,
            parent: "1000"
        }))?.accounts

        if (apaleoPaymentAccounts) {
            apaleoPaymentAccounts.forEach(account => {
                this.apaleoPaymentAccounts.push({
                    name: account.name,
                    number: account.accountNumber
                })
            })
        }
    }

    paymentAccountName(account: selectItem) {
        return account.number + " " + account.name
    }

    get loadedBasicSettings() {
        return this.basicSettings
        //return this.$store.getters.loadedBasicSettings
    }
    
    get paymentAccounts() {
        return this.apaleoPaymentAccounts
    }

    @Watch("$store.getters.selectedProperty")
    async setBasicSettings() {
        if (Object.keys(this.$store.getters.loadedBasicSettings).length > 0) {
            this.basicSettings = await Util.deepCopy(this.$store.getters.loadedBasicSettings)
        } else {
            this.basicSettings = Util.deepCopy(Settings.default) as BasicSettingsProperty
        }
    }

    getTitle() {
        if (this.selectedProperty) {
            return "Basiseinstellungen für " + Properties.getSelectedPropertyName()
        } else {
            return "Basiseinstellungen pro Hotel"
        }
    }
    

    get organisationChangeInProgress() {
        return Organisations.changeInProgress
    }
    get selectedProperty() {
        return this.$store.getters.selectedProperty
    }
    get debitorManagementInScopevisio() {
        return Settings.debitorManagementOnSumAccount()
    }
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    async onDebitorManagementApaleoChange(value: string) {
        if (value == "Nein") {
            const debitorAutomatic = await showConfirmAsync({
                message: "Sollen Debitoren automatisch angelegt werden?"
            })
            if (debitorAutomatic) {
                const changesToScopevisio = await showConfirmAsync({
                    message: "Sollen Änderungen des Debitors in Scopevisio übernommen werden?"
                })
                if (changesToScopevisio) {
                    showNotification({
                        type: NotificationType.INFO,
                        message: "Die Änderungen des Debitors wurden in Scopevisio übernommen.",
                        timeoutMs: 5000
                    })
                    this.basicSettings.debitorMangementScopevisio = "Nein"
                }
            } else {
                this.basicSettings.debitorMangementScopevisio = "Ja"
            }
        }
    }

    async onSave() {
        const r = await showConfirmAsync({
            message: "Wollen Sie diese Basiseinstellungen speichern?"
        })
        
        if (r) {
            try {
                Logger.logBasicSettings(
                    this.$store.getters.loadedBasicSettings, 
                    Util.deepCopy(this.basicSettings),
                )  
                this.$store.commit("loadedBasicSettings", Util.deepCopy(this.basicSettings))

                if (await ManagedData.setValue(this.$store.state)) {
                    showNotification({
                        type: NotificationType.INFO,
                        message: "Die Basiseinstellungen wurden gespeichert",
                        timeoutMs: 5000
                    })
                }                
            } catch (e) {
                console.error("FEHLER: ", e)
                showNotification({
                    type: NotificationType.ERROR,
                    message: "FEHLER: Die Basiseinstellungen wurden nicht gespeichert",
                    timeoutMs: 5000
                })
            }
        }
    }
    get hasHotelMappings() {
        return Properties.hasHotelMappings()
    }
    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }
}
