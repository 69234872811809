
import Page from '@/components/Page.vue'
import {Component, Vue, Watch} from "vue-property-decorator"
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoStatisticMappingMessage from "@/components/NoStatisticMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"
import ButtonBar from '@/components/ButtonBar.vue'
import {NotificationType} from "@/util/scopevisiotypes"
import {Apaleoserver} from "@/util/apaleoserver"
import {Scopevisioserver} from "@/util/scopevisioserver"
import moment from "moment"
import PropertySelector from "@/components/PropertySelector.vue"
import {Statistics, StatisticsAccountMapping, StatisticsPosting} from "@/util/statistics"
import {showNotification} from "@/util/eventbus"
import { Properties } from "@/util/properties"
import { LogEntry, Logger } from "@/util/logger"
import {Util} from "@/util/util"


@Component( {
    components: { Page, PropertySelector, NoMappingMessage, NoStatisticMappingMessage, NoConnectionMessage, ButtonBar}
})
export default class ExportStatistics extends Vue {
    modalFrom = false
    modalTo = false
    show = false
    showErrors = true
    loading = false

    from = moment().clone().startOf('isoWeek').format('YYYY-MM-DD')
    to = moment().clone().endOf('isoWeek').format('YYYY-MM-DD')
    
    lastTransfer = {} as LogEntry

    statisticsBookings = [] as StatisticsPosting[]

    headers = [
        {text: 'Buchungsdatum', value: 'date'},
        {text: 'Scopevisio Statistikkontonummer', value: "accountNumber"},
        {text: 'Scopevisio Statistikkontoname', value: 'text'},
        {text: 'Kostenstelle', value: 'dimension_1'},
        {text: 'Menge', value: 'amount'},
    ]

    bookings = []


    async mounted() {
        // activate modals
        this.show = true  

        await this.setLastTransfer()          
        
        if (process.env.NODE_ENV === 'development') {
            this.from = moment("2022-06-01").clone().format('YYYY-MM-DD')
            this.to = moment("2022-06-01").clone().format('YYYY-MM-DD')
        } else {
            const lastTransferredStatisticDay = Logger.getLastTransferredStatisticDay(this.lastTransfer)
            
            if (lastTransferredStatisticDay) {
                this.from = moment(lastTransferredStatisticDay).clone().add(1, 'days').format('YYYY-MM-DD') 
                this.to = this.from
            }
        }
    }


    get selectedProperty() {
        return this.$store.getters.selectedProperty
    }

    @Watch("$store.getters.selectedProperty")
    onPropertyChange() {
        this.statisticsBookings = []
        this.onFetchData()
    }

    async onFetchData() { 
        // check date intervall <= 14 days
        if (moment(this.to).diff(moment(this.from), 'days') > 14) {
            showNotification({
                type: NotificationType.ERROR,
                message: "Das Zeitintervall darf maximal 14 Tage groß sein.",
                timeoutMs: 5000
            })

            return
        }

        // get performance
        if (this.$store.getters.selectedProperty !== "") {
            this.loading = true
            
            const propertyPerformance = await Statistics.getPropertyPerformance(
                this.selectedProperty,
                this.from,
                this.to,
            )
            
            this.statisticsBookings = propertyPerformance.filter((mapping: any) => mapping.accountNumber != Statistics.unassigned)
            this.loading = false
        }
    }
        
    get lastTransferData() {
        let lastTransferData = Util.formatDateTimeGermanLong(this.lastTransfer.date) + ` Uhr`
        
        if (this.lastTransfer.user) {
            lastTransferData += ` von ${this.lastTransfer.user}`
        } 
        
        return lastTransferData
    }

    @Watch("$store.getters.selectedProperty")
    async setLastTransfer() {
        const lastTransfer = await Logger.getLastTransfer(Logger.actionTypeStatisticTransactionTransfer)
        if (lastTransfer) {
            this.lastTransfer = lastTransfer
        }
    }

    async onImport() {
        // clear dimension_1
        this.statisticsBookings.map(function(posting: StatisticsPosting){
            if (posting.dimension_1 == 0) {
                delete posting.dimension_1
            }
        })

        const response = await Scopevisioserver.instance.createStatisticPostings(this.statisticsBookings)
        
        if (response.status < 200 || response.status > 299) {
            let reason = ""

            if (response.status === 403) {
                reason = " Es fehlen Rechte in Scopevisio zur Verwendung der Statistikkonten."
            }

            if (response.status === 500) {
                reason = ` Fehler: ${response.message}`
            }

            showNotification({
                type: NotificationType.ERROR,
                message: "Die Statistikkonten konnten nicht übertragen werden." + reason,
                timeoutMs: 10000
            })

            return false
        }
        
        Logger.logStatisticPostings(this.statisticsBookings)
            
        showNotification({
            type: NotificationType.INFO,
            message: "Die Statistikkonten wurden erfolgreich übertragen.",
            timeoutMs: 5000
        })
    }

    formatDateGerman(date: string) {
        return Util.formatDateGerman(date)
    }
    get computedFromDateFormatted() {
        return Util.formatDateGerman(this.from)
    }
    get computedToDateFormatted() {
        return Util.formatDateGerman(this.to)
    }

    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    get hasHotelMappings() {
        return Properties.hasHotelMappings()
    }
    
    get hasStatisticMappings() {
        return Statistics.hasStatisticMappings()
    }

    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }

    get title() {
        if (this.selectedProperty === "") {
            return "Statistikbuchungen"
        } else {
            return "Statistikbuchungen für " + Properties.getSelectedPropertyName()
        }        
    }
}
