
import Page from "@/components/Page.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { Apaleoserver } from "@/util/apaleoserver"
//import {ManagedData} from "@/util/manageddata"
import { Scopevisioserver } from "@/util/scopevisioserver"
import { Util } from "@/util/util"


@Component({
    components: { Page }
})
export default class SettingsMerge extends Vue {
    @Prop()
    differences: any

    mounted() {
        Util.cl("SettingsMerge mount", this.differences)
    }

    get development() {
        return (process.env.NODE_ENV === 'development')
    } 
    
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }
}
