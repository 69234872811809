

import {Component, Vue, Watch} from "vue-property-decorator"
import {Dimension, NotificationType} from "@/util/scopevisiotypes"
import Page from '@/components/Page.vue'
import {Apaleoserver} from "@/util/apaleoserver"
import {Scopevisioserver} from "@/util/scopevisioserver"
import {showConfirmAsync, showNotification} from "@/util/eventbus"
import {Statistics, StatisticsAccount, StatisticsAccountMapping} from "@/util/statistics";
import {ManagedData} from "@/util/manageddata";
import { Properties } from "@/util/properties"
import {Util} from "@/util/util"
import {Logger} from "@/util/logger"
import { Organisations } from "@/util/organisations"
import NoMappingMessage from "@/components/NoMappingMessage.vue"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"


@Component( {
    components: { Page , NoMappingMessage, NoConnectionMessage}
})
export default class SettingsStatistic extends Vue {

    loading = false

    headers = [
        {text: 'Apaleo Statistikkonto', value: 'apaleoStatisticAccountName'},
        {text: 'Scopevisio Statistikkonto', value: 'scopevisioStatisticAccountName'},
        {text: 'Kostenstelle', value: 'costUnit'},
    ]
    statisticsAccountMappings = [] as StatisticsAccountMapping[]

    scopevisioAccounts = [] as StatisticsAccount[]
    scopevisioStatisticsUnits = [] as string[]

    apaleoStatisticAccounts = Statistics.getApaleoOccupationUnits()

    costUnits = [] as Dimension[]

    async mounted() {
        if (this.isConnectedToScopevisio && this.isConnectedToApaleo && Properties.isSelected()) {
            this.setStatisticsAccountsMappings()
        }
    }

    @Watch("$store.getters.selectedProperty")
    async setStatisticsAccountsMappings() {
        this.statisticsAccountMappings = [] as StatisticsAccountMapping[]

        if (this.$store.getters.selectedProperty === "") {
            return
        }

        this.loading = true

        this.costUnits = await this.getDimensionAccounts(1)
        this.setScopevisioStatisticsAccounts()
        
        const statisticsAccountMappings = [] as StatisticsAccountMapping[]
        let storedMappings = [] as StatisticsAccountMapping[]
        
        if (this.$store.getters.loadedStatisticsAccountMappings) {
            storedMappings = Util.deepCopy(this.$store.getters.loadedStatisticsAccountMappings)
        }
        
        // integrate store values
        for (const [, mapping] of Object.entries(this.apaleoStatisticAccounts)) {
            if (storedMappings.length) {
                const newMapping = storedMappings.find(
                    (m: { apaleoStatisticAccountId: any }) => m.apaleoStatisticAccountId === mapping.id
                )
                
                if (newMapping) {
                    statisticsAccountMappings.push(newMapping)
                } else {
                    statisticsAccountMappings.push({
                        "apaleoStatisticAccountId": mapping.id,
                        "apaleoStatisticAccountName": mapping.name,
                        "scopevisioStatisticAccountNumber": Statistics.unassigned,
                        "scopevisioStatisticAccountName": "",
                        "costUnit": 0,
                        "unit": ""
                    })
                }
            } else {
                statisticsAccountMappings.push({
                    "apaleoStatisticAccountId": mapping.id,
                    "apaleoStatisticAccountName": mapping.name,
                    "scopevisioStatisticAccountNumber": Statistics.unassigned,
                    "scopevisioStatisticAccountName": "",
                    "costUnit": 0,
                    "unit": ""
                })
            }
            
        }

        this.loading = false
        this.statisticsAccountMappings = Util.deepCopy(statisticsAccountMappings)
    }

    async getDimensionAccounts(dimension: number) {
        const accounts = await Scopevisioserver.instance.getDimensionAccounts(dimension)

        if (accounts) {
            accounts.sort((a, b) => (a.name < b.name ? -1 : 1));
        }
        accounts.unshift({
                number: 0,
                name: "[keine]",
                locked: false,
        })

        return accounts
    }

    async setScopevisioStatisticsAccounts() {
        this.scopevisioAccounts = await Statistics.getStatisticsAccounts()

        this.scopevisioAccounts.unshift({
                id: 0,
                name: ""
        } as StatisticsAccount)
    }

    scopevisioAccountName(item: StatisticsAccount) {
        if (item.id == 0) {
            return ""
        }

        return `${item.number} ${item.name}`;
    }

    costUnitName(item: Dimension) {
        if (item.number == 0) {
            return ""
        }

        return `${item.number} ${item.name}`;
    }

    apaleoAccountName(item: any) {
        return `${item.id} ${item.name}`;
    }

    get organisationChangeInProgress() {
        return Organisations.changeInProgress
    }
    get loadedStatisticsAccountMappings() {
        return this.statisticsAccountMappings
    }

    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    async onSave() {
        const r = await showConfirmAsync({
            message: "Wollen Sie die Statistikkonten speichern?"
        })

        if (!r) {
            showNotification({
                type: NotificationType.INFO,
                message: "Die Statistikkonten wurden nicht gespeichert",
                timeoutMs: 5000
            })

            return
        }

        const storedMappings = Util.deepCopy(this.statisticsAccountMappings)
        
        Logger.logStatisticAccountMappings(
            this.$store.getters.loadedStatisticsAccountMappings,
            storedMappings
        )
        
        this.$store.commit("loadedStatisticsAccountMappings", storedMappings)

        if (await ManagedData.setValue(this.$store.state)) {
            showNotification({
                type: NotificationType.INFO,
                message: "Die Kontenzuordnung wurde gespeichert.",
                timeoutMs: 5000
            })
        }
    }
    
    get hasHotelMappings() {
        return Organisations.changeInProgress || Properties.hasHotelMappings()
    }
    get organisationName() {
        return this.$store.getters.scopevisioAccount?.organisation?.name
    }
    get title() {
        if (this.hasHotelMappings && this.$store.getters.selectedProperty !== "") {
            return "Statistikkonteneinstellungen für " + Properties.getSelectedPropertyName()
        } else {
            return "Statistikkonteneinstellungen pro Hotel"
        }
    }
}
