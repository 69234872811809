/*
 * Purpose: central global event bus
 *
 * Written by Scopevisio AG 2021
 */

import Vue from 'vue'
import { NotificationInfo, ConfirmInfo } from './scopevisiotypes'

export const ACTION_NOTIFICATION = "action.notification"
export const ACTION_CONFIRM = "action.confirm"
export const EVENT_BUS = new Vue()


export function showNotification(notificationInfo: Partial<NotificationInfo>) {
    EVENT_BUS.$emit(ACTION_NOTIFICATION, notificationInfo)
}

export function showConfirm(confirmInfo: Partial<ConfirmInfo>) {
    EVENT_BUS.$emit(ACTION_CONFIRM, confirmInfo)
}

/* shorthand to show a confirm dialog, async style */
export async function showConfirmAsync(confirmInfo: Partial<ConfirmInfo>): Promise<boolean> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
        confirmInfo.onCancel = () => { resolve(false) }  // resolve with false here for convenience
        confirmInfo.onSuccess = () => { resolve(true) }
        showConfirm(confirmInfo)
    })
}

