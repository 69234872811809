/*
 * (c) by Scopevisio AG 2021
 */

/* server access is modelled in this file
 *
 * Accessing the server is done using the axios httpclient library
 * The native fetch api could have been used, but axios is a little
 * nicer to use (not a must have)
 *
 * All calls are typed using a
 *
 *   [TheCall]Request
 *   [TheCall]Response
 *
 * type definition.
 *
 * Note: you have to use try/catch to handle http errors when using axios
 * Always use Util.logAxiosError() to log all of those to the console
 * for diagnosis. In addition provide user feedback in a nicer way
 */
 
import axios from 'axios'
import {Util} from './util'
import { showNotification } from './eventbus'
import {ConnectionState, NotificationType} from './scopevisiotypes'
import store from '@/store'
import {Property} from "@/util/properties"
import QueryString from 'qs'
import {PropertyPerformance} from "@/util/statistics"
import {Logger} from './logger'
import { Account } from './accounts'
import { Folio, InvoicesResponse } from './reports'


interface FinanceFoliosResponse {
  folios: Folio[];
  count: number;
}

export interface GetPropertiesRequest {
    pageNumber: number
    pageSize: number
}

export interface GetAccountsRequest {
    propertyId: string
    depth?: number
    includeArchived?: boolean
}

export interface GetAccountsResponse {
    globalAccounts: ApaleoAccount[]
    guestAccounts: ApaleoAccount[]
    externalAccounts: ApaleoAccount[]
}

export interface TopAccountsRequest {
    propertyId: string
}

export interface TopAccountsResponse {
    accounts: ApaleoAccount[]
    count: number
}

export interface GetChildAccountsRequest {
    propertyId: string
    parent: string
    includeArchived?: boolean
    pageSize?: number
}

export interface GetChildAccountsResponse {
    accounts: ChildAccount[]
    count: number
}

export interface ChildAccount {
    "accountNumber": string
    "name": string
    "type": string
    "parentNumber": string
    "hasChildren": boolean
    "isArchived": boolean
}


export interface ApaleoAccount {
    accountNumber: string
    name: string
    type: string
    hasChildren: boolean
    isArchived: boolean
    subAccounts?: ApaleoAccount[]
}


export interface GetCompaniesRequest {
    propertyId: string
    pageNumber?: number
    pageSize?: number
}

export interface GetCompaniesResponse {
    companies: ApaleoCompany[]
    count: number
}

export interface GetPropertiesPerformanceRequest {
    propertyId: string
    from: string
    to: string
    expand: string
    timeSliceDefinitionIds: string
}

export interface ApaleoCompany {
    id: string                  // seems to be PROPERTY-CODE concatenation
    code: string                // some id
    propertyId: string          // containing property
    name: string                // dispolay name
    taxId: string 
    canCheckOutOnAr: boolean
    address: {
        addressLine1: string
        city: string
        countryCode: string     // iso 2 letter code
        postalCode: string      // zipcode, but its a string
    },
    ratePlans: ApaleoRatePlan[]  // unclear structure
}

export interface ApaleoRatePlan {
    id: string
    code: string
    corporateCode: string
    name: string
}


export interface getPropertiesResponse {
    properties: Property[]
    count: number
}

export interface GrossTransactionsRequest {
    propertyId: string
    dateFilter: string
}
export interface AggregatedPairsDailyTransactionsRequest {
    propertyId: string
    from: string
    to: string
}

export type MainPath = keyof GetAccountsResponse


/* Server class wrapping up all server ops
 */
export class Apaleoserver {

    /* singleton instance */
    static instance = new Apaleoserver()

    baseUrl = process?.env?.VUE_APP_API_BASE_URL ?? 'http://localhost:8080/api'
    apiServer = "https://api.apaleo.com"
    authServer = "https://identity.apaleo.com"
    accessToken = ""
    lastRenew = -1

    private constructor() {
        //console.table({ baseUrl: this.baseUrl, apaleoApiServer: this.apiServer })
    }


    async fetchRefreshToken(code: string) {
        const body = {
            code: code,
            env: process?.env?.VUE_APP_ENV ?? 'local',
        }
        
        const response = await axios.post(`${this.baseUrl}/refreshtoken`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        const data = response.data
        
        store.commit('apaleoRefreshToken', data.refresh_token)
        //Util.cl("got refreshToken: " + store.state.refreshToken)
    }

    private makeAuth() {
        return "Bearer " + this.accessToken
    }

    private handleAuth(e: any) {
        const status = Util.getAxiosStatus(e)
        if (status == -1 || status == 401 || status == 400 || status == 403) {
            store.commit("apaleoRefreshToken", "")
            showNotification({
                type: NotificationType.ERROR,
                message: "Keine Verbindung zu Apaleo",
                timeoutMs: 5000,
            })
            /*
            setTimeout(() => {
                ApaleoAuth.startAuth()
            }, 2000)
            */
        }
        Util.logAxiosError(e)
    }

    /* renew the token every n-minutes */
    private async renew() {
        if (!store.getters.apaleoRefreshToken) {
            return {
                message: "no refreshtoken present",
                response: {
                    status: -1
                }
            }
        }
        const MINUTES = 5
        const tooOld = this.lastRenew == -1
            || new Date().getTime() - this.lastRenew > MINUTES * 60000

        const needRenew = tooOld || !this.accessToken
        if (!needRenew) {
            return
        }
        const body = {
            refresh_token: store.getters.apaleoRefreshToken
        }
        const response = await axios.post(`${this.baseUrl}/accesstoken`, body, {
            headers: { 'Content-Type': 'application/json' }
        })
        this.lastRenew = new Date().getTime()
        this.accessToken = response.data.access_token
    }

    async setMyAccount() {
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/account/v1/accounts/current`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            const account = response.data as ApaleoAccount

            Logger.log(
                "Stammdaten",
                "User " + account.name + " bei Apaleo angemeldet",
                "status",
                [],
                "",
                false
            )

            store.commit("apaleoAccount", account)            
        } catch (err) {
            this.handleAuth(err)
        }        
    }

    async getPropertyCount() {
        let count = -1
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/inventory/v1/properties/$count`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            count = response.data.count
        } catch (err) {
            this.handleAuth(err)
        }
        return count
    }

    async getProperties(request: GetPropertiesRequest) {
        let data: getPropertiesResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/inventory/v1/properties`, {
                params: request,
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }
        return data
    }

    async getPropertyAccounts(request: GetAccountsRequest) {
        let data: GetAccountsResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v1/accounts/schema`, {
                params: request,
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)    
        }

        return data
    }

    async getPropertyPerformance(request: GetPropertiesPerformanceRequest) {
        let data = {} as PropertyPerformance

        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/reports/v1/reports/property-performance`,
                {
                    params: request,
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    /* this is somehow different from getAccounts */
    async getTopAccounts(request: TopAccountsRequest) {
        let data: TopAccountsResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v0-nsfw/accounts/top-accounts`, {
                params: request,
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }


    async getPropertyChildAccounts(request: GetChildAccountsRequest) {
        let data: GetChildAccountsResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v0-nsfw/accounts/child-accounts`, {
                params: request,
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getAccountByNumber(number: string) {
        let data = {} as Account
        const request = {
            propertyId: store.getters.selectedProperty,
        }

        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v0-nsfw/accounts/${number}`, {
                params: request,
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getCompanies(request: GetCompaniesRequest) {
        let data: GetCompaniesResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.apiServer}/rateplan/v0-nsfw/companies`, {
                params: request,
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getGrossTransactions(request: GrossTransactionsRequest) {
        //     https://api.apaleo.com/reports/v0-nsfw/reports/gross-transactions?propertyId=BER&dateFilter=gte_2021-06-07,lte_2021-06-07

        let data = null
        try {
            await this.renew()
            const response = await axios.post(`${this.apiServer}/reports/v0-nsfw/reports/gross-transactions?${QueryString.stringify(request)}`, null, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data.transactions
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getAggregatePairsDailyTransactions(request: AggregatedPairsDailyTransactionsRequest) {
        // https://api.apaleo.com/finance/v0-nsfw/accounts/aggregate-pairs-daily?propertyId=BER&from=2021-01-01&to=2022-12-31
        

        let data = null
        try {
            await this.renew()
            const response = await axios.post(`${this.apiServer}/finance/v0-nsfw/accounts/aggregate-pairs-daily?${QueryString.stringify(request)}`, null, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data.accountTransactionPairs
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getFolioIdsByReservationId(reservationId: string) {
        let data = {} as FinanceFoliosResponse

        try {
            this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v0-nsfw/folios`, {
                params: {
                    reservationIds: reservationId
                },
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getInvoicesByReservationId(reservationId: string) {
        let data = {} as InvoicesResponse

        try {
            this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v0-nsfw/invoices`, {
                params: {
                    reservationIds: reservationId,
                    paymentSettled: false //,status: "Unpaid"
                },
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            // sort by actuality desc
            data = response.data
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }

    async getInvoicePdf(invoiceId: string) {
        let data = {} as any

        try {
            this.renew()
            const response = await axios.get(`${this.apiServer}/finance/v0-nsfw/invoices/${invoiceId}/pdf`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                },
                responseType: "arraybuffer"
            })
            data = new Uint8Array(response.data)
        } catch (err) {
            this.handleAuth(err)
        }

        return data
    }


    isConnected() {
        return (store.getters.apaleoRefreshToken != "")
    }

    checkConnection() {
        if (store.getters.apaleoRefreshToken != "") {
            return ConnectionState.CONNECTED
        } else {
            return ConnectionState.DISCONNECTED
        }
    }

    async checkConnectionCall() {
        const count = await this.getPropertyCount()
        if (count <= -1) {
            return ConnectionState.DISCONNECTED
        } else {
            return ConnectionState.CONNECTED
        }
    }
}

