/*
 * Purpose: providing utilities for several purposes (time, cloning, number, ...)
 *
 * Written by Scopevisio AG 2021
 */

import moment from "moment"

export interface Iso2CountryList {
    [key: string]: string
}

export class Util {

    static deepCopy<E>(o: E): E {
        const s = JSON.stringify(o)

        return JSON.parse(s)
    }

    static arraysAreEqual(a: string[], b: string[]) {
        return a?.every(item => b?.includes(item)) && b?.every(item => a?.includes(item))
    }

    static htmlEntities(string: string) {
        return string
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
    }

    static formatGermanNumber(n: number) {
        let raw = n.toString()
        raw = raw.replaceAll(",", "").replaceAll(".", ",")

        return raw
    }

    static parseGermanNumber(raw: string) {
        if (!raw)
            return 0
        raw = raw.replaceAll(".", "").replaceAll(",", ".")
        
        return Number.parseFloat(raw)
    }

    /* limits the string s to length l
     */
    static limit(s: string | undefined, l: number) {
        return !s ? s : s.substring(0, Math.min(l, s.length))
    }

    static nCopies(s: string, n: number) {
        let r = ""
        for (let i = 0; i < n; i++) {
            r += s
        }

        return r
    }

    static contains(haystack: string, needle: string) {
        return haystack && needle && haystack.indexOf(needle) != -1
    }

    static async readJson(url: string): Promise<any> {
        const response = await fetch(url)
        const data = await response.json()

        return data
    }

    static async readFile(file: Blob): Promise<any> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader()
            reader.onerror = reject
            reader.onload = (event) => {
                const fileReader = event.target as FileReader
                const r = fileReader.result as string
                resolve(r)
            }
            reader.readAsDataURL(file)
        })
    }

    static formatDate(ts: number | string): string {
        if (!ts) {
            return ""
        }
        const d = new Date()
        let dmy: string[] = []

        if (typeof ts === "string") {
            dmy = ts.split(".")
        }
        if (dmy.length === 3) {
            d.setFullYear(parseInt(dmy[2]), parseInt(dmy[1]) - 1, parseInt(dmy[0]))
        } else {
            d.setTime(typeof ts === "string" ? parseInt(ts) : ts)
        }
        // 2-stellige Datumsfelder fuer Datepicker
        return isNaN(d.getTime()) ? "" : ("0" + d.getDate()).substr(-2, 2) + "." + ("0" + (d.getMonth() + 1)).substr(-2, 2) + "." + d.getFullYear()
    }

    static formatDatetime(ts: number, withoutSeconds?: boolean, withoutDate?: boolean): string {
        if (!ts) {
            return ""
        }
        const d = new Date(ts)
        let dateString = ""

        if (isNaN(d.getTime())) {
            return ""
        }

        if (!withoutDate) {
            dateString = ("0" + d.getDate()).substr(-2, 2) + "." + ("0" + (d.getMonth() + 1)).substr(-2, 2) + "." + d.getFullYear() +
                "‧"
        }
        dateString += ("0" + d.getHours()).substr(-2, 2) + ":" + ("0" + d.getMinutes()).substr(-2, 2)

        if (withoutSeconds) {
            return dateString
        }
        dateString += ":" + ("0" + d.getSeconds()).substr(-2, 2)

        return dateString
    }


    static formatDateEnglish(date: string) {
        return moment(date).clone().format('YYYY-MM-DD')
    }
    static formatDateGerman(date: string) {
        return moment(date).clone().format('D.M.YYYY')
    }
    static formatDateTimeGerman(date: string) {
        return moment(date).clone().format('D.M.\'YY - H:mm:ss')
    }
    static formatDateTimeGermanLong(date: string) {
        return moment(date).clone().format('DD.MM.YYYY H:mm')
    }


    static makeid(n: number) {
        const dec2hex = (dec: number) => {
            return dec.toString(16).padStart(2, "0")
        }
        const arr = new Uint8Array(n / 2)
        window.crypto.getRandomValues(arr)

        return Array.from(arr, dec2hex).join('')
    }

    static toHex(ns: number[]) {
        const dec2hex = (dec: number) => {
            return dec.toString(16).padStart(2, "0")
        }

        return Array.from(ns, dec2hex).join('')
    }

    static getAxiosStatus(error: any): number {
        return error.response ? error.response.status : -1
    }

    static logAxiosError(error: any) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            Util.cl("httpstatus: ", error.response.status)
            Util.cl("httpdata: ", error.response.data)
            Util.cl("httpheaders: ", error.response.headers)
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            Util.cl("axios request-error: " + error.request)
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error", error.message)
        }
    }

    static convertBase64(bytes: any) {
        const string = Buffer.from(bytes).toString('base64')

        return string
    }

    static randomInt(n: number) {
        const cs = (x: number, y: number) => x + (y - x + 1) * crypto.getRandomValues(new Uint32Array(1))[0] / 2 ** 32 | 0
        
        return cs(0, n - 1)
    }

    static cl(...args: any) {
        if (Util.isDev()) {  
            console.log(args)
        }
    }
    
    static makeCode(n: number) {
        const codesymbols
            = "ABCDEFGHIJKLMNPQRSTUVWXYZ"
            + "123456789"

        let buffer = ""

        for (let i = 0; i < n; i++) {
            const s = codesymbols[Util.randomInt(codesymbols.length)]
            buffer += s
        }

        return buffer
    }
    
    static upperCaseFirst(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static getCountryNameByIsoCode(code: string) {
        const countryNameToIsoCodeList = this.getCountryNameToIsoCodeList()
        const countryName = countryNameToIsoCodeList[code.toUpperCase()]

        if (countryName) {
            return countryName
        } else {
            return code
        }
    }

    static isDev() {
        return (process.env.NODE_ENV === 'development')
    }

    static getCountryNameToIsoCodeList() {
        return {
            "AF": "Afghanistan",
            "EG": "Ägypten",
            "AL": "Albanien",
            "DZ": "Algerien",
            "AD": "Andorra",
            "AO": "Angola",
            "AI": "Anguilla",
            "AQ": "Antarktis",
            "AG": "Antigua und Barbuda",
            "GQ": "Äquatorial Guinea",
            "AR": "Argentinien",
            "AM": "Armenien",
            "AW": "Aruba",
            "AZ": "Aserbaidschan",
            "ET": "Äthiopien",
            "AU": "Australien",
            "BS": "Bahamas",
            "BH": "Bahrain",
            "BD": "Bangladesh",
            "BB": "Barbados",
            "BE": "Belgien",
            "BZ": "Belize",
            "BJ": "Benin",
            "BM": "Bermudas",
            "BT": "Bhutan",
            "MM": "Birma",
            "BO": "Bolivien",
            "BA": "Bosnien-Herzegowina",
            "BW": "Botswana",
            "BV": "Bouvet Inseln",
            "BR": "Brasilien",
            "IO": "Britisch-Indischer Ozean",
            "BN": "Brunei",
            "BG": "Bulgarien",
            "BF": "Burkina Faso",
            "BI": "Burundi",
            "CL": "Chile",
            "CN": "China",
            "CX": "Christmas Island",
            "CK": "Cook Inseln",
            "CR": "Costa Rica",
            "DK": "Dänemark",
            "DE": "Deutschland",
            "DJ": "Djibuti",
            "DM": "Dominika",
            "DO": "Dominikanische Republik",
            "EC": "Ecuador",
            "SV": "El Salvador",
            "CI": "Elfenbeinküste",
            "ER": "Eritrea",
            "EE": "Estland",
            "FK": "Falkland Inseln",
            "FO": "Färöer Inseln",
            "FJ": "Fidschi",
            "FI": "Finnland",
            "FR": "Frankreich",
            "GF": "französisch Guyana",
            "PF": "Französisch Polynesien",
            "TF": "Französisches Süd-Territorium",
            "GA": "Gabun",
            "GM": "Gambia",
            "GE": "Georgien",
            "GH": "Ghana",
            "GI": "Gibraltar",
            "GD": "Grenada",
            "GR": "Griechenland",
            "GL": "Grönland",
            "UK": "Großbritannien",
            "GB": "Großbritannien (UK)",
            "GP": "Guadeloupe",
            "GU": "Guam",
            "GT": "Guatemala",
            "GN": "Guinea",
            "GW": "Guinea Bissau",
            "GY": "Guyana",
            "HT": "Haiti",
            "HM": "Heard und McDonald Islands",
            "HN": "Honduras",
            "HK": "Hong Kong",
            "IN": "Indien",
            "ID": "Indonesien",
            "IQ": "Irak",
            "IR": "Iran",
            "IE": "Irland",
            "IS": "Island",
            "IL": "Israel",
            "IT": "Italien",
            "JM": "Jamaika",
            "JP": "Japan",
            "YE": "Jemen",
            "JO": "Jordanien",
            "YU": "Jugoslawien",
            "KY": "Kaiman Inseln",
            "KH": "Kambodscha",
            "CM": "Kamerun",
            "CA": "Kanada",
            "CV": "Kap Verde",
            "KZ": "Kasachstan",
            "KE": "Kenia",
            "KG": "Kirgisistan",
            "KI": "Kiribati",
            "CC": "Kokosinseln",
            "CO": "Kolumbien",
            "KM": "Komoren",
            "CG": "Kongo",
            "CD": "Demokratische Republik Kongo",
            "HR": "Kroatien",
            "CU": "Kuba",
            "KW": "Kuwait",
            "LA": "Laos",
            "LS": "Lesotho",
            "LV": "Lettland",
            "LB": "Libanon",
            "LR": "Liberia",
            "LY": "Libyen",
            "LI": "Liechtenstein",
            "LT": "Litauen",
            "LU": "Luxemburg",
            "MO": "Macao",
            "MG": "Madagaskar",
            "MW": "Malawi",
            "MY": "Malaysia",
            "MV": "Malediven",
            "ML": "Mali",
            "MT": "Malta",
            "MP": "Marianen",
            "MA": "Marokko",
            "MH": "Marshall Inseln",
            "MQ": "Martinique",
            "MR": "Mauretanien",
            "MU": "Mauritius",
            "YT": "Mayotte",
            "MK": "Mazedonien",
            "MX": "Mexiko",
            "FM": "Mikronesien",
            "MZ": "Mocambique",
            "MD": "Moldavien",
            "MC": "Monaco",
            "MN": "Mongolei",
            "MS": "Montserrat",
            "NA": "Namibia",
            "NR": "Nauru",
            "NP": "Nepal",
            "NC": "Neukaledonien",
            "NZ": "Neuseeland",
            "NI": "Nicaragua",
            "NL": "Niederlande",
            "AN": "Niederländische Antillen",
            "NE": "Niger",
            "NG": "Nigeria",
            "NU": "Niue",
            "KP": "Nord Korea",
            "NF": "Norfolk Inseln",
            "NO": "Norwegen",
            "OM": "Oman",
            "AT": "Österreich",
            "PK": "Pakistan",
            "PS": "Palästina",
            "PW": "Palau",
            "PA": "Panama",
            "PG": "Papua Neuguinea",
            "PY": "Paraguay",
            "PE": "Peru",
            "PH": "Philippinen",
            "PN": "Pitcairn",
            "PL": "Polen",
            "PT": "Portugal",
            "PR": "Puerto Rico",
            "QA": "Qatar",
            "RE": "Reunion",
            "RW": "Ruanda",
            "RO": "Rumänien",
            "RU": "Russland",
            "LC": "Saint Lucia",
            "ZM": "Sambia",
            "AS": "Samoa",
            "WS": "Samoa",
            "SM": "San Marino",
            "ST": "Sao Tome",
            "SA": "Saudi Arabien",
            "SE": "Schweden",
            "CH": "Schweiz",
            "SN": "Senegal",
            "SC": "Seychellen",
            "SL": "Sierra Leone",
            "SG": "Singapur",
            "SK": "Slowakei",
            "SI": "Slowenien",
            "SB": "Solomon Inseln",
            "SO": "Somalia",
            "GS": "Südgeorgien und die Südlichen Sandwichinseln",
            "ES": "Spanien",
            "LK": "Sri Lanka",
            "SH": "St. Helena",
            "KN": "St. Kitts Nevis Anguilla",
            "PM": "St. Pierre und Miquelon",
            "VC": "St. Vincent",
            "KR": "Süd Korea",
            "ZA": "Südafrika",
            "SD": "Sudan",
            "SR": "Surinam",
            "SJ": "Svalbard und Jan Mayen Islands",
            "SZ": "Swasiland",
            "SY": "Syrien",
            "TJ": "Tadschikistan",
            "TW": "Taiwan",
            "TZ": "Tansania",
            "TH": "Thailand",
            "TP": "Timor",
            "TG": "Togo",
            "TK": "Tokelau",
            "TO": "Tonga",
            "TT": "Trinidad Tobago",
            "TD": "Tschad",
            "CZ": "Tschechische Republik",
            "TN": "Tunesien",
            "TR": "Türkei",
            "TM": "Turkmenistan",
            "TC": "Turks und Kaikos Inseln",
            "TV": "Tuvalu",
            "UG": "Uganda",
            "UA": "Ukraine",
            "HU": "Ungarn",
            "UY": "Uruguay",
            "UZ": "Usbekistan",
            "VU": "Vanuatu",
            "VA": "Vatikan",
            "VE": "Venezuela",
            "AE": "Vereinigte Arabische Emirate",
            "US": "Vereinigte Staaten von Amerika",
            "VN": "Vietnam",
            "VG": "Virgin Island (Brit.)",
            "VI": "Virgin Island (USA)",
            "WF": "Wallis et Futuna",
            "BY": "Weissrussland",
            "EH": "Westsahara",
            "CF": "Zentralafrikanische Republik",
            "ZW": "Zimbabwe",
            "CY": "Zypern"
        } as Iso2CountryList
    }
}
