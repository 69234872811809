/*
 * Purpose: providing utilities to handle organisations
 *
 * Written by Scopevisio AG 2021
 */

import store from "@/store";
import { Scopevisioserver } from "./scopevisioserver";
import { OrganisationRecord } from "./scopevisiotypes"


export class Organisations {
    static getNameById(id: number) {
        if (store.getters.scopevisioAccount.organisations) {
            const organisation = store.getters.scopevisioAccount.organisations.find( 
                (organisation: OrganisationRecord) => (organisation.id == id))
            if (organisation) {
                return organisation.name
            }
        }  

        return ""      
    }

    static get changeInProgress() {
        return Scopevisioserver.instance.organisationChangeInProgress
    }
}