
import { Component, Vue, Prop } from "vue-property-decorator"
import { ApaleoAuth } from "@/util/apaleoauth"


@Component
export default class NoConnectionMessage extends Vue {
    @Prop({default: "scopevisio"})
    system!: string

    onApaleoLogin() {
        ApaleoAuth.startAuth()        
    }
}
