
import {Component, Vue, Watch} from "vue-property-decorator"
import {Properties, Property} from "@/util/properties"
import CountryLookup from "country-code-lookup"
import {Scopevisioserver} from "@/util/scopevisioserver";
import {Organisation} from "@/util/scopevisiotypes";
import ScopevisioOrganisationSelector from "@/components/ScopevisioOrganisationSelector.vue";
import LogoutDialog from "@/components/LogoutDialog.vue";
import { Apaleoserver } from "@/util/apaleoserver";
import { Settings } from "@/util/settings";
import { ApaleoAuth } from "@/util/apaleoauth";
import { Organisations } from "@/util/organisations";
import { Util } from "@/util/util";

@Component({
    components: {LogoutDialog, ScopevisioOrganisationSelector},
})
export default class Menu extends Vue {

    drawer = true
    settingsMenuOpen = false
    exportMenuOpen = false
    organisationChanging = false

    property = {
        location: {}
    } as Property

    properties = [] as Property[]
    organisations = [] as Organisation[]


    mounted() { 
        this.openRelevantMenus()
        this.setOrganisations()        
        this.setProperties() 
    }


    openRelevantMenus() {
        if (this.$route.name === "Home") {
            this.drawer = true
        } 

        if (new RegExp('/settings/*').test(this.$route.path)) {
            this.settingsMenuOpen = true
        }

        if (new RegExp('/export/*').test(this.$route.path) || new RegExp('/debitors/*').test(this.$route.path)) {
            this.exportMenuOpen = true
        }
    }

    async setOrganisation(organisationId: number) {
        this.organisationChanging = true
        Scopevisioserver.instance.organisationChangeInProgress = true

        const organisation = this.organisations?.find(x => x.id === organisationId)
        
        if (organisation) {
            this.properties = []
            await Settings.setStoreForOrganisation(organisation)
        }
        this.organisationChanging = false
        Scopevisioserver.instance.organisationChangeInProgress = false
    }

    setProperty(selectedProperty: string) {
        return this.$store.commit("selectProperty", selectedProperty)
    }

    get development() {
        return (process.env.NODE_ENV === 'development')
    }

    get managedDataModifiedTs() {
        return this.$store.getters.metaData.ts
    }

    get managedDataModified() {
        return Util.formatDateTimeGerman(this.managedDataModifiedTs)
    }

    get managedDataId() {
        return this.$store.getters.metaData.id
    }

    @Watch("$store.getters.allProperties")
    @Watch("$store.getters.scopevisioOrganisation.organisation")
    async setProperties() {
        this.properties = await Properties.getActiveProperties()

        if (this.properties.length > 0) {
            if (this.$store.getters.selectedProperty == "" && this.properties[0]?.id) {
                this.$store.commit("selectProperty", this.properties[0].id)
            }
        }
    }
    @Watch("$store.getters.selectedProperty")
    async setBasicSettingsProperty() {
        if (Object.keys(this.$store.getters.loadedBasicSettings).length === 0) {
            this.$store.commit("loadedBasicSettings", Settings.default)
        }
    }
    @Watch("$store.getters.scopevisioAccount.customer.publicId")
    async setOrganisations() {
        let organisations = this.$store.getters.scopevisioAccount?.organisations
        
        if (this.isConnectedToScopevisio && !organisations) {
            organisations = await Scopevisioserver.instance.getOrganisations()
            
            if (organisations?.records) {
                organisations = organisations.records
            }
        }

        this.organisations = organisations
    }

    get organisationChangeInProgress() {
        return Organisations.changeInProgress
    }

    get onDebitors() {
        return this.$route.name == "Debitors"
    }
    get statisticManagement() {
        return this.$store.getters.loadedBasicSettings?.statisticManagement
    }
    get myOrganisationId() {
        return this.$store.getters.scopevisioAccount?.organisation?.id
    }
    get propertySelected() {
        return this.$store.getters.selectedProperty
    }
    get propertyItems() {
        return this.properties
    }
    get organisationItems() {
        return this.organisations
    }

    organisationName(item: Organisation) {
        if (item.id == 0) {
            return ""
        }

        return item.name
    }

    async onLogoutDialog() {
        const logoutDialog = this.$refs["logoutdialog"] as LogoutDialog
        await logoutDialog.open()
    }

    isLoggedIn() {
        return (this.isConnectedToScopevisio || this.isConnectedToApaleo)
    }
 
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    get myAccountInstance() {
        const myAccount = this.$store.getters.scopevisioAccount

        if (!myAccount?.customer) {
            return ""
        }

        return myAccount?.customer?.name + "[" + myAccount?.customer?.publicId + "]"
    }

    propertyAdressLine1() {
        const property = Properties.getPropertyById(
            this.$store.getters.selectedProperty,
            Properties.getActiveProperties()
        )

        return property?.location?.addressLine1 || ""
    }

    propertyCity() {
        const property = Properties.getPropertyById(
            this.$store.getters.selectedProperty,
            Properties.getActiveProperties()
        )

        return ((property?.location?.postalCode || "" ) + " " + (property?.location?.city || "")).trim()
    }

    propertyCountry() {
        const property = Properties.getPropertyById(
            this.$store.getters.selectedProperty,
            Properties.getActiveProperties()
        )

        return this.getCountryNameByIsoCode(property?.location?.countryCode)
    }

    getCountryNameByIsoCode(code: string) {
        if (code && code.length !== undefined) {
            return CountryLookup.byIso(code)?.country
        }
    }

    toggle() {
        this.drawer = !this.drawer
    }

    onHome() {
        this.doRoute("/")
    }

    onAccounts() {
        this.doRoute("/accounts")
    }

    onSettingsMapping() {
        this.doRoute("/settings/mapping")
    }

    onSettingsBasic() {
        this.doRoute("/settings/basic")
    }

    onSettingsAccounting() {
        this.doRoute("/settings/accounting")
    }

    onSettingsStatistic() {
        this.doRoute("/settings/statistic")
    }

    onExport() {
        this.doRoute("/export/transactions")
    }

    onExportStatistics() {
        this.doRoute("/export/statistics")
    }

    onScopevisioSettings() {
        this.doRoute("/settings/scopevisio")
    }

    onApaleoSettings() {
        this.doRoute("/settings/apaleo")
    }

    onScopevisioLogin() {
        this.doRoute("/settings/scopevisio/go")
    }

    onApaleoLogin() {
        ApaleoAuth.startAuth()
        //this.doRoute("/settings/apaleo/go")
    }

    onLogs() {
        this.doRoute("/journal")
    }

    onImprint() {
        this.doRoute("/imprint")
    }

    private doRoute(path: string) {
        //this.drawer = false
        const currentRoute = this.$router.currentRoute
        if (currentRoute && currentRoute.path == path) {
            return
        }
        this.$router.push(path)
    }

}
