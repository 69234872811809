

import Page from "@/components/Page.vue"
import { Component, Vue } from "vue-property-decorator"
import { Apaleoserver } from "@/util/apaleoserver"
import { QueryParser} from '@/util/queryparser'
import { Properties } from "@/util/properties"

@Component({
    components: { Page },
})
export default class Auth extends Vue {
    propertyCount = 0

    async mounted() {
        const query = QueryParser.parse(window.location.href)
        const code = query.code || ""
        
        await Apaleoserver.instance.fetchRefreshToken(code)
        await Apaleoserver.instance.setMyAccount()
        await Properties.refreshPropertiesFromApaleo()
     
        this.propertyCount = Properties.propertyCount() // await Apaleoserver.instance.getPropertyCount()

        this.$router.push("/settings/apaleo")
    }
}
