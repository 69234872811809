/*
 * (c) by Scopevisio AG 2021
 */

/* server access is modelled in this file
 *
 * Accessing the server is done using the axios httpclient library
 * The native fetch api could have been used, but axios is a little
 * nicer to use (not a must have)
 *
 * All calls are typed using a
 *
 *   [TheCall]Request
 *   [TheCall]Response
 *
 * type definition.
 *
 * Note: you have to use try/catch to handle http errors when using axios
 * Always use Util.logAxiosError() to log all of those to the console
 * for diagnosis. In addition provide user feedback in a nicer way
 */
 
import axios from 'axios'
import {Util} from './util'
import {ConnectionState, ScopevisioAccountResponse, NotificationType, ManagedDataStatus, Profile, CreateDebitorResponse, CreateContactRequest, PostingsRequest, PostingsResponse, OrganisationsResponse, Dimension, VatMatrixEntriesResponse, OrganisationRecord, ImpersonalAccountsResponse, LoginResponse} from './scopevisiotypes'
import store from '@/store'
import QueryString from 'qs'
import {StatisticsAccount, StatisticsPosting, StatisticsPostingRequest} from "@/util/statistics"
import {showNotification} from "@/util/eventbus"
import {ManagedData} from './manageddata'
import { ScopevisioDebitor, CreateDebitorRequest, UpdateDebitorRequest, ScopevisioContact} from './debitorutil'


/* Server class wrapping up all server ops
 */
export class Scopevisioserver {

    /* singleton instance */
    static instance = new Scopevisioserver()

    /* lifetime in minutes */
    static TOKEN_LIFETIME = 5

    static SYSTEM_RIGHTS_NECCESSARY = [
        "ManagedData",
        "Kontakte - alle Rechte",
        "Finanzen - alle Rechte",
        "Finanzen - Statistikbuchungen"
    ]
    static ALL_ORGANISATIONS = "Instanz"

    baseUrl = "https://appload.scopevisio.com/rest"

    accessToken = ""
    lastRenew = -1
    organisationChangeInProgress = false
    showErrors = false

    private constructor() {
        /* */
    }


    static hasProfile(profileSearch: Profile, profiles: Profile[]) {
        for (const profile of profiles) {
            if (profile.name === profileSearch.name && 
                (profile.organisation === profileSearch.organisation) || 
                (profile.organisation === Scopevisioserver.ALL_ORGANISATIONS)) {
                return true
            }
        }

        return false
    }

    static usageRights(organisation: string, profiles: Profile[]) {
        const usageRights = {
            ok: [] as string[],
            failed: [] as string[],
        }

        for (const rightName of Scopevisioserver.SYSTEM_RIGHTS_NECCESSARY) {
            const checkProfile = {
                name: rightName,
                organisation: organisation
            } as Profile
            
            if (Scopevisioserver.hasProfile(checkProfile, profiles)) {
                usageRights.ok.push(rightName)
            } else {
                usageRights.failed.push(rightName)
            }
        }

        return usageRights
    }

    async fetchRefreshToken(code: string) {
        const body = {
            client_secret: "a396801c-d723-11eb-8476-178e743767da",
            grant_type: "authorization_code",
            code: code,
            client_id: 'apaleo'
        }

        const response = await axios.post(`${this.baseUrl}/token`, QueryString.stringify(body), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        const data = response.data as LoginResponse
        store.commit('scopevisioRefreshToken', data.refresh_token)
    }

    private makeAuth() {
        return "Bearer " + this.accessToken
    }


    async createStatisticPostings(postings: StatisticsPosting[]) {
        const request = {
            autoCreateAccount: true,
            autoAccountDecimal: 2,
            statisticsPostings: postings,
        } as StatisticsPostingRequest

        try {
            await this.renew()
            return await axios.post(
                `${this.baseUrl}/statisticspostings/new`,
                request,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                })
        } catch (err: any) {
            Util.logAxiosError(err)

            return err.response.data
        }
    }

    async getStatisticsPostings() {
        let statisticsPostings = [] as StatisticsPosting[]
        try {
            await this.renew()
            const response = await axios.post(
                `${this.baseUrl}/statisticspostings`,
                {
                    pageSize: 1000,
                },
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                })
            Util.cl(response)
            statisticsPostings = response.data.records
        } catch (err) {
            Util.logAxiosError(err)
        }
        return statisticsPostings
    }


    async getStatisticsUnits() {
        let statisticsUnits = {} as string[]
        try {
            await this.renew()
            const response = await axios.get(
                `${this.baseUrl}/catalog/accounting/statisticsunit`,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                })
            statisticsUnits = response.data.records
        } catch (err) {
            Util.logAxiosError(err)
        }

        return statisticsUnits
    }

    async getStatisticsAccounts() {
        let statisticsAccounts = [] as StatisticsAccount[]
        try {
            await this.renew()
            const response = await axios.post(
                `${this.baseUrl}/statisticsaccounts`,
                {
                    pageSize: 1000,
                },
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                })
            statisticsAccounts = response.data.records
        } catch (err) {
            Util.logAxiosError(err)
        }

        return statisticsAccounts
    }

    async createManagedDataEntry(data: Record<string, unknown>) {
        try {
            await this.renew()

            const response = await axios.post(
                `${this.baseUrl}/manageddata/${ManagedData.key}/entry/new`,
                data,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                }
            )
            
            return response
        } catch (err: any) {
            this.showErrors && showNotification({
                type: NotificationType.ERROR,
                message: "Es fehlt das Schema um die Applikationsdaten zu persistieren.",
                timeoutMs: 50000
            })
            Util.logAxiosError(err)
        }
    }

    async deleteManagedDataEntry(entryId: string) {
        try {
            await this.renew()

            const response = await axios.delete(
                `${this.baseUrl}/manageddata/${ManagedData.key}/entry/${entryId}`,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                }
            )
            
            return response
        } catch (err: any) {
            this.showErrors && showNotification({
                type: NotificationType.ERROR,
                message: `Der Eintrag ${entryId} konnte nicht gelöscht werden`,
                timeoutMs: 50000
            })
            Util.logAxiosError(err)
        }
    }

    async managedDataStatus(tableIdOrName: number|string, entryId: number) {
        let managedData = {} as ManagedDataStatus
        
        try {
            await this.renew()
            
            if (!store.getters.scopevisioRefreshToken) {
                managedData.code = 401

                return managedData
            }

            const search = [
                {
                    "field": "key",
                    "value": tableIdOrName,
                    "operator": "equal"
                }
            ]

            if ( entryId > 0 ) {
                search.push(
                    {
                        "field": "id",
                        "value": entryId,
                        "operator": "equal"
                    }
                )
            }

            const payload = {
                pageSize: 1000,
                fields: ["modifiedTs", "id", "modifierName"],
                search: search
            }

            const response = await axios.post(
                `${this.baseUrl}/manageddata/${tableIdOrName}/entries`,
                payload,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                }
            )

            if (response.data.records.length > 0) {
                managedData = response.data.records[0]
            }
            
        } catch (err: any) {
            // this.showErrors && showNotification({
            //     type: NotificationType.ERROR,
            //     message: "Es fehlt das Schema um die Applikationsdaten zu persistieren.",
            //     timeoutMs: 50000
            // })
            Util.logAxiosError(err)

            managedData.code = err.response.status
            managedData.message = err.message
        }

        return managedData
    }
    async getAllManagedData(tableIdOrName: number|string, modifiedTs = 0, onlyCheckModifiedTs = false) {
        let managedData = [] as ManagedData[]
        try {
            await this.renew()
            
            if (!store.getters.scopevisioRefreshToken) {
                return managedData
            }

            const search = [
                {
                    "field": "key",
                    "value": tableIdOrName,
                    "operator": "equal"
                }
            ]

            if ( modifiedTs > 0 ) {
                search.push(
                    {
                        "field": "modifiedTs",
                        "value": modifiedTs,
                        "operator": "equal"
                    }
                )
            }

            let payload = {}

            if (onlyCheckModifiedTs) {
                payload = {
                    pageSize: 1000,
                    fields: ["modifiedTs"],
                    search: search
                }
            } else {
                payload = {
                    pageSize: 1000,
                    search: search
                }
            }

            const response = await axios.post(
                `${this.baseUrl}/manageddata/${tableIdOrName}/entries`,
                payload,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                }
            )
            managedData = response.data.records
        } catch (err: any) {
            this.showErrors && showNotification({
                type: NotificationType.ERROR,
                message: "Es fehlt das Schema um die Applikationsdaten zu persistieren.",
                timeoutMs: 50000
            })
            Util.logAxiosError(err)
        }

        return managedData
    }

    async getManagedData(tableId: number, entryId: number) {
        let managedData = {} as ManagedData
        try {
            await this.renew()
            managedData = await axios.get(
                `${this.baseUrl}/manageddata/${tableId}/entry/${entryId}`,
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                })
        } catch (err: any) {
            this.showErrors && showNotification({
                type: NotificationType.ERROR,
                message: "Es fehlt das Schema um die Applikationsdaten zu persistieren.",
                timeoutMs: 50000
            })
            Util.logAxiosError(err)
        }
        return managedData
    }

    async setManagedData(tableIdOrName: number|string, body: Record<string, unknown>) {
        try {
            await this.renew()
            //const entryId = await Scopevisioserver.instance.getManagedDataId(tableIdOrName)
            const entryId = ManagedData.getId()

            if (entryId) {
                return await axios.post(
                    `${this.baseUrl}/manageddata/${tableIdOrName}/entry/${entryId}`,
                    body,
                    {
                        headers: {
                            accept: "application/json",
                            authorization: this.makeAuth(),
                        }
                    })
            } else {
                throw new Error("No managed data found for key " + ManagedData.key)                
            }
        } catch (err: any) {
            this.showErrors && showNotification({
                type: NotificationType.ERROR,
                message: "Die Daten konnten nicht persistent gespeichert werden. Fehler: " + 
                    err.message,
                timeoutMs: 5000
            })
            Util.logAxiosError(err)
        }
    }

    async getDimensionAccounts(dimension: number) {
        let dimensions = [] as Dimension[]
        try {
            await this.renew()
            const response = await axios.get(`${this.baseUrl}/dimensions/${dimension}/dimensionentries`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            dimensions = response.data.records
        } catch (err) {
            Util.logAxiosError(err)
        }
        return dimensions
    }

    /* renew the token every n-minutes */
    private async renew() {
        if (!store.getters.scopevisioRefreshToken) {
            return {
                message: "no refreshtoken present",
                response: {
                    status: -1
                }
            }
        }
        const tooOld = this.lastRenew == -1
            || new Date().getTime() - this.lastRenew > Scopevisioserver.TOKEN_LIFETIME * 60000

        const needRenew = tooOld || !this.accessToken
        
        if (!needRenew) {
            return
        }
        const body = {
            refresh_token: store.getters.scopevisioRefreshToken,
            grant_type: "refresh_token",
        }
        const response = await axios.post(`${this.baseUrl}/token`, QueryString.stringify(body), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        /*
        const response = await axios.post(`${this.baseUrl}/token`, body, {
            headers: { 
                'Content-Type': 'application/json',
             }
        })
        */
        this.lastRenew = new Date().getTime()
        this.accessToken = response.data.access_token
    }



    async getMyAccount() {
        let data: ScopevisioAccountResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.baseUrl}/myaccount`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }
        return data
    }

    async getOrganisations() {
        let data: OrganisationsResponse|null = null
        try {
            await this.renew()
            const response = await axios.get(`${this.baseUrl}/organisations`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }
        return data
    }

    async getVatMatrixEntries() {
        let data = {} as VatMatrixEntriesResponse
        try {
            await this.renew()
            const response = await axios.get(`${this.baseUrl}/vatmatrixentries`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }
        return data
    }

    async getStatisticAccounts() {
        let data = {} as VatMatrixEntriesResponse
        try {
            await this.renew()
            const response = await axios.get(`${this.baseUrl}/vatmatrixentries`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }
        return data
    }

    async setOrganisation(organisation: OrganisationRecord) {
        let refreshToken = ""
        try {
            await this.renew()
            const response = await axios.put(`${this.baseUrl}/token/organisation/${encodeURIComponent(organisation.id)}`,
                null, {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                }
            )
            refreshToken = response.data.refresh_token
        } catch (err) {
            Util.logAxiosError(err)
        }
        return refreshToken
    }

    async getImpersonalAccounts() {
        let data: ImpersonalAccountsResponse | null = null
        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/impersonalaccounts`, {
                pageSize: 50000,
            }, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }
        return data
    }

    async postings(request: PostingsRequest) {
        let data: PostingsResponse
        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/postings`,
                request,
                {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err: any) {
            Util.logAxiosError(err)
            data = {
                r: err,
                message: err.response.data.backendResponse
            }
        }

        return data
    }

    async getContact(id: number) {
        let data = {} as ScopevisioContact

        try {
            await this.renew()
            const response = await axios.get(`${this.baseUrl}/contact/${id}?fields=lastname,city1,street1,postcode1,country1`, {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            data = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }

        return data
    }

    async getContacts(search: any) {
        let data = {} as ScopevisioContact[]

        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/contacts`,
                search, 
                {
                    headers: {
                        accept: "application/json",
                        authorization: this.makeAuth(),
                    }
                }
            )
            data = response.data.records
        } catch (err) {
            Util.logAxiosError(err)
        }

        return data
    }

    async createContact(request: CreateContactRequest) {
        let contactNumber = 0

        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/contact/new`,
                request,
                {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            contactNumber = response.data.contactId
        } catch (err) {
            Util.logAxiosError(err)
        }
        return contactNumber
    }

    async updateContact(id: number, contact: any) {
        try {
            await this.renew()
            await axios.post(`${this.baseUrl}/contact/` + id,
                contact,
                {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            
            return true
        } catch (err) {
            Util.logAxiosError(err)

            return false
        }
    }

    async createDebitor(request: CreateDebitorRequest) {
        let createDebitorResponse = {} as CreateDebitorResponse

        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/createdebitor`,
                request,
                {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            createDebitorResponse = response.data
        } catch (err) {
            Util.logAxiosError(err)
        }

        return createDebitorResponse
    }

    async updateDebitor(request : UpdateDebitorRequest, accountNumber: string) {
        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/debitoraccounts/${accountNumber}`,
                request,
                {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            return response.data
        } catch (err) {
            Util.logAxiosError(err)
        }

        return {}
    }



    async debitors(filter = {pageSize: 10000}) {
        let debitors = [] as ScopevisioDebitor[]

        try {
            await this.renew()
            const response = await axios.post(`${this.baseUrl}/debitoraccounts`,
                filter,
                {
                headers: {
                    accept: "application/json",
                    authorization: this.makeAuth(),
                }
            })
            debitors = response.data.records
        } catch (err) {
            Util.logAxiosError(err)
        }
        return debitors
    }

    isConnected() {
        return (store.getters.scopevisioRefreshToken != "")
    }

    checkConnection() {
        if (store.getters.scopevisioRefreshToken != "") {
            return ConnectionState.CONNECTED
        } else {
            return ConnectionState.DISCONNECTED
        }
    }
}
