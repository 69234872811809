/*
 * Purpose: providing utilities to handle properties
 *
 * Written by Scopevisio AG 2021
 */

import {Apaleoserver} from "@/util/apaleoserver";
import store from "@/store";
import { Scopevisioserver } from "./scopevisioserver"


export interface Location {
    addressLine1: string
    postalCode: string
    city: string
    countryCode: string
}

export interface BankAccount {
    iban: string
    bic: string
    bank: string
}

export interface PaymentTerms {
    de: string
    en: string
}

export interface Property {
    id: string
    code: string
    isTemplate: boolean
    name: string
    description: string
    companyName: string
    commercialRegisterEntry: string
    taxId: string
    location: Location
    bankAccount: BankAccount
    paymentTerms: PaymentTerms
    timeZone: string
    currencyCode: string
    created: Date
    selected?: boolean
}


export class Properties {
    static noHotelText = "Keine Auswahl"


    static async getPropertiesFromApaleo() {
        let properties = [] as Property[]
        const response = await Apaleoserver.instance.getProperties({
            pageNumber: 1,
            pageSize: 1000
        })
        if (response) {
            properties = response.properties
        }

        return properties
    }

    static async refreshPropertiesFromApaleo() {
        let properties = await Properties.getPropertiesFromApaleo()
        properties = await Properties.syncExistingMappings(properties)
        store.commit("allProperties", properties)
    }

    static getPropertyMap() {
        const propertyMap = new Map(
            store.getters.allProperties?.map(
                (property: Property) => [property.id, property]
            )
        )

        return propertyMap
    }

    static async syncExistingMappings(properties: Property[]) {
        const propertyMap = Properties.getPropertyMap()
        
        for (const property of properties) {
            if (propertyMap.get(property.id)) {
                const storedProperty = propertyMap.get(property.id) as Property

                if (storedProperty && storedProperty.selected) {
                    property.selected = storedProperty.selected
                } else {
                    property.selected = false
                }
            }
        }

        return properties
    }

    static getPropertyById(id: string, properties?: Property[]) {
        if (!properties) {
            properties = store.getters.allProperties
        }

        if (properties) {
            const property = properties.find(
                (property: Property) => (property.id == id)
            )
                
            if (property) {
                return property
            }
        }
        
        return {} as Property
    }

    static getPropertyNameById(id: string) {
        const property = Properties.getPropertyById(
            id,
            store.getters.allProperties,
        )
        
        if (property?.name) {
            return property.name
        } else {
            return "Hotel nicht gefunden"
        }
    }

    static getSelectedPropertyName() {
        const property = Properties.getPropertyById(
            store.getters.selectedProperty,
            store.getters.allProperties,
        )
        
        if (property?.name && Apaleoserver.instance.isConnected()) {
            return property.name
        } else {
            return Properties.noHotelText
        }
    }

    static getActiveProperties() {
        if (!store.getters.allProperties) {
            return [] as Property[]
        }
        const properties = store.getters.allProperties?.filter((property: Property) => property.selected === true)
        
        return properties
    }

    static isSelected() {
        return (store.getters.selectedProperty !== "")
    } 

    static hasProperties() {
        return (store.getters.allProperties.length > 0)
    } 

    static propertyCount() {
        return store.getters.allProperties.length 
    }

    static noMappedHotels() {
        return (Properties.getActiveProperties().length < 1)
    }

    static hasHotelMappings() {
        return Scopevisioserver.instance.organisationChangeInProgress || (Properties.getActiveProperties().length > 0)
    }
}