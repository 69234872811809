
import Page from '@/components/Page.vue'
import {Component, Vue, Watch} from "vue-property-decorator"
import NoConnectionMessage from "@/components/NoConnectionMessage.vue"
import {Properties, Property} from "@/util/properties"
import { Apaleoserver } from '@/util/apaleoserver'
import {NotificationType} from '@/util/scopevisiotypes'
import {showNotification} from "@/util/eventbus"
import {Scopevisioserver} from "@/util/scopevisioserver"
import {Util} from '@/util/util'
import {Logger} from '@/util/logger'
import {ManagedData} from '@/util/manageddata'


@Component( {
    components: { Page , NoConnectionMessage }
})
export default class SettingsMapping extends Vue {
    show = false
    selecting = false
    
    search = ""

    properties = [] as Property[]

    async created() {
        if (this.$store.getters.allProperties == undefined) {
            await Properties.refreshPropertiesFromApaleo()
        }
}
    async mounted() {
        this.updateProperties()
        this.show = true
    }

    get selectionInProgress() {
        Util.cl("selectionInProgress", this.selecting)
        
        return this.selecting
    }

    @Watch("$store.getters.allProperties")
    updateProperties() {        
        this.properties = Util.deepCopy(this.$store.getters.allProperties).sort((a: Property, b: Property) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
        })
    }
  
    async onActiveClick(property: Property) {
        if (this.selecting) {
            property.selected = !property.selected

            return false
        }
        this.selecting = true
        const lastChange = {
            type: "property", 
            entity: property.id, 
            change: this.selecting
        }

        if (! await ManagedData.equalManagedDataVersion(lastChange)) {
            property.selected = !property.selected
            this.selecting = false

            return false
        }

        if (property.selected) {
            if (!this.$store.getters.selectedProperty) {
                this.$store.commit("selectProperty", property.id)
            }
        } else {
            if (property.id === this.$store.getters.selectedProperty) {
                this.$store.commit("selectProperty", "")
            }
        }
        
        this.saveToStore(property)        
    }

    async saveToStore(property: Property) {
        this.selecting = true

        try {
            this.$store.commit("allProperties", Util.deepCopy(this.properties))
            await Logger.logPropertyActivation(property) 

            showNotification({
                type: NotificationType.INFO,
                message: "Die Hotel Zuordnungen wurden gespeichert",
                timeoutMs: 5000
            })
        } catch (e: any) {
            showNotification({
                type: NotificationType.ERROR,
                message: "FEHLER: Die Hotel Zuordnungen wurden nicht gespeichert. " + e.message,
                timeoutMs: 5000
            })
        } 
            
        this.selecting = false
    }

    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }

    getCountryNameByIsoCode(code: string) {
        return Util.getCountryNameByIsoCode(code)
    }

    header = [
        { text: 'Aktiv', sortable: true, value: 'selected'},
        { text: 'Hotelname', sortable: true, value: 'name', width: '25%'},
        { text: 'Unternehmen', sortable: true, value: 'companyName'},
        { text: 'Straße', sortable: true, value: 'location.addressLine1'},
        { text: 'PLZ', sortable: true, value: 'location.postalCode'},
        { text: 'Stadt', sortable: true, value: 'location.city'},
        { text: 'Land', sortable: true, value: 'location.countryCode'},
    ]
}
