
import Page from '@/components/Page.vue'
import {Component, Vue} from "vue-property-decorator"
import {showConfirmAsync, showNotification} from "@/util/eventbus"
import {Store} from "vuex"
import {StateType} from "@/store"
import {Properties} from "@/util/properties"
import {Util} from '@/util/util'
import { ApaleoAuth } from '@/util/apaleoauth'
import { Apaleoserver } from '@/util/apaleoserver'
import { Settings } from "@/util/settings"
import { Organisations } from '@/util/organisations'
import { NotificationType } from '@/util/scopevisiotypes'



@Component( {
    components: { Page }
})
export default class SettingsApaleo extends Vue {

    connected = true

    created() {
        if (this.$route.params.directredirect === "go") {
            ApaleoAuth.startAuth()
        }
    }

    async mounted() {
        let connected = false

        try {
            if (this.$store.getters.apaleoRefreshToken) {
                const properties = await Apaleoserver.instance.getProperties({
                    pageNumber: 1,
                    pageSize: 1,
                })
                if (properties && properties.count >= 1) {
                    connected = true

                    if (Object.keys(this.$store.getters.loadedBasicSettings).length === 0) {
                        this.$store.commit("loadedBasicSettings", Settings.default)
                    }
                }
            }
        } catch (e) {
            Util.logAxiosError(e)
        } finally {
            this.connected = connected
        }

        if (Properties.hasHotelMappings()) {
            this.$router.push("/")
        } else {
            showNotification({
                type: NotificationType.INFO,
                message: "Bitte aktivieren Sie mindestens ein Hotel."
            })
            this.$router.push("/settings/mapping")
        }  
    }

    get organisationChangeInProgress() {
        return Organisations.changeInProgress
    }

    get propertyName() {
        if (Properties.getSelectedPropertyName() == Properties.noHotelText) {
            return false
        }

        return Properties.getSelectedPropertyName()
    }

    noHotelMapped() {
        if (!this.$store.getters.scopevisioAccount) {
            return false
        }

        if (Organisations.changeInProgress) {
            return false
        }

        return Properties.noMappedHotels()
    }
    noMappingMessage() {
        if (!this.$store.getters.scopevisioAccount) {
            return false
        }

        return "Der Gesellschaft '" + this.$store.getters.scopevisioAccount.organisation?.name + "' sind keine Hotels zugeordnet."
    }
    onMapProperties()  {
        this.$router.push("/settings/mapping")
    }

    async onSignout() {
        const r = await showConfirmAsync({
            message: "Wollen Sie die Verbindung mit Apaleo trennen?"
        })
        if (r) {
            const store = this.$store as Store<StateType>
            store.commit("apaleoAccount", {})
            store.commit("selectProperty", "")
            store.commit("allProperties", [])
            store.commit("apaleoRefreshToken", "")
            Apaleoserver.instance.accessToken = ""
            this.connected = false
            this.$router.push("/")
        }
    }

    onSignin() {
        ApaleoAuth.startAuth()
    }

}
