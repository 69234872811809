/*
 * Purpose: providing utilities to handle accounts
 *
 * Written by Scopevisio AG 2021
 */

import store from "@/store";
import {ApaleoAccount, Apaleoserver, GetAccountsRequest, MainPath} from "@/util/apaleoserver";
import {Scopevisioserver} from "@/util/scopevisioserver";

export interface Account {
    name: string
    type: string
    hasChildren: boolean
    apaleoAccountNumber: string;
    scopevisioAccountNumber: string
    taxRate?: string
    aggregation?: string
    buKey: boolean
    vatmatrix: string
    dimension1: number
    dimension2: number
    path: string
}

export interface AccountSchema {
    [key: string]: Account[],
}

export interface AccountMap {
    [key: string]: Account
}

export interface VatMatrix {
    id: number
    vatRate: number
    datevKey: string
    vatCode: string
    vatKey: string
    vatKeyDescription: string
    purchaseAccount: string
    salesAccount: string
}


export class Accounts {

    static apaleoDepositAccount = "3000"
    static depositAccountTaxRates = ["0", "5", "7", "19"]

    static apaleoSchemaNames = [
        {text: "Externe Konten", value: "externalAccounts"},
        {text: "Globale Konten", value: "globalAccounts"},
        {text: "Gäste Konten", value: "guestAccounts"},
    ]

    static apaleoSchemaNameArray = {
        "externalAccounts": "Externe Konten",
        "globalAccounts": "Globale Konten",
        "guestAccounts": "Gäste Konten",
    }

    static debitorType = "AccountsReceivable"

    static async getVatMatrixEntries() {
        const response = await Scopevisioserver.instance.getVatMatrixEntries()

        if (response) {
            return response.records
        } else {
            return [] as VatMatrix[]
        }
    }

    static addMapping(mapping: Account) {
        const loadedAccountMappings = store.getters.loadedAccountMappings
        loadedAccountMappings[mapping.apaleoAccountNumber] = mapping
        store.commit("loadedAccountMappings", loadedAccountMappings)
    }

    static async getPropertyAccounts(propertyId: string, depth = 4) {
        const editAccounts = {
            externalAccounts: [],
            globalAccounts: [],
            guestAccounts: [],
        } as AccountSchema

        const request = {
            propertyId: propertyId,
            depth: depth,
            includeArchived: true,
        } as GetAccountsRequest

        const response = await Apaleoserver.instance.getPropertyAccounts(request)

        const accountsList = new Array<string>()
        const accountsSet = new Set<string>()

        if (response) {
            const convertAccount = (a: ApaleoAccount, path: string) => {
                const account: Account = {
                    name: a.name,
                    type: a.type,
                    hasChildren: !false,
                    apaleoAccountNumber: a.accountNumber,
                    scopevisioAccountNumber: "",
                    buKey: false,
                    vatmatrix: "",
                    dimension1: 0,
                    dimension2: 0,
                    path: path,
                }
                return account;
            }
            
            const processedAccounts = [] as string[]
            
            const processAccounts = async (mainPath: MainPath, path: string, depth: number, accounts: ApaleoAccount[], processedAccounts: string[]) => {
                for (const a of accounts) {
                    // full depth
                    const fullDepth = ["Revenues", "Payments", "CityTaxes", "Vat", "VatOnLiabilities", "TransitoryItems", "House"].indexOf(a.type) != -1

                    if (!fullDepth && !a.hasChildren) {
                        continue;
                    }
                    const account = convertAccount(a, path)
                    editAccounts[mainPath].push(account)
                    accountsList.push(mainPath + "/" + account.apaleoAccountNumber)
                    accountsSet.add(mainPath + "/" + account.apaleoAccountNumber)
                    
                    // recurse
                    if (a.hasChildren && !a.subAccounts && processedAccounts.indexOf(a.accountNumber) == -1) {
                        // ignore AccountsReceivable and Liabilities child accounts
                        if (a.type !== "AccountsReceivable" && a.type !== "Liabilities" || a.accountNumber === "9999"){
                            const response = await Apaleoserver.instance.getPropertyChildAccounts({
                                propertyId: propertyId,
                                parent: a.accountNumber,
                            })
                            processedAccounts.push(a.accountNumber)

                            if (response) {
                                a.subAccounts = []
                                for (let i = 0, n = response.accounts.length; i < n; i++) {
                                    const c = response.accounts[i]
                                    a.subAccounts.push(c)
                                }
                            }
                        }
                    }
                    if (a.hasChildren && a.subAccounts) {
                        await processAccounts(mainPath, path + ":::" + a.accountNumber, depth + 1, a.subAccounts, processedAccounts)
                    }
                }
            }
            for (let i = 0, n = 3; i < n; i++) {
                const apaleoKey = Object.keys(this.apaleoSchemaNameArray)[i] as MainPath
                await processAccounts(apaleoKey, apaleoKey, 1, response[apaleoKey], processedAccounts);
            }
        }

        if (accountsList.length != accountsSet.size) {
            console.error("account numbers not unique")
            debugger
        }

        return editAccounts
    }

    static getAllAccountsForProperty(map: AccountMap) {
        const accounts: Account[] = []

        for (const [, account] of Object.entries(map)) {
            accounts.push(account)
        }

        return accounts
    }

    static getAccountById(id: string) {
        return store.getters.loadedAccountMappings[id]
    }

    static isCreditCardAccount(accountNumber: string) {
        return (store.getters.loadedBasicSettings.apaleoCreditCardAccounts?.indexOf(accountNumber) !== -1)
    }
}