
import { Component, Vue } from "vue-property-decorator";
import {Apaleoserver} from "@/util/apaleoserver";
import {Scopevisioserver} from "@/util/scopevisioserver";
import {showNotification} from "@/util/eventbus";
import {NotificationType} from "@/util/scopevisiotypes";

@Component
export default class LogoutDialog extends Vue {
    dialog = false
    color = "black"

    scopevisio = "logout"
    apaleo = "logout"

    resolve: (args: any) => void = (args) => { /* */ }
    reject: (args: any) => void = (args) => { /* */ }

    open() {
        this.dialog = true
        return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
    }

    agree() {
        let logoutProcessed = false
        
        if (this.isConnectedToScopevisio && this.scopevisio === "logout") {
            Scopevisioserver.instance.accessToken = ""
            // this.$store.commit("selectProperty", "")
            // this.$store.commit("allProperties", [])
            // this.$store.commit("scopevisioRefreshToken", "")
            // this.$store.commit("scopevisioAccount", {})
            
            this.$store.dispatch("clearStoreScopevisio")
            this.$store.dispatch("clearLogs")

            logoutProcessed = true
        }

        if (this.isConnectedToApaleo && this.apaleo === "logout") {
            Apaleoserver.instance.accessToken = ""
            // this.$store.commit("selectProperty", "")
            // this.$store.commit("apaleoRefreshToken", "")
            // this.$store.commit("apaleoAccount", {})
            
            this.$store.dispatch("clearStoreApaleo")
            logoutProcessed = true
        }

        if (logoutProcessed) {
            showNotification({
                type: NotificationType.INFO,
                message: "Sie wurden erfolgreich abgemeldet.",
                timeoutMs: 5000
            })

            if (this.$route.path != "/") {
                this.$router.push("/")
            }
        }

        this.dialog = false
    }

    cancel() {
        this.resolve(false)
        this.dialog = false
    } 
    
    get isConnectedToScopevisio() {
        return Scopevisioserver.instance.isConnected()
    }
    get isConnectedToApaleo() {
        return Apaleoserver.instance.isConnected()
    }
}
