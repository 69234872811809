/*
 * Purpose: providing utilities to handle all journalling tasks
 *
 * Written by Scopevisio AG 2021
 */

import store, { BasicSettingsProperty, HotelAccountMapping } from "@/store";
import moment from "moment";
import { Account } from "./accounts";
import {ManagedData} from "./manageddata";
import { Property } from "./properties";
import { Transaction } from "./reports";
import { CreateDebitorResponse } from "./scopevisiotypes";
import { Statistics, StatisticsAccountMapping, StatisticsPosting } from "./statistics";
import {Util} from "./util";

export interface LogEntry {
    date: string
    logType: string
    action: string
    actionType: string
    propertyId: string
    user: string
    userId: string
    data: any
}

export interface LogSearch {
    logType?: string
    actionType?: string
    dateFrom?: string
    dateTo?: string
    action?: string
    propertyId?: string
    userId?: string
    data?: any
}

export class Logger {
    
    static actionTypeTransactionTransfer = "transaction transfer"
    static actionTypeTransactionTransferPosting = "transaction posting"
    static actionTypeTransactionTransferError = "transaction transfer error"

    static actionTypeStatisticTransactionTransfer = "statistic transfer"
    static actionTypeStatisticTransactionTransferPosting = "statistic posting"
    
    static async log(logType: string, action: string, actionType: string, data: any, propertyId = "", writeManagedData = true) {
        const logEntry = {
            date: moment().format(),
            logType: logType,
            action: action,
            actionType: actionType,
            propertyId: propertyId,
            user: store.getters.scopevisioAccount?.user?.lastName + ", " + store.getters.scopevisioAccount?.user?.firstName,
            userId: store.getters.scopevisioAccount?.user?.uid,
            data: {}, // data => disabled because of session storage quota,
        } as LogEntry
        store.commit("log", logEntry)
        
        if (writeManagedData) {
            ManagedData.setValue(store.state)
        }
    }

    static async logTransactions(transactions: Transaction[], bookingInterval: string) {
        Logger.log(
            "Transaktionsbuchungen",
            `Transfer beendet (Buchungszeitraum: ${bookingInterval})`,
            Logger.actionTypeTransactionTransfer,
            transactions,
            store.getters.selectedProperty
        )
        
        transactions.forEach(function(transaction: Transaction, id: number) {
            Logger.log(
                "Transaktionsbuchungen",
                `${Util.formatDateGerman(transaction.date)}: ${transaction.netAmount.toFixed(2)} ${transaction.currency} von ${transaction.debitedAccount.name} nach ${transaction.creditedAccount.name} (${transaction.receipt?transaction.receipt.number:"Aggregierte Paare"})`,
                Logger.actionTypeTransactionTransferPosting,
                transactions,
                store.getters.selectedProperty,
                (Object.is(transactions.length - 1, id))?true:false,
            )
        })
    }

    static async getLastEntriesDatesByLogType(logType: string, amount=50, propertyId?: string) {
        const search = {
            logType: logType,
        } as LogSearch

        if (propertyId) {
            search.propertyId = propertyId
        }

        let logs = await Logger.getLogs(search)

        if (logs?.length) {
            logs.sort((a: LogEntry, b: LogEntry) => (a.date < b.date) ? 1 : -1)
            logs = logs.slice(0, amount)
            
            const data = {
                from: logs[logs.length-1].date,
                to: logs[0].date,
            }
            
            return data
        }

        return false
    }

    static async getLastTransfer(type: string) {
        if ([
                Logger.actionTypeTransactionTransfer, 
                Logger.actionTypeStatisticTransactionTransfer,
            ].indexOf(type) == -1
        ) {
            return false
        }

        const logs = await Logger.getLogs({
            actionType: type,
            propertyId: store.getters.selectedProperty,
        }) as LogEntry[]

        if (logs?.length) {
            logs.sort((a: LogEntry, b: LogEntry) => (a.date < b.date) ? 1 : -1)

            return logs[0]
        }

        return false
    }

    static getLastTransferInterval(lastTransfer: LogEntry) {
        if (!lastTransfer) {
            return false
        }
        const lastTransferInterval = lastTransfer.action.match(/\(Buchungszeitraum: ([^)]*)\)/)

        if (lastTransferInterval && lastTransferInterval[1]) {
            return lastTransferInterval[1]
        }

        return false
    }
    static getLastTransferredStatisticDay(lastTransfer: LogEntry) {
        if (!lastTransfer) {
            return false
        }
        const lastTransferredStatisticInterval = lastTransfer.action.match(/\(Statistikzeitraum: ([^)-]*)-?([^)]*)\)/)
                
        if (!lastTransferredStatisticInterval) {
            return false
        }
        
        if (lastTransferredStatisticInterval[2] === "") {
            lastTransferredStatisticInterval[2] = lastTransferredStatisticInterval[1]
        }
        
        return moment(lastTransferredStatisticInterval[2], 'DD.MM.YYYY', 'de').clone().format('YYYY-MM-DD')
    }

    static getLastBookingDay(lastTransfer: LogEntry) {
        if (!lastTransfer) {
            return false
        }
        const lastBookingInterval = lastTransfer.action.match(/\(Buchungszeitraum: ([^)-]*)-?([^)]*)\)/)
        
        if (lastBookingInterval) {
            if (lastBookingInterval[2] === "") {
                lastBookingInterval[2] = lastBookingInterval[1]
            }
            
            return moment(lastBookingInterval[2], 'DD.MM.YYYY', 'de').clone().format('YYYY-MM-DD')
        }

        return false
    }

    static async logDebitorCreation(createdDebitors: CreateDebitorResponse[]) {
        Util.cl("logDebitorCreation", createdDebitors);
        
        for (const createdDebitor of createdDebitors){
            if (createdDebitor) {
                Logger.log(
                    "Debitoren",
                    `Debitor ${createdDebitor.number} ${createdDebitor.name} wurde angelegt`,
                    "debitor created",
                    null,//createdDebitor,
                    store.getters.selectedProperty,
                )
            } else {
/*                 Logger.log(
                    "Debitoren",
                    `Debitor ${createdDebitor.number} ${createdDebitor.name} konnte nicht angelegt werden`,
                    "create debitor",
                    createdDebitor,
                    store.getters.selectedProperty,
                ) */
            }
        }
    }

    static async logDebitorUpdate(debitor: any) {
        Util.cl("DEBITOR", debitor);
        
        Logger.log(
            "Debitoren",
            `Der Debitor ${debitor.lastname||debitor.name} wurde aktualisiert`,
            "debitor updated",
            null,//debitor,
            store.getters.selectedProperty,
        )
    }

    static async logBasicSettings(oldSettings: any, newSettings: BasicSettingsProperty) {
        if (newSettings && oldSettings) {
            for (const [key, value] of Object.entries(newSettings)) {
                if (oldSettings[key] != value && (!Array.isArray(value) || !Util.arraysAreEqual(oldSettings[key], value))) {
                    //Util.cl("===> HIT", key, oldSettings[key], value, Util.arraysAreEqual(oldSettings[key], value))
                    if (oldSettings[key] == undefined) {
                        oldSettings[key] = "ungesetzt"
                    }
                    Logger.log(
                        "Stammdaten",
                        `${this.getBasicSettingName(key)} geändert von <${oldSettings[key]}> zu <${value != ""?value:"ungesetzt"}>`,
                        "basic settings",
                        newSettings,
                        store.getters.selectedProperty,
                    )
                 }
            }
        }
        ManagedData.setValue(store.state)
    }

    static async logPropertyActivation(property: Property) {
        await Logger.log(
            "Stammdaten",
            `${property.name} wurde ${property.selected?"aktiviert":"deaktiviert"}`,
            "property activation",
            [],
            property.id
        )
        await ManagedData.setValue(store.state)
    }

    static async logAccountMappings(oldMappings: HotelAccountMapping, newMappings: HotelAccountMapping) {
        if (!newMappings) {
            return false
        }

        Object.values(newMappings).forEach(newMapping => {
            let oldMapping = {
                apaleoAccountNumber: newMapping["apaleoAccountNumber"],
                scopevisioAccountNumber: "",
                vatmatrix: "",
                dimension1: 0,
                dimension2: 0,
            } as Account

            if (oldMappings && oldMappings && oldMappings[newMapping["apaleoAccountNumber"]]) {
                oldMapping = oldMappings[newMapping["apaleoAccountNumber"]]
            }

            if (oldMapping.scopevisioAccountNumber != newMapping.scopevisioAccountNumber) {
                Logger.log(
                    "Stammdaten",
                    `${newMapping.scopevisioAccountNumber} ${newMapping.name}: Scopevisio Konto wechselt von 
                        ${oldMapping.scopevisioAccountNumber != ""?oldMapping.scopevisioAccountNumber:"<unzugeordnet>"} zu 
                        ${newMapping.scopevisioAccountNumber != ""?newMapping.scopevisioAccountNumber:"<unzugeordnet>"}`,
                    "account mappings",
                    newMappings,
                    store.getters.selectedProperty,
                )
            }

            if (oldMapping.vatmatrix != newMapping.vatmatrix) {
                Logger.log(
                    "Stammdaten",
                    `${newMapping.scopevisioAccountNumber} ${newMapping.name}: Steuerschlüssel wechselt von 
                        ${oldMapping.vatmatrix?oldMapping.vatmatrix:"<unzugeordnet>"} zu 
                        ${newMapping.vatmatrix?newMapping.vatmatrix:"<unzugeordnet>"}`,
                    "account mappings",
                    newMappings,
                    store.getters.selectedProperty,
                )
            }

            if (oldMapping.dimension1 != newMapping.dimension1) {
                Logger.log(
                    "Stammdaten",
                    `${newMapping.scopevisioAccountNumber} ${newMapping.name}: Dimension 1 wechselt von 
                        ${oldMapping.dimension1?oldMapping.dimension1:"<unzugeordnet>"} zu 
                        ${newMapping.dimension1?newMapping.dimension1:"<unzugeordnet>"}`,
                    "account mappings",
                    newMappings,
                    store.getters.selectedProperty,
                )
            }

            if (oldMapping.dimension2 != newMapping.dimension2) {
                Logger.log(
                    "Stammdaten",
                    `${newMapping.scopevisioAccountNumber} ${newMapping.name}: Dimension 1 wechselt von 
                        ${oldMapping.dimension2?oldMapping.dimension2:"<unzugeordnet>"} zu 
                        ${newMapping.dimension2?newMapping.dimension2:"<unzugeordnet>"}`,
                    "account mappings",
                    newMappings,
                    store.getters.selectedProperty,
                )
            }
        })
    }

    static async logStatisticAccountMappings(oldMappings: StatisticsAccountMapping[], newMappings: StatisticsAccountMapping[]) {
        if (newMappings) {
            for (const k in newMappings) {
                const newMapping = newMappings[k]
                const oldMapping = oldMappings.find(
                    (mapping: StatisticsAccountMapping) => (mapping.apaleoStatisticAccountId == newMapping.apaleoStatisticAccountId)
                )
                if (oldMapping) {
                    if (newMapping.scopevisioStatisticAccountNumber != oldMapping.scopevisioStatisticAccountNumber) {
                        if (oldMapping.scopevisioStatisticAccountNumber == "") {
                            oldMapping.scopevisioStatisticAccountNumber = Statistics.unassigned
                        }
                        if (newMapping.scopevisioStatisticAccountNumber == "") {
                            newMapping.scopevisioStatisticAccountNumber = Statistics.unassigned
                        }
    
                        Logger.log(
                            "Stammdaten",
                            `${oldMapping.apaleoStatisticAccountName}: Konto von ${oldMapping.scopevisioStatisticAccountNumber} zu ${newMapping.scopevisioStatisticAccountNumber} geändert`,
                            "statistic settings",
                            newMappings,
                            store.getters.selectedProperty,
                        )
                    }
    
                    if (newMapping.costUnit != oldMapping.costUnit) {
                        Logger.log(
                            "Stammdaten",
                            `${oldMapping.apaleoStatisticAccountName}: Kostenstelle von ${oldMapping.costUnit} zu ${newMapping.costUnit} geändert`,
                            "statistic settings",
                            newMappings,
                            store.getters.selectedProperty,
                        )
                    }
    
                }
            }  
        }

        ManagedData.setValue(store.state)
    }

    static logStatisticPostings(postings: StatisticsPosting[]) {
        const postingDates = [] as number[]

        postings.forEach(function(posting: StatisticsPosting) {
            let logText = `${posting.text} (${Util.formatDate(posting.date)}): ${posting.amount} importiert zu Konto ${posting.accountNumber}`
            if (posting.dimension_1 && posting.dimension_1 > 0) {
                logText += ` mit Kostenstelle ${posting.dimension_1}`
            }
            postingDates.push(posting.date)
            Logger.log(
                "Statistikbuchungen",
                logText,
                "statistic posting",
                posting,
                store.getters.selectedProperty,
                false,
            )
        })

        const distinctPostingDates = [...new Set(postingDates)]
        const postingInterval = (distinctPostingDates[0] === distinctPostingDates[distinctPostingDates.length - 1])?
            "(Statistikzeitraum: " + Util.formatDate(distinctPostingDates[0]) + ")" : 
            "(Statistikzeitraum: " + Util.formatDate(distinctPostingDates[0]) + "-" + Util.formatDate(distinctPostingDates[distinctPostingDates.length - 1]) + ")"
        
        Logger.log(
            "Statistikbuchungen",
            `Transfer beendet ${postingInterval}`,
            Logger.actionTypeStatisticTransactionTransfer,
            {},
            store.getters.selectedProperty,
        )
    }

    static getBasicSettingName(id: string) {
        const dictionary = {
            "details": "Details",
            "debitorMangementScopevisio": "Debitorenverwaltung(SV)",
            "debitorAccount": "Forderungskonto",
            "debitorSynchronisation": "Stammdatenaktualisierung",
            "debitorAccountNumberStart": "Anfangsbereich Debitoren",
            "statisticManagement": "Statistikwerte",
            "creditCardDetails": "Kreditkarten-Details",
            "apaleoCreditCardAccounts": "Kreditkarten-Kontos",
            "call": "Abruf",    
        } as any

        if (!dictionary[id]) {
            return id
        }

        return dictionary[id]
    }

    static getLogs(search: LogSearch = {}) {
        let logs = store.getters.logs

        if (!logs.filter) {
            return []
        }

        if (search?.propertyId){
            logs = logs.filter((logEntry: LogEntry) => logEntry.propertyId == search.propertyId)
        }
        if (search?.logType){
            logs = logs.filter((logEntry: LogEntry) => logEntry.logType == search.logType)
        }
        if (search?.actionType){
            logs = logs.filter((logEntry: LogEntry) => logEntry.actionType == search.actionType)
        }
        if (search?.dateFrom){
            logs = logs.filter((logEntry: LogEntry) => moment(logEntry.date).endOf('day') >= moment(search.dateFrom))
        }
        if (search?.dateTo){
            logs = logs.filter((logEntry: LogEntry) => moment(logEntry.date).startOf('day') <= moment(search.dateTo))
        }
        
        return logs
    }

        
    static async checkStorage() {
        const vuexMemoryMb = ((localStorage.vuex.length * 2 + 8) / 1024 / 1024)
        
        if (vuexMemoryMb > 4.5) {
            const logCount = await this.reduceLog()
            await this.log(
                "Stammdaten",
                "Logs reduziert um " + logCount + " Einträge",
                "automatic",
                {}
            )       
            
            const reducedVuexMemoryMb = ((localStorage.vuex.length * 2 + 8) / 1024 / 1024)
    
            Util.cl(`Local storage reduced from ${reducedVuexMemoryMb}MB to ${reducedVuexMemoryMb}MB`)
        }
    } 

    static async reduceLog(days = 1) {
        let logs = await this.getLogs()
        const logCount = logs.length

        if (logs) {
            logs = logs.sort((a: LogEntry, b: LogEntry) => (a.date > b.date) ? 1 : -1)

            if (logs.length > 1) {
                const lastDay = moment(logs[0].date).startOf("day")
                const deleteUntil = lastDay.clone().subtract(days - 1, "days")
                
                logs = logs.filter(
                    (logEntry: LogEntry) => moment(logEntry.date) > deleteUntil
                )
            }
        }
        
        return logCount - logs.length
    }
}