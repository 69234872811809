/*
 * Purpose: providing utilities to handle debitors
 *
 * Written by Scopevisio AG 2021
 */

import store from "@/store"
import { ApaleoCompany, Apaleoserver } from "./apaleoserver"
import { showNotification } from "./eventbus"
import {Logger} from "./logger"
import {ManagedData} from "./manageddata"
import { Scopevisioserver } from "./scopevisioserver"
import { CreateContactRequest, CreateDebitorResponse, NotificationType } from "./scopevisiotypes"
import { Settings } from "@/util/settings"
import {Util} from "./util"
import { Accounts } from "./accounts"
import { TransactionAccount } from "./reports"

export interface PaymentTermForm {
    name: string
    cashDiscountDays1: number
    percentage1: number
    cashDiscountDays2: number
    percentage2: number
    netTimeLimit: number
    description: string
}
export interface ApaleoDebitor {
    apaleocode: string
    number: string // yes, these numbers are strings
    name: string
    companyname: string
    street: string
    city: string
    zipcode: string
    countrycode: string
    scopevisioDebitorNumber?: string
    scopevisioDebitorDataChanged?: boolean
    scopevisioDebitorContactId?: number
    action: string    
}

/* load debitors from the apaleo side and preprocess them into an array
 * of Debitor[]
 */

export class DebitorUtil {

    static sumAccountName = "<Sammelkonto>"
    static sumAccountNumber = "CollectiveDebitor_AccountsReceivable"
    static defaultSumAccountNumber = "120000"
    static revenuesAccountType = "Revenues"
    static debitorAccountType = "AccountsReceivable"

    /* Debitors are sitting behind the 
        the accounts-receivable account
        
        1. we load the children of that top-level account
        2. we enrich the information by loading the "firmen/companies"
            from the api, where we find additional information like adresses
    */
    static async loadApaleoDebitors(propertyId: string) {
        const debitors: ApaleoDebitor[] = []
        const topAccountsResponse = await Apaleoserver.instance.getTopAccounts({
            propertyId: propertyId
        })
        const arAccounts = topAccountsResponse?.accounts.filter(a => a.type == DebitorUtil.debitorAccountType)
        if (!arAccounts || arAccounts.length != 1) {
            [
                showNotification({ 
                    type: NotificationType.ERROR, 
                    message: "AccountsReceivable Account not identified" 
                })
            ]
        } else {
            const arAccount = arAccounts[0]
            const childAccountsResponse = await Apaleoserver.instance.getPropertyChildAccounts({
                propertyId: propertyId,
                parent: arAccount.accountNumber,
                includeArchived: true,
            })
            if (childAccountsResponse && childAccountsResponse?.count > 0) {
                const code2company = new Map<string, ApaleoCompany>()
                const companiesResponse = await Apaleoserver.instance.getCompanies({ propertyId: propertyId })
                if (companiesResponse && companiesResponse?.count > 0) {
                    for (const c of companiesResponse.companies) {
                        code2company.set(c.code, c)
                    }
                }
                for (const ar of childAccountsResponse?.accounts) {
                    const code = ar.accountNumber.replace(/_.*$/, "").trim()
                    const company = code2company.get(code)
                    const debitor = {
                        apaleocode: code,
                        number: ar.accountNumber,
                        name: ar.name,
                        companyname: company?.name || "",
                        street: company?.address?.addressLine1 || "",
                        zipcode: company?.address?.postalCode || "",
                        city: company?.address?.city || "",
                        countrycode: company?.address?.countryCode || "",
                    } as ApaleoDebitor

                    debitors.push(debitor)
                }

            }
        }
        return debitors
    }
    
    static async loadMappedScopevisioDebitors() {
        const filter = {
            search: [
                {
                    field:"externalNumber",
                    value:"AccountsReceivable",
                    operator:"endswith",
                }
            ],
            pageSize: 10000
        }
        const debitors = await Scopevisioserver.instance.debitors(filter)
        
        return debitors
    }

    static isScopevisioDebitor(scopevisioDebitorList: ScopevisioDebitor[], accountNumber: string) {
        return scopevisioDebitorList.find(d => d.number === accountNumber) !== undefined
    }

    static async checkForNewMappedScopevisioDebitors(scopevisioDebitors: ScopevisioDebitor[]){
        const code2ScopeviseoDebitor = new Map<string, ScopevisioDebitor>()
        scopevisioDebitors.forEach(d => code2ScopeviseoDebitor.set(d.externalNumber, d))
        
        for (const scopevisioDebitor of scopevisioDebitors) {
            if (!store.getters.loadedAccountMappings[scopevisioDebitor.externalNumber]) {
                // create account mapping
                const account = await Apaleoserver.instance.getAccountByNumber(scopevisioDebitor.externalNumber)
                account.scopevisioAccountNumber = scopevisioDebitor.number
                account.apaleoAccountNumber = scopevisioDebitor.externalNumber
                
                Accounts.addMapping(account)
            }
            
        }
    }

    static async extendAccountMappings(createdDebitors: CreateDebitorResponse[]) {
        Util.cl("extendAccountMappings", createdDebitors)
        let newAccountMapping = false
        
        for (const createdDebitor of createdDebitors) {
            if (createdDebitor.apaleoDebitorNumber && 
                store.getters.loadedAccountMappings &&
                store.getters.loadedAccountMappings[createdDebitor.apaleoDebitorNumber]
            ) {
                store.getters.loadedAccountMappings[createdDebitor.apaleoDebitorNumber].scopevisioAccountNumber = createdDebitor.number
                Util.cl("extendAccountMapping with ", createdDebitor.apaleoDebitorNumber, store.getters.loadedAccountMappings[createdDebitor.apaleoDebitorNumber].scopevisioAccountNumber)

                newAccountMapping = true
            }           
        }
        
        if (newAccountMapping) {
            ManagedData.setValue(store.state)
        }
    }

    static getContactIds(debitors: ScopevisioDebitor[]) {
        const ids = [] as number[]

        for (const debitor of debitors) {
            if (debitor) {
                ids.push(debitor.contactId)
            }            
        }
        
        return ids
    }

    static async getContacts(search: any) {
        const contacts = {} as ScopevisioContacts
        const scopevisioContactsResponse = await Scopevisioserver.instance.getContacts(search)

        for (const contact of scopevisioContactsResponse) {
            if (contact) {
                contacts[contact.id] = contact
            }            
        }

        return contacts
    }

    static getContactSearch(accounts: number[]) {
        let values = ""

        for (const account of accounts) {
            values += account + ","
        }

        values = values.substring(0, values.length-1)

        const search = {
            fields:[
                "id",
                "city1",
                "lastname",
                "street1",
                "postcode1",
                "city1",
                "country1"
            ],
            search: {
                field: "id",
                values: values,
                pageSize: "1000",
                operator: "equals",
            },
        }

        return search
    }

    static async createDebitors(debitors: ApaleoDebitor[]) {
        const createdDebitors = [] as CreateDebitorResponse[]

        Util.cl("createDebitors", debitors)

        for ( const debitor of debitors) {
                if (!debitor.name || !debitor.number) {
                    Logger.log(
                        "Debitoren",
                        `Debitor konnte nicht angelegt werden`,
                        "debitor not created",
                        null,//createdDebitor,
                        store.getters.selectedProperty,
                    )

                    const notCreatedDebitor = {} as CreateDebitorResponse
                    notCreatedDebitor.status = 400
                    createdDebitors.push(notCreatedDebitor)
                } else if (debitor.action && debitor.action == "create") {
                    const createdDebitor = await DebitorUtil.createDebitor(debitor)
                    createdDebitor.apaleoDebitorNumber = debitor.number
                    createdDebitors.push(createdDebitor)
                } 
        }

        return createdDebitors
    }

    static async createDebitor(debitor: ApaleoDebitor) {
        Util.cl("createDebitor", debitor)

        const newContactId = await DebitorUtil.createContact(debitor)
        const newDebitorRequest = {
            contactId: newContactId,
            sumAccountNumber: DebitorUtil.defaultSumAccountNumber, 
            externalNumber: debitor.number,
            group: "Apaleo"
        } as CreateDebitorRequest

        if (Settings.debitorManagementOnSumAccount() &&
            store.getters.loadedBasicSettings?.debitorAccountNumberStart !== 0
        ) {
            newDebitorRequest.numberRangeNumber = 2
        }

        if (Settings.debitorManagementOnSumAccount() &&
            store.getters.loadedBasicSettings?.debitorAccount
        ) {
            newDebitorRequest.sumAccountNumber = store.getters.loadedBasicSettings.debitorAccount   
        }

        const createdDebitor = await Scopevisioserver.instance.createDebitor(newDebitorRequest)

        if (createdDebitor.created) {
            createdDebitor.name = debitor.number
            debitor.scopevisioDebitorNumber = createdDebitor.number
        }        

        return createdDebitor
    }

    static async createContact(debitor: ApaleoDebitor) {
        const newContactRequest = {
            person: true,
            lastname: debitor.companyname?debitor.companyname:debitor.name,
            street1: debitor.street,
            city1: debitor.city,
            postcode1: debitor.zipcode,
            country1: Util.getCountryNameByIsoCode(debitor.countrycode),
            //language: debitor.countrycode,
        } as CreateContactRequest

        const newContactId = await Scopevisioserver.instance.createContact(newContactRequest)

        return newContactId
    }

    static updateDebitors(apaleoDebitors: ApaleoDebitor[], updates: CreateDebitorResponse[]) {
        for (const key in apaleoDebitors) {
            for (const update of updates) {
                if (update.created && apaleoDebitors[key].number === update.name) {
                    apaleoDebitors[key].scopevisioDebitorNumber = update.number
                }
            }            
        }
    }
    
    static isDebitorAccount(account: TransactionAccount) {
        return ([DebitorUtil.debitorAccountType].indexOf(account.type) != -1)
        //return (account.type == DebitorUtil.debitorAccountType)
    }
}

export interface UpdateDebitorRequest {
    externalNumber: string
    sumAccountNumber: string
    numberRangeNumber: number
    group: string
    vatCode: string
    paymentType: string
    vatNumber: string
    vatId: string
    currency: string
    language: string
    paymentTermId: number
    paymentTermForm: PaymentTermForm
}

export interface CreateDebitorRequest {
    contactId: number
    customerNumber: string
    email: string
    externalNumber: string
    personalAccountNumber: string
    sumAccountNumber: string
    numberRangeNumber: number
    group: string
    vatCode: string
    paymentType: string
    vatNumber: string
    vatId: string
    currency: string
    language: string
    paymentTermId: number
    paymentTermForm: PaymentTermForm
}

export interface ScopevisioDebitor {
    number: string
    name: string
    vatId: string
    summaryAccountNumber: string
    contactId: number
    externalNumber: string
    locked: boolean
    paymenTermName: string
    paymenTermDescription: string
    paymenTermNetTimeLimit: number
    paymenTermCashDiscountDays1?: any
    paymenTermPercentage1?: any
    paymenTermCashDiscountDays2?: any
    paymenTermPercentage2?: any
    paymentType: string
    vatCode?: any
}
export interface ScopevisioContacts {
    [key: number]: ScopevisioContact,
}

export interface ScopevisioContact {
    id: number
    thumbLength?: any
    organisation: number
    isAccount: boolean
    vhUserName?: any
    salutation?: any
    title?: any
    lastname: string
    firstname?: any
    city1: string
    email?: any
    statusLeadDisplay?: any
    statusInterestedDisplay?: any
    statusCustomerDisplay?: any
    sphereLead?: any
    sphereInterested?: any
    sphereCustomer?: any
    sphereVendor?: any
    sphereDebitor: boolean
    sphereKreditor?: any
    debitorNumber: string
    kreditorNumber?: any
    sphereOrganisationUser?: any
    sphereEmployee?: any
    spherePartner?: any
    sphereWorker?: any
    creationTs: string
    changeTs: string
    customerNumber: string
    o1_outgoingInvoiceOpportunityCount?: any
    o1_outgoingInvoiceOpportunitySum?: any
    o1_outgoingInvoiceOfferCount?: any
    o1_outgoingInvoiceOfferSum?: any
    o1_outgoingInvoiceOrderCount?: any
    o1_outgoingInvoiceOrderSum?: any
    o1_outgoingInvoiceInvoiceCount?: any
    o1_outgoingInvoiceInvoiceSum?: any
    o1_outgoingInvoiceSentInvoiceCount?: any
    o1_outgoingInvoiceSentInvoiceSum?: any
    phone?: any
    phone2?: any
    phone3?: any
    phone4?: any
    phone5?: any
    phone6?: any
    mobile?: any
    mobile2?: any
    mobile3?: any
    mobile4?: any
    mobile5?: any
    mobile6?: any
    imei?: any
    email2?: any
    email3?: any
    email4?: any
    email5?: any
    email6?: any
    website?: any
    fax?: any
    fax2?: any
    fax3?: any
    fax4?: any
    fax5?: any
    fax6?: any
    bossContactName1?: any
    bossContactName2?: any
    bossContactName3?: any
    bossContactName4?: any
    bossContactName5?: any
    bossContactName6?: any
    bossContactName7?: any
    bossContactName8?: any
    salesContactName1?: any
    salesContactName2?: any
    salesContactName3?: any
    salesContactName4?: any
    salesContactName5?: any
    salesContactName6?: any
    salesContactName7?: any
    salesContactName8?: any
    salesContactName9?: any
    salesContactName10?: any
    salesContactName11?: any
    salesContactName12?: any
    salesContactName13?: any
    salesContactName14?: any
    taxContactName1?: any
    taxContactName2?: any
    taxContactName3?: any
    taxContactName4?: any
    description?: any
    jobPosition?: any
    position?: any
    positionReplacement?: any
    department?: any
    street1: string
    addressExtra1?: any
    postcode1: string
    federalState1?: any
    country1: string
    timezone1: string
    street2?: any
    addressExtra2?: any
    city2?: any
    postcode2?: any
    federalState2?: any
    country2?: any
    timezone2?: any
    street3?: any
    addressExtra3?: any
    city3?: any
    postcode3?: any
    federalState3?: any
    country3?: any
    timezone3?: any
    street4?: any
    addressExtra4?: any
    city4?: any
    postcode4?: any
    federalState4?: any
    country4?: any
    timezone4?: any
    street5?: any
    addressExtra5?: any
    city5?: any
    postcode5?: any
    federalState5?: any
    country5?: any
    timezone5?: any
    street6?: any
    addressExtra6?: any
    city6?: any
    postcode6?: any
    federalState6?: any
    country6?: any
    timezone6?: any
    contactOwnerName: string
    paymentTypeName?: any
    industry?: any
    revenue?: any
    organisationType?: any
    organisationFlavor?: any
    organisationCurrency: string
    organisationEmployeeCount?: any
    organisationAnnualTurnover?: any
    organisationMarketShare?: any
    personSex?: any
    personBirthname?: any
    dateOfBirth?: any
    personMaritalStatus?: any
    personChildNumber: number
    personJobName?: any
    personEmployerName?: any
    workerCompetence?: any
    workerBossId?: any
    workerBoss?: any
    workerView?: any
    workerExperience?: any
    personEmployerOffice?: any
    personContactFor?: any
    personMailAddress?: any
    customerPersonDisplay?: any
    abcCustomer?: any
    customerGroup?: any
    customerStatus?: any
    debitorPersonDisplay?: any
    language: string
    preferredKontaktType?: any
    preferredInvoiceSendMethod?: any
    bundesanzeigerLink?: any
    tradeRegisterName?: any
    tradeRegisterNumber?: any
    organisationReason?: any
    branchNumber?: any
    financeOfficeName?: any
    financeOfficetaxNumber?: any
    vatId?: any
    currency: string
    publicInterest?: any
    publicInterestReason?: any
    publicInterestGoal?: any
    vip: boolean
    nickname?: any
    nationality: string
    segmentationAudience?: any
    segmentationEducationalBackground?: any
    segmentationIncomeGroup?: any
    segmentationAttitude?: any
    segmentationMotivation?: any
    segmentationBuyingBehavior?: any
    segmentationLifestyle?: any
    segmentationCustomerBehavior?: any
    salesPersonDisplay?: any
    salesConsumerType?: any
    salesKontaktType?: any
    salesChannel?: any
    interestedLastSeenBySalesPerson?: any
    salesInternalOrganisation?: any
    salesNielsenRegion?: any
    salesRegion?: any
    salesRisk?: any
    salesPriority?: any
    salesBehavior?: any
    salesPhase?: any
    salesStatus?: any
    salesActivity?: any
    salesContractPersonName?: any
    salesCampaignName?: any
    deliveryTermPriority?: any
    deliveryTermCompleteDelivery?: any
    deliveryTermIncoterm?: any
    deliveryTermIncotermLocation?: any
    customText1?: any
    customBoolean1?: any
    customAmount1?: any
    customLong1?: any
    customDate1?: any
    customDateTime1?: any
    customText2?: any
    customBoolean2?: any
    customAmount2?: any
    customLong2?: any
    customDate2?: any
    customDateTime2?: any
    customText3?: any
    customBoolean3?: any
    customAmount3?: any
    customLong3?: any
    customDate3?: any
    customDateTime3?: any
    customText4?: any
    customBoolean4?: any
    customAmount4?: any
    customLong4?: any
    customDate4?: any
    customDateTime4?: any
    customText5?: any
    customBoolean5?: any
    customAmount5?: any
    customLong5?: any
    customDate5?: any
    customDateTime5?: any
    customText6?: any
    customBoolean6?: any
    customAmount6?: any
    customLong6?: any
    customDate6?: any
    customDateTime6?: any
    customText7?: any
    customBoolean7?: any
    customAmount7?: any
    customLong7?: any
    customDate7?: any
    customDateTime7?: any
    customText8?: any
    customBoolean8?: any
    customAmount8?: any
    customLong8?: any
    customDate8?: any
    customDateTime8?: any
    customText9?: any
    customBoolean9?: any
    customAmount9?: any
    customLong9?: any
    customDate9?: any
    customDateTime9?: any
    customText10?: any
    customBoolean10?: any
    customAmount10?: any
    customLong10?: any
    customDate10?: any
    customDateTime10?: any
    customText11?: any
    customBoolean11?: any
    customAmount11?: any
    customLong11?: any
    customDate11?: any
    customDateTime11?: any
    customText12?: any
    customBoolean12?: any
    customAmount12?: any
    customLong12?: any
    customDate12?: any
    customDateTime12?: any
    customText13?: any
    customBoolean13?: any
    customAmount13?: any
    customLong13?: any
    customDate13?: any
    customDateTime13?: any
    customText14?: any
    customBoolean14?: any
    customAmount14?: any
    customLong14?: any
    customDate14?: any
    customDateTime14?: any
    customText15?: any
    customBoolean15?: any
    customAmount15?: any
    customLong15?: any
    customDate15?: any
    customDateTime15?: any
    customText16?: any
    customBoolean16?: any
    customAmount16?: any
    customLong16?: any
    customDate16?: any
    customDateTime16?: any
    customText17?: any
    customBoolean17?: any
    customAmount17?: any
    customLong17?: any
    customDate17?: any
    customDateTime17?: any
    customText18?: any
    customBoolean18?: any
    customAmount18?: any
    customLong18?: any
    customDate18?: any
    customDateTime18?: any
    customText19?: any
    customBoolean19?: any
    customAmount19?: any
    customLong19?: any
    customDate19?: any
    customDateTime19?: any
    customText20?: any
    customBoolean20?: any
    customAmount20?: any
    customLong20?: any
    customDate20?: any
    customDateTime20?: any
    customText21?: any
    customBoolean21?: any
    customAmount21?: any
    customLong21?: any
    customDate21?: any
    customDateTime21?: any
    customText22?: any
    customBoolean22?: any
    customAmount22?: any
    customLong22?: any
    customDate22?: any
    customDateTime22?: any
    customText23?: any
    customBoolean23?: any
    customAmount23?: any
    customLong23?: any
    customDate23?: any
    customDateTime23?: any
    customText24?: any
    customBoolean24?: any
    customAmount24?: any
    customLong24?: any
    customDate24?: any
    customDateTime24?: any
    customText25?: any
    customBoolean25?: any
    customAmount25?: any
    customLong25?: any
    customDate25?: any
    customDateTime25?: any
    customText26?: any
    customBoolean26?: any
    customAmount26?: any
    customLong26?: any
    customDate26?: any
    customDateTime26?: any
    customText27?: any
    customBoolean27?: any
    customAmount27?: any
    customLong27?: any
    customDate27?: any
    customDateTime27?: any
    customText28?: any
    customBoolean28?: any
    customAmount28?: any
    customLong28?: any
    customDate28?: any
    customDateTime28?: any
    customText29?: any
    customBoolean29?: any
    customAmount29?: any
    customLong29?: any
    customDate29?: any
    customDateTime29?: any
    customText30?: any
    customBoolean30?: any
    customAmount30?: any
    customLong30?: any
    customDate30?: any
    customDateTime30?: any
    tags?: any
    legacyNumber?: any
    crefoNumber?: any
    crefoCreatorUid?: any
    crefoCreatedTs?: any
    crefoCreatorName?: any
    campaignPersonDisplay?: any
    leadPersonDisplay?: any
    partnerPersonDisplay?: any
    partnerType?: any
    partnerStatus?: any
    partnerDateBegin?: any
    partnerDateEnd?: any
    partnerClassification?: any
    partnerExperience?: any
    leadLastSeenByLeadPerson?: any
    dueDate?: any
    reminderDate?: any
    leadClassification: string
    leadIdentification?: any
    leadQualification: string
    leadQualificationRange: string
    leadBudget?: any
    leadBudgetPoints?: any
    leadAuthority?: any
    leadAuthorityPoints?: any
    leadNeed?: any
    leadNeedPoints?: any
    leadTime?: any
    leadTimePoints?: any
    leadSumPoints: number
    leadTransfer?: any
    lastCampaignId?: any
    lastCampaignName?: any
    lastCampaignMailTs?: any
    unsubscribedFromCampaign: boolean
    stopWatch?: any
    creditReformCreditLimit?: any
    vendorCreditLimit?: any
    customerCreditLimit?: any
    permissionDisplay: string
    worktimeDescription?: any
    worktimeCount?: any
    worktimeUnitDisplay?: any
    worktimeInternalCostPerHour?: any
    worktimePtoDays?: any
    worktimeValidFrom?: any
    worktimeValidTill?: any
}